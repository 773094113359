import { Theme } from './../models/theme.interface';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ThemeSummary } from '../models/theme-summary.model';
import { ProjectSummary } from '../../project/models/project-summary.interface';
import { environment } from '../../../environments/environment';
import { Content } from '../../content/models/content.model';

@Injectable({ providedIn: 'root' })
export class ThemeService {
  private apiBase = `${environment.apiUrl}/themes`;

  constructor(private http: HttpClient) {}

  getThemeSummary(): Observable<ThemeSummary[]> {
    return this.http.get<ThemeSummary[]>(`${this.apiBase}/summary`);
  }

  all(): Observable<Theme[]> {
    return this.http.get<Theme[]>(`${this.apiBase}/get`);
  }

  get(id: number): Observable<Theme> {
    return this.http.get<Theme>(`${this.apiBase}/get/${id}`);
  }

  timeline(id: number): Observable<ProjectSummary[]> {
    return this.http.get<ProjectSummary[]>(`${this.apiBase}/get/${id}/timeline`);
  }

  getThemeContent(id) {
    return this.http.get<Content[]>(`${this.apiBase}/get/${id}/content`);
  }
}
