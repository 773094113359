<article class="brick-wall__container__item type--news">
  <figure>
    <a [href]="item.Url" class="brick-wall__container__item__content__img" [class.has--icon]="item.VideoUrl">
      <app-icon [anchor]="'icon-play'" *ngIf="item.VideoUrl"></app-icon>

      <img
        [src]="item.ImageUrl + '?width=' + imageWidth + imageHeightRule + '&quality=75&mode=crop&scale=both'"
        alt=""
        (load)="load.emit($event)"
        *ngIf="item.ImageUrl"
        [width]="imageWidth"
        [height]="imageHeight"
      />

      <time class="date-chip" [datetime]="item.DateAdded | date : 'dd/MM/yyyy'" *ngIf="showEventDateCard">
        <span class="date-chip__day">{{ item.DateAdded | date : 'dd' }}</span>
        <span class="date-chip__month">{{ item.DateAdded | date : 'MMM' }}</span>
      </time>
    </a>
  </figure>

  <header class="brick-wall__container__item__header" *ngIf="item?.DateAdded && !showEventDateCard">
    <a class="hover--underline" [routerLink]="item.Url">
      <time class="brick-wall__container__item__header__title" *ngIf="item.ParentTitle !== 'news'">
        {{ item.DateAdded | date : 'dd/MM/yyyy' }}
      </time>
      <time class="brick-wall__container__item__header__title" *ngIf="item.ParentTitle === 'news'">{{ item.DateAdded | amTimeAgo }}</time>
    </a>
  </header>

  <main class="brick-wall__container__item__content" *ngIf="item?.Url && item?.Title">
    <h1>
      <a [href]="item.Url" class="hover--underline">{{ item.Title }}</a>
    </h1>
    <a class="hover--underline" [href]="item.Url" *ngIf="item.HostName">
      <strong>{{ item.HostName }}</strong>
    </a>
    <a class="hover--underline" [routerLink]="item.ParentUrl" *ngIf="item.ParentUrl && !item.HostName && !hideCategory">
      <strong class="type--capitalize">{{ item.ParentTitle }}</strong>
    </a>
  </main>
</article>
