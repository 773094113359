<section class="content-detail background--{{ background }}">
  <div class="block-mobile-nav-space"></div>

  <header class="content-detail__header">
    <div class="content-detail__header__container"></div>
  </header>
  <main class="content-detail__body padding-top--0 padding-bottom--0"></main>
  <div class="pressroom background--dark">
    <press-room></press-room>
  </div>
</section>
