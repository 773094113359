<section class="tech-specs background--light" [ngClass]="cssClasses">
  <!-- <header class="tech-specs__header">
    <h1 class="tech-specs__header__title">
      {{project.Title}}
    </h1>
  </header> -->

  <div class="tech-specs__intro" [innerHtml]="project.Summary" *ngIf="project.Summary"></div>

  <ul class="tech-specs__list details-list type--categories">
    <li *ngIf="project.Country || project.City">
      <dl>
        <dt>{{ 'project-tech-specs-location' | fromDictionary }}</dt>
        <dd>
          <a
            *ngIf="project.Latitude && project.Longitude"
            class="hover--underline"
            [href]="'https://maps.google.com/?q=' + project.Latitude + ',' + project.Longitude"
            target="_blank"
          >


            <span class="d-inline-block" *ngIf="project.City">
              <ng-container>{{ project.City }}</ng-container>
              <ng-container *ngIf="project.Country.Name">,&nbsp;</ng-container>
              <app-icon *ngIf="!project.Country.Name" class="link-icon" anchor="icon-external-link"></app-icon>
            </span>
            <span class="d-inline-block" *ngIf="project.Country.Name">
              <ng-container>{{ project.Country.Name }}</ng-container>
              <app-icon class="link-icon" anchor="icon-external-link"></app-icon>
            </span>
          </a>
          <ng-container *ngIf="!project.Latitude && !project.Longitude">
            <span *ngIf="project.City">
             <ng-container>{{ project.City }}</ng-container>
             <ng-container *ngIf="project.Country.Name">,&nbsp;</ng-container>
            </span>

            <span *ngIf="project.Country.Name" >
              {{ project.Country.Name }}
            </span>
          </ng-container>
        </dd>
      </dl>
    </li>
    <li *ngIf="statusLookup[project.Phase - 1]">
      <dl>
        <dt>{{ 'project-tech-specs-status' | fromDictionary }}</dt>
        <dd>{{ 'project-phase_' + project.Phase | fromDictionary }}</dd>
      </dl>
    </li>
    <!--<li *ngIf="project.Country">
      <dl>
        <dt>{{ 'project-tech-specs-location' | fromDictionary }}</dt>
        <dd>
          <a [routerLink]="['/projects']" [queryParams]="{ country: project.Country.Name }">{{ project.Country.Name }}</a>
        </dd>
      </dl>
    </li>
    <li *ngIf="project.City">
      <dl>
        <dt>{{ 'project-tech-specs-city' | fromDictionary }}</dt>
        <dd>{{ project.City }}</dd>
      </dl>
    </li>-->
    <li *ngIf="project.Year && project.Year > 0">
      <dl>
        <dt>{{ 'project-tech-specs-year' | fromDictionary }}</dt>
        <dd>{{ project.Year }}</dd>
      </dl>
    </li>
    <li *ngIf="project.Size">
      <dl>
        <dt>{{ 'project-tech-specs-surface' | fromDictionary }}</dt>
        <dd>{{ project.Size }} m&#178;</dd>
      </dl>
    </li>
    <li *ngIf="project.Budget">
      <dl>
        <dt>{{ 'project-tech-specs-budget' | fromDictionary }}</dt>
        <dd>{{ project.ClientType == 0 || !project.Budget ? 'Undisclosed' : project.Budget }}</dd>
      </dl>
    </li>
    <li *ngIf="project.ClientName">
      <dl>
        <dt>{{ 'project-tech-specs-client' | fromDictionary }}</dt>
        <dd>{{ project.ClientType == 0 || !project.ClientName ? 'Undisclosed' : project.ClientName }}</dd>
      </dl>
    </li>
    <li *ngIf="programs">
      <dl>
        <dt>{{ 'project-tech-specs-programs' | fromDictionary }}</dt>
        <dd>
          <ng-container *ngFor="let program of project.Programs; let last = last">
            <a [routerLink]="['/projects']" [queryParams]="{ programs: program.Name }">{{ program.Name }}</a>
            <ng-container>{{ last ? '' : ', ' }}</ng-container>
          </ng-container>
        </dd>
      </dl>
    </li>
    <li *ngIf="themes">
      <dl>
        <dt>{{ 'project-tech-specs-themes' | fromDictionary }}</dt>
        <dd>
          <ng-container *ngFor="let theme of project.Themes; let last = last">
            <a [routerLink]="['/projects']" [queryParams]="{ themes: theme.title }">{{ theme.title }}</a>
            <ng-container>{{ last ? '' : ', ' }}</ng-container>
          </ng-container>
        </dd>
      </dl>
    </li>

    <!-- Certifications -->
    <li *ngIf="project.Certificates?.length">
      <dl>
        <dt>{{ 'project-tech-specs-certifications' | fromDictionary }}</dt>
        <dd *ngFor="let certificate of project.Certificates">{{ certificate.Name }} - {{ certificate.State }}</dd>
      </dl>
    </li>
  </ul>
</section>
