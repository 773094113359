
import * as throttle from 'lodash/throttle';
import { Injectable, EventEmitter, NgZone, Renderer2 } from '@angular/core';

@Injectable()
export class ParallaxService {
  scrolled = new EventEmitter<any>();

  constructor(private zone: NgZone) {
    // listen to scroll every {x}miliseconds
    // ...and emit 'scrolled' event
    // this.zone.runOutsideAngular(() => {
    //   if (typeof window !== 'undefined') {
    //     const interval = 16;
    //     const callback = throttle(e => this.scrolled.emit(e), interval).bind(this);
    //     window.document.addEventListener('scroll', callback);
    //     window.document.addEventListener('resize', callback);
    //     callback();
    //   }
    // });
  }
}
