import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/projects.action';
import * as utility from '../../../utility';
import { Project } from '../../models/project.interface';

export interface ProjectsState {
  entities: { [id: number]: Project };
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: ProjectsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const ProjectsReducer = createReducer(
  initialState,
  on(pageActions.LoadProject, (state: ProjectsState) => {
    return {
      ...state,
      loaded: false,
      loading: true,
    };
  }),
  on(pageActions.LoadProjectSuccess, (state: ProjectsState, { project }) => {
    const entities = {
      ...state.entities,
      [project.Id]: project,
    };

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadProjectFail, (state: ProjectsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getProjectsEntities = (state: ProjectsState) => state.entities;
export const getProjectsLoading = (state: ProjectsState) => state.loading;
export const getProjectsLoaded = (state: ProjectsState) => state.loaded;
