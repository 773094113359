import { isPlatformBrowser } from '@angular/common';
import { Component, OnInit, Input, Inject, PLATFORM_ID } from '@angular/core';
import { Utility } from '../../../core/classes/utility';

@Component({
  selector: 'app-google-static-map',
  templateUrl: './google-static-map.component.html',
  styleUrls: ['./google-static-map.component.scss'],
})
export class GoogleStaticMapComponent implements OnInit {
  @Input() latitude;
  @Input() longitude;
  @Input() zoom = 16;
  @Input() scale = 2;
  @Input() mapType = 'satellite';
  @Input() markers = `color:white%7Csize:tiny%7C`;
  @Input() format = 'jpg';

  staticUrl = `//maps.googleapis.com/maps/api/staticmap?key=AIzaSyAs20OmCdNosCYqtATLQpv5e2upswcjyR8&center=`;
  size = '1020x1020';
  loadMap = false;
  gStaticMapUrl;

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngOnInit() {
    if (isPlatformBrowser(this.platformId)) {
      this.size = Utility.getBreakpoint() === 'breakpoint-mobile' ? '640x640' : '1020x1020';
    }
    this.gStaticMapUrl = `${this.staticUrl}${this.latitude},${this.longitude}&zoom=${this.zoom}&maptype=${this.mapType}&size=${this.size}&scale=${this.scale}&markers=${this.markers}${this.latitude},${this.longitude}&formats=${this.format}`;
  }
}
