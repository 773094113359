import { Brick } from './../../../bricks/models/brick.model';
import { Component, OnInit, Input, EventEmitter, AfterViewInit, Output } from '@angular/core';

@Component({
  selector: 'app-post-news',
  templateUrl: './post-news.component.html',
})
export class PostNewsComponent implements OnInit, AfterViewInit {
  @Input() item: Brick;
  @Input() imageWidth: number = 360;
  @Input() imageHeight: number = 0;
  @Input() hideCategory: boolean = false;
  @Input() showEventDateCard: boolean = false;
  @Output() load = new EventEmitter<any>(null);
  // inview = false;
  constructor() {}

  ngOnInit() {}

  ngAfterViewInit() {
    this.load.emit(true);
  }

  get imageHeightRule() {
    return this.imageHeight ? `&height=${this.imageHeight}` : '';
  }
}
