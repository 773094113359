import { Component, OnInit, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { DomSanitizer } from '@angular/platform-browser';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-pressroom',
  templateUrl: './pressroom.component.html',
})
export class PressroomComponent implements OnInit, AfterViewInit, OnDestroy {
  iframeUrl = 'https://legacy.mvrdv.boerdamdns.nl/en/about/pressroom/overview?standalone=true';
  pressroomUrl = 'https://pressroom.mvrdv.fstr.io/elements.min.js';

  backUrl: string;
  backTitle: string;
  imageLoaded = false;
  hasMenu: boolean;
  background = 'light';
  noBody = false;
  private _unsubscribe$ = new Subject<void>();

  constructor(private route: ActivatedRoute, private sanitizer: DomSanitizer, private nav: MainNavService) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      this.backUrl = '/' + a.apiIdentifier;
      this.backTitle = a.apiIdentifier;
      this.hasMenu = a.menu;
      this.background = a.background || 'light';

      this.nav.updateLama();
    });
    if (this.route.snapshot.queryParams.id) {
      this.iframeUrl += `&id=${this.route.snapshot.queryParams.id}`;
    }
  }

  cleanUrl(url) {
    return this.sanitizer.bypassSecurityTrustResourceUrl(url);
  }

  ngAfterViewInit() {
    this.nav.updateLama();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
