<app-loader [loading]="loading" [dark]="false" [fixed]="true"></app-loader>
<div class="app">
  <app-main-nav *ngIf="!loading" [items]="navItems$ | async" [themes]="themes$ | async"></app-main-nav>

  <div [@fadeRouteAnimation]="getRouterOutletState(o)" class="router-animation" [class.set--loading]="loading">
    <router-outlet #o="outlet"></router-outlet>
  </div>
  <app-json-ld *ngIf="socialsLoaded$ | async" [json]="schema$ | async"></app-json-ld>
  <app-cookie-notice *ngIf="dictionaryLoaded$ | async"></app-cookie-notice>
</div>
