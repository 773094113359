import { isPlatformBrowser } from '@angular/common';
import {
  Component,
  AfterViewInit,
  Input,
  EventEmitter,
  Output,
  ViewChildren,
  QueryList,
  PLATFORM_ID,
  Inject,
  OnDestroy,
  ChangeDetectionStrategy,
} from '@angular/core';
import { ThemeSummary } from '../../../theme/models/theme-summary.model';
import { ThemeSlideComponent } from './../theme-slide/theme-slide.component';
import { Theme } from '../../models/theme.interface';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-theme-slides',
  templateUrl: './theme-slides.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSlidesComponent implements AfterViewInit, OnDestroy {
  @Input() themes: Theme[];
  @Input() active: ThemeSummary;
  @Output() load = new EventEmitter<boolean>();
  @ViewChildren(ThemeSlideComponent) themeSlideComponents: QueryList<ThemeSlideComponent>; // get app-theme-slide components
  private _unsubscribe$ = new Subject<void>();

  private _loadEmitted = false;

  constructor(@Inject(PLATFORM_ID) private platformId) {}

  ngAfterViewInit() {
    if (isPlatformBrowser(this.platformId) && !this._loadEmitted) {
      this.load.emit(true);
    }
  }

  trackBy(index, item: any) {
    return item.id;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
