import { createAction, props } from '@ngrx/store';

export const AcceptCookies = createAction('[Cookies] Accept Cookies');
export const RejectCookies = createAction('[Cookies] Reject Cookies');

export const InitCookieSettings = createAction('[Cookies] Initialize Cookie Settings From Local Storage');
export const InitCookieSettingsSuccess = createAction(
  '[Cookies] Initialize Cookie Settings From Local Storage Success',
  props<{ accept: string }>()
);
export const InitCookieSettingsFail = createAction('[Cookies] Initialize Cookie Settings From Local Storage Fail', props<{ error: any }>());

export const ClearCookieSettings = createAction('[Cookies] Clear User Cookie settings');
