import { Photo } from './../../models/photo.interface';
import { Component, OnInit, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-featured-photo',
  templateUrl: './featured-photo.component.html',
})
export class FeaturedPhotoComponent implements OnInit {
  @Input() photo: any;

  isLoaded = false;
  load = new EventEmitter<any>();
  inview = false;
  html5PlayerVars = {};

  constructor() {}

  ngOnInit() {
    this.html5PlayerVars = {
      autoplay: this.photo.mediaItem.autoPlay,
      controls: !this.photo.mediaItem.hideControls,
      muted: this.photo.mediaItem.autoPlay || this.photo.mediaItem.hideControls ? true : false,
      loop: this.photo.mediaItem.loop,
    };
  }
}
