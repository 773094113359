import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { Content } from '../../content/models/content.model';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ThemeContentResolve implements Resolve<Content[]> {
  apiBase = `${environment.apiUrl}/themes`;

  constructor(private http: HttpClient) {}

  resolve(route: ActivatedRouteSnapshot): Observable<Content[]> {
    const id = +route.paramMap.get('id');
    return this.http.get<Content[]>(`${this.apiBase}/get/${id}/content`);
  }

  getByTheme(id) {
    return this.http.get<Content[]>(`${this.apiBase}/get/${id}/content`);
  }
}
