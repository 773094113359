import { EmployeeModule } from './../employee/employee.module';
import { BricksModule } from './../bricks/bricks.module';
import { UpdatesResolve } from './services/updates.resolve';
import { SignupNewsletterComponent } from './components/signup-newsletter/signup-newsletter.component';
import { ModalModule } from './../modal/modal.module';
import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

import { ContactComponent } from './containers/contact/contact.component';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ContentOverviewComponent } from './containers/content-overview/content-overview.component';
import { ContentDetailComponent } from './containers/content-detail/content-detail.component';
import { ContentOverviewService } from './services/content-overview.service';
import { ContentDetailService } from './services/content-detail.service';
import { ContentRoutingModule } from './content-routing.module';
import { NewsBlockComponent } from './components/news-block/news-block.component';
import { RecruiteeComponent } from './components/recruitee/recruitee.component';
import { CoreModule } from '../core/core.module';
import { EventBlockComponent } from './components/event-block/event-block.component';
import { PostModule } from '../post/post.module';
import { ShopBlockComponent } from './components/shop-block/shop-block.component';
import { AwardBlockComponent } from './components/award-block/award-block.component';
import { constants } from '../core/constants';
import { FormsModule } from '@angular/forms';
import { AwardsOverviewComponent } from './containers/awards-overview/awards-overview.component';
import { ProjectModule } from '../project/project.module';
import { PressroomComponent } from './components/pressroom/pressroom.component';
import { AwardsComponent } from './containers/awards/awards.component';
import { CookieModule } from '../cookie/cookie.module';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';
@NgModule({
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  imports: [
    CommonModule,
    ContentRoutingModule,
    CoreModule,
    PostModule,
    ModalModule,
    FormsModule,
    BricksModule,
    AgmCoreModule.forRoot({ apiKey: constants.mapsApiKey }),
    AgmSnazzyInfoWindowModule,
    ProjectModule,
    EmployeeModule,
    CookieModule,
    DictionaryNgrxModule,
  ],
  declarations: [
    ContentOverviewComponent,
    ContentDetailComponent,
    AwardsOverviewComponent,
    NewsBlockComponent,
    EventBlockComponent,
    RecruiteeComponent,
    ShopBlockComponent,
    AwardBlockComponent,
    ContactComponent,
    SignupNewsletterComponent,
    PressroomComponent,
    AwardsComponent,
  ],
  providers: [ContentOverviewService, ContentDetailService, UpdatesResolve],
  exports: [ContentOverviewComponent, ContentDetailComponent, AwardsComponent],
})
export class ContentModule {}
