<section class="award-section background--dark" #awardsContainer>
  <div class="award-section__inner">
    <h1 class="content-detail__header__title">{{ 'awards-title' | fromDictionary }}</h1>

    <ul class="award-grid" [class.is--open]="showAll">
      <!--[style.--list-length]="awards.length"-->
      <li class="award-grid__item" *ngFor="let award of awards | slice : 0 : (showAll ? awards.length : 4)">
        <article class="award" [class.no--image]="!award.image && !noImages">
          <img
            class="award__image"
            *ngIf="award.image"
            [src]="award.image + '?width=360&height=360&mode=pad&quality=75'"
            [alt]="award.shortTitle || award.title"
          />
          <main class="award__text">
            <h2 class="h6 mb-0">
              <ng-container *ngIf="award.awardedOn">
                <small class="">{{ award.awardedOn | date : 'YYYY' }}</small>
              </ng-container>

              <span *ngIf="award.title">{{ award.shortTitle || award.title }}</span>
            </h2>
            <p class="mb-0" *ngIf="award.subTitle">{{ award.subTitle }}</p>
          </main>
        </article>
      </li>

      <li class="award-grid__load-more" *ngIf="awards.length > 3 " [class.has--1-more]="awards.length === 4">
        <button class="button button--type--outline button--type--outline-white"  *ngIf="!showAll" (click)="showAll = !showAll">
          <span>{{ 'awards-more-button-text' | fromDictionary }}</span>
        </button>
        <button class="button button--type--outline button--type--outline-white" *ngIf="showAll" (click)="lessAwards()">
          <span >{{ 'awards-less-button-text' | fromDictionary }}</span>
        </button>

      </li>
    </ul>
  </div>
</section>
