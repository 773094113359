import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripTwitterCrap'
})
export class StripTwitterCrapPipe implements PipeTransform {
  transform(value = '') {
    const split = value.split(/“(.*?)”/igm);
    return split && split.length === 3 ? split[1] : value;
  }
}
