import { ParallaxService } from './parallax.service';
import { Input, ElementRef, Directive, ChangeDetectorRef, NgZone, AfterViewInit, OnChanges, OnInit } from '@angular/core';
import { ParallaxItem } from './parallax-item.interface';
import { elementInViewport } from '../../globals';
import * as throttle from 'lodash/throttle';
import { HostListener } from '@angular/core';
import { Subject } from 'rxjs';

@Directive({
  selector: '[appParallax]',
})
export class ParallaxDirective implements OnInit {
  private scrollSubject = new Subject<any>();
  private scrollObservable = this.scrollSubject.asObservable();

  @Input() parallaxSpeed = 5;
  @Input() parallaxRatio = 1;
  @Input() parallaxType: 'top' | 'bottom' | 'transform' | 'background-position-y' | 'margin-top' | 'margin-bottom' = 'transform';
  deviceInfo: any = null;

  @HostListener('window:scroll', ['$event']) onscroll(e) {
    this.scrollSubject.next(e);
  }

  constructor(private element: ElementRef, private cd: ChangeDetectorRef, private zone: NgZone, private service: ParallaxService) {
    this.cd.detach();
    this.zone.runOutsideAngular(() => {
      //this.update();
      this.scrollObservable.subscribe(x => this.update());
    });

    this.cd.reattach();
  }

  ngOnInit() {}

  update() {
    // const element = this.element.nativeElement;
    // if (elementInViewport(element)) {
    //   const top = element.getBoundingClientRect().top;
    //   const yPos = `${(-(top / this.parallaxSpeed)) * this.parallaxRatio}px`;
    //   const yPosStyle = this.parallaxType === 'transform' ? `translate3d(0, ${yPos}, 0)` : yPos;
    //   element.style[this.parallaxType] = yPosStyle;
    // }
  }
}
