<article class="post-teaser" (appInViewport)="countStart = true">
  <div class="post-teaser__text" *ngIf="text">{{text}}</div>

  <div class="post-teaser__heading">
    <h1 class="post-teaser__heading__title">
        <a
          [routerLink]="url"
          *ngIf="url; else noUrl"
          [innerHTML]="title | superScript | safe">
        </a>
        <ng-template #noUrl>{{title}}</ng-template>
      </h1>
  </div>

  <a class="post-teaser__button" [routerLink]="url" *ngIf="url && showArrow">
    <app-icon [anchor]="'icon-arrow'"></app-icon>
  </a>
</article>
