import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { first, map } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class LegacyProjectGuard implements CanActivate {
  private API_URL = environment.apiUrl;

  constructor(private _http: HttpClient, private router: Router) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    const slug = route.params.title;

    return this._http.get<any>(`${this.API_URL}/projects/legacy/${slug}`).pipe(
      first(),
      map(a => {
        if (a.found) {
          this.router.navigate(a.url);
          return true;
        }
        this.router.navigate(['/projects']);
        return false;
      })
    );
  }

  get(id): Observable<any> {
    return this._http.get(`${this.API_URL}/projects/${id}`);
  }

  find(): Observable<any> {
    return this._http.get<any>(`${this.API_URL}/projects/programs`);
  }
}
