<div class="theme-slides">

  <app-theme-slide
    *ngFor="let theme of themes; let i = index; trackBy: trackBy"
    [id]="theme.fragment"
    [class.is--active]="active?.id === theme?.id">

    <section class="theme-slide__wrapper">
      <app-post-teaser-banner
        [theme]="theme"
        [isActive]="active?.id === theme?.id"
        class="theme-slide background--{{theme?.image_light ? 'light' : 'dark'}}">
      </app-post-teaser-banner>
      <ul
        class="theme-slide__list background--light">
        <li class="theme-slide__list__item" *ngFor="let proof of theme.proofLinks; trackBy: trackBy">
          <app-theme-teaser [proofLink]="proof"></app-theme-teaser>
        </li>
      </ul>
    </section>

  </app-theme-slide>

</div>
