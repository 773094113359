import { Component, OnInit, Input } from '@angular/core';
import { News } from '../../models/news.model';

@Component({
  selector: 'app-news-block',
  templateUrl: './news-block.component.html'
})
export class NewsBlockComponent implements OnInit {
  @Input() item: News;

  constructor() { }

  ngOnInit() {
  }

}
