import { Component, AfterContentInit, Input, ElementRef } from '@angular/core';
import { Theme } from '../../models/theme.interface';
import { ThemeSummary } from './../../../theme/models/theme-summary.model';

@Component({
  selector: 'app-theme-grid',
  templateUrl: './theme-grid.component.html',
})
export class ThemeGridComponent implements AfterContentInit {
  @Input() themes: Theme[];
  isActive = false;

  constructor(private element: ElementRef) {}

  ngAfterContentInit() {}

  trackBy(index, item: any) {
    return item.id;
  }
}
