import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, switchMap, catchError } from 'rxjs/operators';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getProjectEventsLoaded, getProjectEventsLoading, LoadProjectEvents } from '../store';
import { getRouterState } from '../../store';

@Injectable({ providedIn: 'root' })
export class ProjectEventsGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return combineLatest([
      this.store.select(getProjectEventsLoaded),
      this.store.select(getProjectEventsLoading),
      this.store.select(getRouterState),
    ]).pipe(
      tap(([loaded, loading, router]) => {
        if (loaded !== router.state.params.id && loading !== router.state.params.id) {
          this.store.dispatch(LoadProjectEvents({ id: router.state.params.id }));
        }
      }),
      map(([loaded, , router]) => loaded === router.state.params.id)
    );
  }
}
