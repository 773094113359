import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'reverse'
})
export class ReversePipe implements PipeTransform {


  transform(items: any[]): any[] {
    return items.reverse();
  }
}
