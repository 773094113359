import { EmployeeService } from './../../services/employee.service';
import { staggerOpacityAnimation } from './../../../core/fade.animation';
import { MainNavService } from './../../../main-nav/main-nav.service';
import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { EmployeeSummary } from '../../interfaces/employee-summary.interface';
import { Meta, Title } from '@angular/platform-browser';
import { takeUntil } from 'rxjs/operators';
import { Observable, Subject } from 'rxjs';
import { FromDictionaryPipe } from '../../../dictionary/pipes/from-dictionary.pipe';

import * as employeeSummarySelectors from '../../store/selectors';
import { Store } from '@ngrx/store';
@Component({
  selector: 'app-employee-overview',
  templateUrl: './employee-overview.component.html',
  animations: [staggerOpacityAnimation],
})
export class EmployeeOverviewComponent implements OnInit, OnDestroy {
  employees: EmployeeSummary[];
  employeeSummaries$: Observable<EmployeeSummary[]>;
  private _unsubscribe$ = new Subject<void>();

  constructor(private employeeService: EmployeeService, private nav: MainNavService, title: Title, meta: Meta, private dict: FromDictionaryPipe, private store: Store) {
    title.setTitle(`MVRDV - ${this.dict.transform('employee-meta-title')}`);
    meta.addTags([
      { property: 'og:title', content:  `MVRDV - ${this.dict.transform('employee-meta-title')}` },
      {
        property: 'og:description',
        content: this.dict.transform('employee-meta-description'),
      },
      {
        name: 'description',
        content: this.dict.transform('employee-meta-description'),
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@MVRDV' },
      { name: 'twitter:title', content: `MVRDV - ${this.dict.transform('employee-meta-title')}` },
      {
        name: 'twitter:description',
        content: this.dict.transform('employee-meta-description'),
      },
    ]);
  }

  ngOnInit() {
    this.employeeService
      .all()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(e => (this.employees = e));
    this.nav.setFill('dark');

    this.employeeSummaries$ = this.store.select(employeeSummarySelectors.getAllEmployeeSummaries);
  }

  trackBy(index, item: EmployeeSummary) {
    return item.id;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
