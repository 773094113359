<section class="modal" [class.is--active]="isActive">

  <button class="modal__close-button" (click)="closeModal()">
    <button class="main-nav-toggle fill--dark is--active">
      <span>Toggle nav</span>
    </button>
  </button>

  <div class="modal__container">

    <div class="modal__container__inner">
      <ng-content></ng-content>
    </div>
  </div>

  <div class="modal__bg" (click)="closeModal()"></div>

</section>
