import { Routes, RouterModule } from '@angular/router';
import { NotFoundComponent } from './404.component';
import { CookieSettingsGuard } from './cookie/guards/cookie-settings.guard';
import { ActiveLanguageGuard, LanguageGuard } from './language/guards';
import { DictionaryGuard } from './dictionary/guards/dictionary.guard';
import { NgModule } from '@angular/core';
import { ThemesGuard } from './theme/guards';
import { MenuItemsGuard } from './main-nav/guards';
import { SocialLinksGuard } from './main-nav/guards/social-links.guard';

const appGuards = [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard, ThemesGuard, MenuItemsGuard, SocialLinksGuard];

export const routes: Routes = [
  {
    path: 'home',
    redirectTo: '',
    pathMatch: 'full',
  },
  {
    path: '',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
    pathMatch: 'full',
    canActivate: [...appGuards],
  },
  // {
  //   path: 'about/team',
  //   loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
  //   canActivate: [...appGuards],
  // },
  {
    path: 'search',
    loadChildren: () => import('./search/search.module').then(m => m.SearchModule),
    canActivate: [...appGuards],
  },
  {
    path: 'styleguide',
    loadChildren: () => import('./styleguide/styleguide.module').then(m => m.StyleguideModule),
  },
  {
    path: 'themes',
    loadChildren: () => import('./theme/theme.module').then(m => m.ThemeModule),
    canActivate: [...appGuards],
  },
  {
    path: 'projects',
    loadChildren: () => import('./project/project.module').then(m => m.ProjectModule),
    canActivate: [...appGuards],
  },

  {
    path: 'about/team/:id/:name',
    loadChildren: () => import('./employee/employee.module').then(m => m.EmployeeModule),
    canActivate: [...appGuards],
  },
  {
    path: '404',
    pathMatch: 'full',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
  {
    path: '',
    loadChildren: () => import('./content/content.module').then(m => m.ContentModule),
    canActivate: [...appGuards],
  },

  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [...appGuards],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
