import { Component, Input, OnInit } from '@angular/core';
import { Content } from '../../../content/models/content.model';
import { Brick } from '../../../bricks/models/brick.model';
import { Event } from '../../../content/models/event.model';
import { FromDictionaryPipe } from '../../../dictionary/pipes/from-dictionary.pipe';

@Component({
  selector: 'app-project-content',
  templateUrl: './project-content.component.html',
})
export class ProjectContentComponent implements OnInit {
  @Input() content: Content[];
  @Input() projectTitle: string;

  constructor(private dict: FromDictionaryPipe) {}

  ngOnInit(): void {}

  // Copied from content-overview filters
  types = [
    {
      type: 'updates',
      title: this.dict.transform('overview-all-filter-title'),
      url: '/updates',
    },
    {
      type: 'news',
      title: this.dict.transform('overview-news-filter-title'),
      url: '/news',
    },
    {
      type: 'events',
      title: this.dict.transform('overview-events-filter-title'),
      url: '/events',
    },
    {
      type: 'stack-magazine',
      title: this.dict.transform('overview-stack-magazine-filter-title'),
      url: '/stack-magazine',
    },
    {
      type: 'mvrdv-tv',
      title: this.dict.transform('overview-mvrdv-tv-filter-title'),
      url: '/mvrdv-tv',
    },
  ];

  get bricks() {
    return this.content.map(b => this.contentToBrick(b));
  }

  trackBy(index, item: any) {
    return item.id;
  }

  contentToBrick(item: Content): Brick {
    return {
      Id: item.id,
      Title: item.title,
      Description: item.text,
      Type: 'article',
      ImageUrl: item.image,
      Url: item.url,
      Favicon: null,
      SiteName: null,
      DateAdded: (<Event>(<unknown>item)).eventDate || item.publishDate,
      Author: null,
      ProjectId: null,
      HostName: null,
      ParentTitle: this.types.filter(t => t.type === item.contentType)[0].title,
      ParentUrl: this.types.filter(t => t.type === item.contentType)[0].url,
    };
  }
}
