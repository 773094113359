<header class="post-teaser-banner" [class.is--active]="isActive && loaded" [class.type--no-overlay]="!theme?.overlay">
  <!-- background image -->
  <figure class="post-teaser-banner__crop" (appInViewport)="inview = $event">
    <app-loader [loading]="!loaded" [dark]="!theme.image_light"></app-loader>
    <a [routerLink]="theme.externalUrl ? [theme.externalUrl] : theme.routerLink || ['/themes', theme.id, theme.title | urlRewrite]">
      <app-image
        class="post-teaser-banner__image"
        [class.set--active]="isActive"
        [class.is--loaded]="loaded"
        *ngIf="theme.image && !theme.video && inview"
        [src]="theme.image"
        loading="lazy"
        (loaded)="loadedImg($event)"
        [alt]="theme.title"
      ></app-image>

      <app-html5-video
        *ngIf="theme.video && inview"
        class="post-teaser-banner__video"
        [play]="isActive"
        (load)="loaded = true"
        [class.is--loaded]="loaded"
        [videoSrc]="theme.video"
        [posterSrc]="theme.image"
        [playerVars]="{ muted: true, loop: true, controls: false }"
      ></app-html5-video>
    </a>
  </figure>

  <!-- end: background image -->
  <!-- text overlay -->
  <a
    class="post-teaser-banner__content"
    [externalLink]="theme.externalUrl?.startsWith('http') ? theme.externalUrl : null"
    [routerLink]="theme.externalUrl ? [theme.externalUrl] : theme.routerLink || ['/themes', theme.id, theme.title | urlRewrite]"
  >
    <div class="post-teaser-banner__content__wrapper">
      <!-- svg text -->
      <h1 class="post-teaser-banner__content__claim">
        <span class="post-teaser-banner__content__claim__span" *ngFor="let claim of theme.claim_array">{{ claim }}</span>
      </h1>
      <!-- end: svg text -->

      <!-- proof text -->
      <div class="post-teaser-banner__content__text">
        <p>{{ theme.proof }}</p>
      </div>
      <!-- end: proof text -->
    </div>
  </a>
  <!-- end: text overlay -->
</header>
