import { Component, OnInit, Input, NgZone, ElementRef } from '@angular/core';

@Component({
  selector: 'app-theme-teaser',
  templateUrl: './theme-teaser.component.html'
})
export class ThemeTeaserComponent implements OnInit {
  @Input() proofLink: any;
  countStart = false;

  constructor(
    private zone: NgZone,
    private element: ElementRef
  ) {}

  ngOnInit(): void {
  }
}
