import { Inject, Injectable, Optional, PLATFORM_ID } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as langActions from '../actions/language.action';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { LanguageService } from '../../services';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import { LanguageService as LNG } from '../../../dictionary/services/language.service';

@Injectable()
export class LanguageEffects {
  private STORAGE_KEY = 'LANGUAGE';

  constructor(
    private actions$: Actions,
    private langService: LanguageService,
    @Inject(PLATFORM_ID) private platformId,
    private lng: LNG,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  LoadLanguages$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(langActions.LoadLanguages),
      switchMap(() =>
        this.langService.getLanguages().pipe(
          map(languages => langActions.LoadLanguagesSuccess({ languages })),
          catchError(error => of(langActions.LoadLanguagesFail({ error })))
        )
      )
    );
  });

  setActiveLanguage$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(langActions.SetActiveLanguage),
      map(({ activeLang }) => {
        // if (isPlatformBrowser(this.platformId)) {
        //   localStorage.setItem(this.STORAGE_KEY, activeLang);
        //   location.reload();
        // }

        // this.lng.setLanguage(activeLang);
        return langActions.SetActiveLanguageSuccess({ activeLang });
      })
    );
  });

  InitLanguageSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(langActions.InitActiveLanguage),
      map(() => {
        const lang = this.BASE_URL.indexOf('.asia') >= 0 ? 'CN' : 'EN';

        if (!isPlatformBrowser(this.platformId)) {
          return langActions.InitActiveLanguageComplete({ activeLang: lang });
        }

        // const storedLanguageSettings = localStorage.getItem(this.STORAGE_KEY);

        // if (storedLanguageSettings) {
        //   this.lng.setLanguage(storedLanguageSettings);
        //   return langActions.InitActiveLanguageComplete({ activeLang: storedLanguageSettings });
        // }

        this.lng.setLanguage(lang.toLowerCase());
        return langActions.InitActiveLanguageComplete({ activeLang: lang });
      })
    );
  });
}
