import { NgZone, Component, Input, ElementRef, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-post-teaser',
  templateUrl: 'post-teaser.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PostTeaserComponent implements AfterViewInit {
  @Input() text: string;
  @Input() title: string;
  @Input() url: string;
  @Input() showArrow = true;
  countStart = false;

  constructor(
    private zone: NgZone,
    private element: ElementRef
  ) {}

  ngAfterViewInit() {
  }

}
