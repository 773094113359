import { PostNavItem } from './../../../post/interfaces/post-nav.interface';
import { Brick } from './../../models/brick.model';
import {
  Component,
  OnInit,
  ElementRef,
  Input,
  AfterViewInit,
  OnChanges,
  SimpleChanges,
  HostListener,
  Inject,
  PLATFORM_ID,
} from '@angular/core';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

if (typeof window !== 'undefined') {
  (window as any).Isotope = require('isotope-layout/js/isotope');
}

@Component({
  selector: 'app-brick-wall',
  templateUrl: './brick-wall.component.html',
})
export class BrickWallComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() items: Brick[];
  @Input() title: string;
  @Input() filters: PostNavItem[];
  @Input() showTotalUpdates: number;
  isotope;
  inview = false;
  showNewsLetter = false;

  constructor(private ref: ElementRef, @Inject(DOCUMENT) private document: any, @Inject(PLATFORM_ID) private platformId) {}

  ngOnInit() {
    const $container = this.ref.nativeElement.querySelector('[grid]');

    if (!this.showTotalUpdates) {
      this.showTotalUpdates = this.items.length;
    }

    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
    }

    if ($container && typeof window !== 'undefined' && (window as any).Isotope) {
      this.isotope = new (window as any).Isotope($container, {
        itemSelector: '[grid-item]',
        masonry: {
          columnWidth: '[grid-sizer]',
          horizontalOrder: true,
        },
      });
    }
  }

  ngAfterViewInit() {
    this.update();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes && changes['items']) {
      this.update();
    }
  }

  update() {
    if (this.isotope) {
      this.isotope.reloadItems();
      this.isotope.arrange();
    }
  }

  @HostListener('window:scroll', ['$event'])
  onWindowScroll() {
    if (isPlatformBrowser(this.platformId)) {
      const number = this.document.pageYOffset || this.document.documentElement.scrollTop || this.document.body.scrollTop || 0;

      if (number > window.outerHeight / 1) {
        this.showNewsLetter = true;
      } else if (number < window.outerHeight / 1) {
        this.showNewsLetter = false;
      }
    }
  }

  trackByFn(index, item) {
    return item.id;
  }
}
