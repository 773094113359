import { AfterViewInit, Component, Input, OnInit, ViewChild } from '@angular/core';
import { Project } from '../../models/project.interface';
import { SwiperComponent, SwiperConfigInterface } from 'ngx-swiper-wrapper';
import Swiper from 'swiper';

@Component({
  selector: 'app-related-projects',
  templateUrl: './related-projects.component.html',
})
export class RelatedProjectsComponent {
  @Input() projects: Project[];

  @ViewChild('swiper', { static: true }) swiper: SwiperComponent;

  swiperConfig: SwiperConfigInterface = {
    slidesPerView: 1.5,
    keyboard: true,
    effect: 'slide',
    loop: false,
    spaceBetween: 20,
    navigation: {
      nextEl: '.swiper-button-next',
      prevEl: '.swiper-button-prev',
    },
    breakpoints: {
      768: {
        spaceBetween: 30,
        slidesPerView: 2.5,
      },
      1024: {
        spaceBetween: 40,
        slidesPerView: 2.5,
      },
    },
  };

  trackByFn(index, item) {
    return item.id;
  }
}
