<main class="content-overview">
  <header class="content-overview__header container" hidden>
    <h1 class="content-overview__title">{{title}}</h1>
  </header>

  <app-brick-wall
    [filters]="filters"
    [items]="contentItemsSocial">
  </app-brick-wall>

  <app-pagination
    [total]="itemCount"
    [currentPage]="currentPage"
    [pageSize]="pageSize"
    [prefix]="prefix">
  </app-pagination>
</main>

