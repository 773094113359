import { Theme } from './../../../theme/models/theme.interface';
import { OnChanges } from '@angular/core';
//import { textAnimation, strokeAnimation } from './animations';
import { Component, OnInit, Input, SimpleChanges, ElementRef } from '@angular/core';

@Component({
  selector: 'app-post-teaser-banner',
  templateUrl: 'post-teaser-banner.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush,
  // animations: [
  //   textAnimation,
  //   strokeAnimation
  // ]
})
export class PostTeaserBannerComponent implements OnInit, OnChanges {
  @Input() theme: Theme;
  @Input() isActive = false; // default false
  inview = true;
  loaded = true;

  constructor(private element: ElementRef) {}

  ngOnInit() {}

  ngOnChanges(changes: SimpleChanges) {}

  loadedImg(e) {
    this.loaded = e;
  }
}
