import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Event } from '../../../content/models/event.model';
import { Brick } from '../../../bricks/models/brick.model';

@Component({
  selector: 'app-project-events',
  templateUrl: './project-events.component.html',
})
export class ProjectEventsComponent {
  @Input() upcomingEvents: Event[] = [];
  @Input() pastEvents: Event[] = [];
  @ViewChild('eventsContainer') eventsContainer: ElementRef;

  showAll = false;
  showAllHistory = false;

  defaultPaging = 2;
  itemsToLoad: number = 2;
  currentIndexHisotry: number = this.defaultPaging;
  currentIndex: number = this.defaultPaging;

  constructor() {}

  ngOnInit(): void {
    //console.log(this.pastEvents);
    // this.upcomingEvents = this.pastEvents; // for testing
  }

  get allEvents(): Event[] {
    return [...this.upcomingEvents, ...this.pastEvents];
  }

  get eventCards(): Brick[] {
    // not needed client wil list cards for pastevents
    // if (this.pastEvents.length > 0 && this.upcomingEvents.length === 0) {
    //   return this.asBricks(this.pastEvents);
    // }

    return this.asBricks(this.upcomingEvents);
  }

  get title(): string {
    if (this.upcomingEvents.length > 0 && this.pastEvents.length === 0) {
      return 'upcomping-events-title';
    }
    if (this.pastEvents.length > 0 && this.upcomingEvents.length === 0) {
      return 'past-events-title';
    }
    return 'related-events-title';
  }

  asBricks(items: Event[]): Brick[] {
    return items.map(events => {
      return {
        Id: events.id,
        Title: events.title,
        Description: events.text,
        Type: 'event',
        ImageUrl: events.image,
        Url: events.url,
        DateAdded: events.eventDate,
      };
    });
  }

  lessEvents() {
    this.currentIndex = this.defaultPaging;

    setTimeout(() => {
      this.eventsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  lessEventsHistory() {
    this.currentIndexHisotry = this.defaultPaging;

    setTimeout(() => {
      this.eventsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  trackBy(index, item: any) {
    return item.id;
  }
}
