import { createAction, props } from '@ngrx/store';
import { Theme } from '../../models/theme.interface';

export const LoadThemes = createAction('[Themes] Load Themes');
export const LoadThemesSuccess = createAction('[Themes] Load Themes Success', props<{ themes: Theme[] }>());
export const LoadThemesFail = createAction('[Themes] Load Themes Fail', props<{ error: any }>());

export const LoadTheme = createAction('[Themes] Load Theme', props<{ id: number }>());
export const LoadThemeSuccess = createAction('[Themes] Load Theme Success', props<{ theme: Theme }>());
export const LoadThemeFail = createAction('[Themes] Load Theme Fail', props<{ error: any }>());
