import { Brick } from './../../../bricks/models/brick.model';
import { ProjectView } from './../../enums/project-view.enum';
import { PostNavItem } from './../../../post/interfaces/post-nav.interface';
import { Photo } from './../../models/photo.interface';
import { Component, OnInit, AfterViewInit, Inject, ChangeDetectionStrategy } from '@angular/core';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { DOCUMENT } from '@angular/common';
import { MediaBlock } from '../../../theme/models/media-block.model';
import { Observable, combineLatest } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCookiesAccepted } from '../../../cookie/store';
import { map, switchMap } from 'rxjs/operators';
import { Project } from '../../models/project.interface';
import {
  getOrderedProjectContent,
  getOrderedProjectSocials,
  getPastProjectEvents,
  getSelectedProject,
  getUpcomingProjectEvents,
} from '../../store';
import { Content } from '../../../content/models/content.model';
import { Event } from '../../../content/models/event.model';
import { Award } from '../../../content/models/award.model';

@Component({
  selector: 'app-project-detail',
  templateUrl: './project-detail.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ProjectDetailComponent implements OnInit, AfterViewInit {
  project$: Observable<Project>;
  photos$: Observable<Photo[]>;
  sharingLinks$: Observable<PostNavItem[]>;
  media$: Observable<MediaBlock[]>;
  otherMedia$: Observable<MediaBlock[]>;

  content$: Observable<Content[]>;
  news$: Observable<Content[]>;
  otherContent$: Observable<Content[]>;
  awards$: Observable<Award[]>;

  upcomingEvents$: Observable<Event[]>;
  pastEvents$: Observable<Event[]>;
  hasRelatedEvents$: Observable<boolean>;

  // socialPosts$: Observable<Brick[]>;
  projectView = ProjectView;
  loadBrickWall = false;

  cookiesAccepted$: Observable<boolean>;

  constructor(private nav: MainNavService, private store: Store, @Inject(DOCUMENT) private document) {}

  ngOnInit() {
    this.cookiesAccepted$ = this.store.select(getCookiesAccepted);
    this.project$ = this.store.select(getSelectedProject);
    this.media$ = this.project$.pipe(map(a => a?.mediaBlocks));
    // this.socialPosts$ = this.store.select(getOrderedProjectSocials);
    this.otherMedia$ = this.media$.pipe(map(media => (media && media.length > 3 ? [...media].slice(3, media.length) : [])));

    this.content$ = this.store.select(getOrderedProjectContent);
    this.news$ = this.content$.pipe(map(content => content.filter(c => c.contentType === 'news')));

    this.otherContent$ = this.content$.pipe(map(content => content.filter(c => !['news', 'awards'].some(a => c.contentType === a))));
    //this.otherContent$ = this.otherContent$.pipe(map(a => [...a, ...a, ...a, ...a]));

    this.awards$ = this.content$.pipe(
      map(content =>
        content
          .filter(c => c.contentType === 'awards')
          .map(a => a as Award)
          // First sort by featured, then by awardedOn
          .sort((a, b) => +b.featured - +a.featured || new Date(b.awardedOn).getTime() - new Date(a.awardedOn).getTime())
      )
    );

    this.upcomingEvents$ = this.store.select(getUpcomingProjectEvents);
    this.pastEvents$ = this.store.select(getPastProjectEvents);
    this.hasRelatedEvents$ = combineLatest([this.upcomingEvents$, this.pastEvents$]).pipe(map(a => a[0].length > 0 || a[1].length > 0));

    this.photos$ = this.project$.pipe(
      map(project => {
        return project?.Files?.filter(file => file.UsedInWebsite)
          .map(photo => {
            return {
              title: photo.Category || 'image', // testing, but looks cool anyway
              id: photo.Id,
              Caption: photo.Caption,
              url: photo.ImageUrl,
              order: photo.Order,
            };
          })
          .sort((p1, p2) => p1.order - p2.order);
      })
    );

    this.sharingLinks$ = this.project$.pipe(
      map(project => {
        const url = this.document.location.href;
        const img = project?.MainImage.ImageUrl;
        return [
          {
            title: 'Facebook',
            url: `https://www.facebook.com/sharer/sharer.php?u=${url}`,
          },
          { title: 'Twitter', url: `https://twitter.com/home?status=${url}` },
          {
            title: 'Pinterest',
            url: `https://pinterest.com/pin/create/button/?url=${url}&media=${img}`,
          },
          {
            title: 'LinkedIn',
            url: `https://www.linkedin.com/shareArticle?mini=true&url=${url}`,
          },
          { title: 'Email', url: `mailto:?&body=${url}` },
        ];
      })
    );
  }

  ngAfterViewInit() {
    this.updateNav();
  }

  updateNav() {
    this.nav.setFill('light');
    this.nav.updateLama();
  }
}
