import { Brick } from './../../../bricks/models/brick.model';
import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-post-social',
  templateUrl: './post-social.component.html',
})
export class PostSocialComponent implements OnInit {
  @Input() item: Brick;
  @Output() load = new EventEmitter<any>();
  playing = false;
  inview = false;
  constructor() {}

  ngOnInit() {}

  togglePlay() {
    // reimplement later or not....
    // const video = el.target;
    // const icon = video.nextElementSibling;
    // if (video.paused) {
    //   video.play();
    //   icon.style.opacity = 0;
    // } else {
    //   video.pause();
    //   icon.style.opacity = 1;
    // }
    // // set video at start when finished
    // video.onended = () => {
    //   video.currentTime = 0;
    //   icon.style.opacity = 1;
    // };
  }
}
