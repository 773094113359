import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as ProjectActions from '../actions/projects.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';

@Injectable()
export class ProjectsEffects {
  constructor(private actions$: Actions, private service: ProjectService) {}

  LoadProject$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectActions.LoadProject),
      switchMap(({ id }) =>
        this.service.get(id).pipe(
          map(project => ProjectActions.LoadProjectSuccess({ project })),
          catchError(error => of(ProjectActions.LoadProjectFail({ error })))
        )
      )
    );
  });
}
