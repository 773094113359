import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProjects from '../reducers/projects.reducer';
import * as fromRoot from '../../../store';
import { Project } from '../../models/project.interface';

export const getProjectsSelectorState = createSelector(fromFeature.getProjectsState, (state: fromFeature.ProjectsState) => state.projects);

export const getProjectsEntities = createSelector(getProjectsSelectorState, fromProjects.getProjectsEntities);

export const getAllProjects = createSelector(getProjectsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedProjects = createSelector(getAllProjects, Projects => {
  return Projects?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getProjectsLoaded = createSelector(getProjectsSelectorState, fromProjects.getProjectsLoaded);
export const getProjectsLoading = createSelector(getProjectsSelectorState, fromProjects.getProjectsLoading);

export const getSelectedProject = createSelector(getProjectsEntities, fromRoot.getRouterState, (entities, router): Project => {
  return router.state && entities[router.state.params?.id || router.state.data?.id];
});
