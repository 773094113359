<main class="theme-timeline background--dark" (appInViewport)="startFirstTime(2000); inview = $event">
  <swiper [config]="config" [(index)]="index" #slidesSwiper>
    <article *ngFor="let item of items" class="theme-timeline__item" (mousedown)="stop()">
      <figure class="theme-timeline__item__image">
        <a [routerLink]="item.url" *ngIf="item.mediaItem.type === 'image'">
          <img
            *ngIf="inview"
            loading="lazy"
            class="theme-timeline__item__image__obj"
            [src]="item.mediaItem.url + '?width=1200&height=900&mode=crop'"
            [alt]="item.title"
          />
        </a>

        <app-youtube
          class="theme-timeline__item__image__obj"
          [url]="item.mediaItem.url"
          *ngIf="item.mediaItem.type === 'video'"
        ></app-youtube>
        <!-- <app-html5-video
          class="content-detail__featured-photo__object"
          *ngIf="true"
          [playerVars]="html5PlayerVars"
          [videoSrc]="html5video"
        ></app-html5-video> -->
      </figure>

      <a class="theme-timeline__item__caption" [routerLink]="item.url">
        <time class="theme-timeline__item__caption__year" [attr.datetime]="item.year" *ngIf="item.year">{{ item.year }}</time>
        <h1 class="theme-timeline__item__caption__title">{{ item.title }}</h1>
        <p class="theme-timeline__item__caption__intro" *ngIf="item.text" [innerHTML]="item.text | stripHtml | truncate: 70"></p>
      </a>
    </article>
  </swiper>

  <nav class="theme-timeline__navigation" [title]="'theme-timeline-navigation-title' | fromDictionary">
    <ul class="scroll-nav type--horizontal">
      <li *ngFor="let item of items; let i = index" [class.is--active]="index > -1 && i === index">
        <button (click)="index = i" class="scroll-nav__item type--square">
          <span class="scroll-nav__item__title">
            <strong>{{ item.year }}</strong>
          </span>
        </button>
      </li>
    </ul>
  </nav>
</main>
