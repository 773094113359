import { ThemeOverviewComponent } from './containers/theme-overview/theme-overview.component';
import { ThemeDetailComponent } from './containers/theme-detail/theme-detail.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ThemeProjectResolve } from './services/theme-projects.resolve';
import { ThemesGuard } from './guards';
import { ThemeMetaGuard } from './guards/theme-meta.guard';
import { ThemeContentGuard } from './guards/theme-content.guard';
import { ThemeDetailGuard } from './guards/theme-detail.guard';

const routes: Routes = [
  {
    path: '',
    component: ThemeOverviewComponent,
    canActivate: [],
    pathMatch: 'full',
  },
  {
    path: ':id/:title',
    component: ThemeDetailComponent,
    canActivate: [ThemeDetailGuard, ThemeContentGuard, ThemeMetaGuard],
    resolve: {
      projects: ThemeProjectResolve,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ThemeRoutingModule {}
