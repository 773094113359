import { Component, Input, OnInit } from '@angular/core';
import { Brick } from '../../../bricks/models/brick.model';

@Component({
  selector: 'app-featured-article',
  templateUrl: './featured-article.component.html',
})
export class FeaturedArticleComponent implements OnInit {
  @Input() item: Brick;
  @Input() showEventDateCard: boolean = false;

  constructor() {}

  ngOnInit(): void {}
}
