import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromCookieSettings from '../reducers/cookie-settings.reducer';

export const getCookieSettingstate = createSelector(fromFeature.getCookiesState, (state: fromFeature.CookiesState) => state.settings);

export const getCookiesAcceptedRaw = createSelector(getCookieSettingstate, fromCookieSettings.getCookiesAccepted);
export const getCookieSettingsInitialized = createSelector(getCookieSettingstate, fromCookieSettings.getCookieSettingsInitialized);
export const getCookieSettingsInitializing = createSelector(getCookieSettingstate, fromCookieSettings.getCookieSettingsInitializing);
export const getCookieSettingsError = createSelector(getCookieSettingstate, fromCookieSettings.getCookieSettingsError);

export const getCookiePrefsSet = createSelector(getCookiesAcceptedRaw, (accepted: string) => {
  return accepted !== null;
});

export const getCookiesAccepted = createSelector(getCookiesAcceptedRaw, (accepted: string) => {
  return accepted === '1';
});

export const getCookiesRejected = createSelector(getCookiesAcceptedRaw, (accepted: string) => {
  return accepted === '0';
});
