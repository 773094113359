import { fadeInAnimation, fadeOutAnimation } from './../../fade.animation';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-loader',
  templateUrl: './loader.component.html',
  styleUrls: ['./loader.component.scss'],
  animations: [fadeInAnimation, fadeOutAnimation]
})
export class LoaderComponent implements OnInit {
  @Input() loading = false;
  @Input() dark = false;
  @Input() fixed = false;
  @Input() zIndex = 999;
  @Input() simple = false;
  initialized = false;

  constructor() { }

  ngOnInit() {
    this.initialized = true;
  }

}
