import { Injectable, EventEmitter, Inject } from '@angular/core';
import { DOCUMENT } from "@angular/common";
@Injectable()
export class ModalService {
  private activeModal: string;
  private modals: string[] = [];
  changed = new EventEmitter<string>();

  constructor(@Inject(DOCUMENT) private document) {
    this.changed.subscribe((id: string) => {
      this.document.querySelector('body').classList.toggle('modal--open', !!id);
    });
  }

  open(id: string) {
    if (!this.modals.includes(id)) {
      console.error('Open Failed: Modal doesn\'t exist');
    }

    this.activeModal = id;
    this.changed.emit(this.activeModal);
  }

  close() {
    this.activeModal = null;
    this.changed.emit(this.activeModal);
  }

  register(id: string) {
    if (this.modals.includes(id)) {
      console.error('Register Failed: Modal already exist');
    }

    this.modals = [...this.modals, id];
  }

  unregister(id: string) {
    if (!this.modals.includes(id)) {
      console.error('Unregister Failed: Modal doesn\'t exist');
    }

    this.modals = this.modals.filter(modal => modal !== id);
  }

  getModals() {
    return this.modals;
  }

  getActiveModal() {
    return this.activeModal;
  }

  isActive(id: string) {
    return this.activeModal === id;
  }
}
