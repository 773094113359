import { UpdatesResolve } from './services/updates.resolve';
import { ContactComponent } from './containers/contact/contact.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ContentOverviewComponent } from './containers/content-overview/content-overview.component';
import { ContentOverviewService } from './services/content-overview.service';
import { ContentDetailComponent } from './containers/content-detail/content-detail.component';
import { ContentType } from './enums/content-type.enum';
import { ContentDetailService } from './services/content-detail.service';
import { AwardsComponent } from './containers/awards/awards.component';
import { TeamComponent } from '../employee/components/team/team.component';
import { RecruiteeComponent } from './components/recruitee/recruitee.component';
import { PressroomComponent } from './components/pressroom/pressroom.component';
import { LegacyContentGuard } from './services/legacy-content.service';
import { CookieSettingsGuard } from '../cookie/guards/cookie-settings.guard';
import { ActiveLanguageGuard, LanguageGuard } from '../language/guards';
import { DictionaryGuard } from '../dictionary/guards/dictionary.guard';
import { EmployeeSummariesGuard } from '../employee/guards/employee-summaries.guard';

const routes: Routes = [
  {
    path: 'updates',
    component: ContentOverviewComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      contentResult: UpdatesResolve,
    },
    data: {
      contentType: ContentType.NEWS,
      title: 'updates',
      apiIdentifier: 'updates',
    },
  },
  {
    path: 'news',
    component: ContentOverviewComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      contentResult: ContentOverviewService,
    },
    data: {
      contentType: ContentType.NEWS,
      title: 'news',
      apiIdentifier: 'news',
    },
  },
  {
    path: 'events',
    component: ContentOverviewComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      contentResult: ContentOverviewService,
    },
    data: {
      contentType: ContentType.EVENTS,
      title: 'events',
      apiIdentifier: 'events',
    },
  },
  {
    path: 'stack-magazine',
    component: ContentOverviewComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      contentResult: ContentOverviewService,
    },
    data: {
      contentType: ContentType.MAGAZINE,
      title: 'stack-magazine',
      apiIdentifier: 'magazine',
    },
  },
  {
    path: 'mvrdv-tv',
    component: ContentOverviewComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      contentResult: ContentOverviewService,
    },
    data: {
      contentType: ContentType.LECTURES,
      title: 'mvrdv-tv',
      apiIdentifier: 'mvrdv-tv',
    },
  },
  {
    path: 'about',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.MENU,
      apiIdentifier: 'menu',
      pageId: 895,
      menu: true,
    },
  },
  {
    path: 'about/awards',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 965,
      menu: true,
    },
    children: [
      {
        path: '',
        component: AwardsComponent,
        outlet: 'default',
      },
    ],
  },
  {
    path: 'about/team',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard, EmployeeSummariesGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 902,
      menu: true,
    },
    children: [
      {
        path: '',
        component: TeamComponent,
        outlet: 'default',
      },
    ],
  },
  {
    path: 'about/clients',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 966,
      menu: true,
    },
  },
  {
    path: 'about/privacy',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 1258,
      menu: true,
    },
  },
  {
    path: 'about/mutual-code-of-conduct',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 4363,
      menu: true,
    },
  },
  {
    path: 'about/publications',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 6,
      menu: true,
    },
    children: [
      {
        path: '',
        component: ContentOverviewComponent,
        outlet: 'bottom',
        resolve: {
          contentResult: ContentOverviewService,
        },
        data: {
          contentType: ContentType.PUBLICATIONS,
          title: 'publications',
          apiIdentifier: 'publications',
          menu: true,
        },
      },
    ],
  },
  {
    path: 'about/publications',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
      pageId: 6,
      menu: true,
    },
    children: [
      {
        path: '',
        component: ContentOverviewComponent,
        outlet: 'bottom',
        resolve: {
          contentResult: ContentOverviewService,
        },
        data: {
          contentType: ContentType.PUBLICATIONS,
          title: 'publications',
          apiIdentifier: 'publications',
          menu: true,
        },
      },
    ],
  },
  {
    path: 'jobs',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'menu',
      pageId: 975,
    },
    children: [{ path: '', component: RecruiteeComponent, outlet: 'default' }],
  },
  {
    path: 'pressroom',
    component: PressroomComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'menu',
      pageId: 970,
      background: 'dark',
      menu: true,
    },
  },
  {
    path: 'news/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.NEWS,
      apiIdentifier: 'news',
    },
  },
  {
    path: 'stack-magazine/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.MAGAZINE,
      apiIdentifier: 'magazine',
    },
  },
  {
    path: 'mvrdv-tv/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.LECTURES,
      apiIdentifier: 'mvrdv-tv',
    },
  },
  {
    path: 'events/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.EVENTS,
      apiIdentifier: 'events',
    },
  },
  {
    path: 'publications/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PUBLICATIONS,
      apiIdentifier: 'publications',
    },
  },
  {
    path: 'publications/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PUBLICATIONS,
      apiIdentifier: 'publications',
    },
  },
  {
    path: 'pages/:id/:title',
    component: ContentDetailComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {
      content: ContentDetailService,
    },
    data: {
      contentType: ContentType.PAGE,
      apiIdentifier: 'pages',
    },
  },
  {
    path: 'contact',
    component: ContactComponent,
    canActivate: [CookieSettingsGuard, LanguageGuard, ActiveLanguageGuard, DictionaryGuard],
    resolve: {},
    data: {
      title: 'Contact',
    },
  },
  {
    path: ':language/news/:title',
    component: ContentDetailComponent,
    canActivate: [LegacyContentGuard, CookieSettingsGuard, LanguageGuard, DictionaryGuard],
    data: {
      type: 'news',
    },
  },
  {
    path: ':language/events/:title',
    component: ContentDetailComponent,
    canActivate: [LegacyContentGuard, CookieSettingsGuard, LanguageGuard, DictionaryGuard],
    data: {
      type: 'events',
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ContentRoutingModule {}
