import { Component, OnInit, ViewChild } from '@angular/core';
import { ModalService } from '../../../modal/modal.service';

@Component({
  selector: 'app-signup-newsletter',
  templateUrl: './signup-newsletter.component.html'
})
export class SignupNewsletterComponent implements OnInit {
  @ViewChild('fakeForm', { static: true }) fakeForm;
  form = {
    email: null,
    language: 116,
    step: 0
  };

  languages = [
    {
      title: 'English',
      abbr: 'EN',
      code: 116
    },
    {
      title: '中文',
      abbr: '中文',
      code: 115
    },
    {
      title: 'Dutch',
      abbr: 'NL',
      code: 11
    },
    {
      title: 'French',
      abbr: 'FR',
      code: 45
    }
  ];

  constructor(
    private modal: ModalService
  ) { }

  ngOnInit() {
  }

  submit() {
    this.modal.open('signup-newsletter-iframe');
    this.fakeForm.nativeElement.submit();
    this.reset();
  }

  reset() {
    this.form = {
      language: 116,
      email: null,
      step: 0
    };
  }

}
