import { EmployeeSummary } from './../interfaces/employee-summary.interface';
import { EmployeeDetail } from './../interfaces/empoyee-detail.interface';

import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, Router } from '@angular/router';
import { ProjectSummary } from '../../project/models/project-summary.interface';
import { Content } from '../../content/models/content.model';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { EmployeeSummaryDepartment } from '../interfaces/employee-summary-department';
import { environment } from '../../../environments/environment';

@Injectable()
export class EmployeeService implements Resolve<EmployeeDetail> {
  private baseApi = `${environment.apiUrl}/employees`;
  // private baseApi = 'http://windows:49748/employees';

  constructor(private http: HttpClient, private router: Router) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> {
    const id = +route.paramMap.get('id');
    return this.get(id).pipe(
      catchError(e => {
        this.router.navigate(['/about/team']);
        return of(null);
      })
    );
  }

  //
  all(): Observable<EmployeeSummary[]> {
    return this.http.get<EmployeeSummary[]>(`${this.baseApi}/summary`);
  }

  sortBy(sort = 'az'): Observable<EmployeeSummaryDepartment[]> {
    return this.http.get<EmployeeSummaryDepartment[]>(`${this.baseApi}/ordered?order=${sort}`);
  }

  //
  get(id: number): Observable<EmployeeDetail> {
    return this.http.get<EmployeeDetail>(`${this.baseApi}/get/${id}`);
  }

  //
  content(id: number): Observable<Content[]> {
    return this.http.get<Content[]>(`${this.baseApi}/get/${id}/content`);
  }
  projects(id: number): Observable<ProjectSummary[]> {
    return this.http.get<ProjectSummary[]>(`${this.baseApi}/get/${id}/projects/ordered`);
  }
}
