import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Renderer2,
  Output,
  HostListener,
} from '@angular/core';
import { ModalService } from './modal.service';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
})
export class ModalComponent implements OnInit, OnDestroy {
  @Input() id: string;
  @Output() close = new EventEmitter<boolean>();

  isActive = false;
  subscription;

  constructor(private service: ModalService, private renderer: Renderer2) {}

  ngOnInit() {
    this.service.register(this.id);

    this.subscription = this.service.changed.subscribe(
      (id: string) => (this.isActive = id && id === this.id)
    );
  }

  ngOnDestroy() {
    this.service.unregister(this.id);
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  closeModal() {
    this.close.emit(true);
    this.service.close();
  }

  @HostListener('document:keydown.esc') escape() {
    this.closeModal();
  }
}
