import { MainNavItem } from './interfaces/main-nav.interface';
import { MainSocialFollow } from './interfaces/main-social-follow.interface';
import { scrollama } from '../../vendors/scrollama/init';
import { Observable } from 'rxjs/Observable';
import { Injectable, PLATFORM_ID, Inject, NgZone, Optional } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import 'rxjs/add/operator/map';
import 'rxjs/add/operator/first';
import { isPlatformBrowser } from '@angular/common';
import { environment } from '../../environments/environment';
import { BehaviorSubject } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MainNavService {
  fill$ = new BehaviorSubject<'dark' | 'light'>(null);
  subLogo$ = new BehaviorSubject<string>(null);
  lama;

  constructor(
    @Inject(PLATFORM_ID) private platformId,
    private http: HttpClient,
    private zone: NgZone,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  resolve() {
    return this.get();
  }

  get(): Observable<MainNavItem[]> {
    return this.http.get<MainNavItem[]>(`${environment.apiUrl}/content/menu/summary`);
  }

  // getSocialLinks(): Observable<MainSocialFollow[]> {
  //   return this.http
  //     .get('/assets/api/data.json')
  //     .first()
  //     .map((data: any) => data.social_follow);
  // }

  setSubLogo(text: string) {
    this.subLogo$.next(text);
  }

  updateLama() {
    if (typeof window === 'undefined') return;

    this.destroyLama();

    this.zone.runOutsideAngular(() => {
      const onEnter = e => {
        let fill;

        if (e.element.classList.contains('background--dark')) {
          fill = 'light';
        } else if (e.element.classList.contains('background--light')) {
          fill = 'dark';
        }

        this.setFill(fill);
      };

      // console.log('lama:update');

      this.lama = scrollama();
      this.lama
        .setup({
          step: '.background--light, .background--dark',
          offset: 0.02,
        })
        .onStepEnter(onEnter);
    });
  }

  destroyLama() {
    if (typeof window === 'undefined') return;
    if (this.lama && typeof this.lama.destroy === 'function') {
      this.lama.destroy();
      // console.log('lama:destroy');
    }
  }

  setFill(fill: 'dark' | 'light' = 'dark') {
    // console.log('lama:fill', fill);
    this.fill$.next(fill);
  }

  getSocialLinks(): Observable<MainSocialFollow[]> {
    return this.http
      .get(`${this.BASE_URL}assets/api/data.json`)
      .first()
      .map((data: any) => data.social_follow);
  }
}
