<header class="content-detail__header background--{{ background }}">
  <div class="content-detail__header__container">
    <h1 class="content-detail__header__title text-center" *ngIf="title && !big">
      {{title}}
    </h1>
  </div>

  <nav *ngIf="items && items.length" class="content-detail__header__filters">
    <ul class="filter">
      <li *ngFor="let item of items" [routerLinkActive]="'is--active'">
        <a *ngIf="!href || item.routerLink" [routerLink]="item.routerLink">{{item.title}}</a>
        <a *ngIf="href" [href]="item.url">{{item.title}}</a>
      </li>
    </ul>
  </nav>
</header>
