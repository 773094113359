import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/language.action';
import * as utility from '../../../utility';
import { Language } from '../../models';

export interface LanguageState {
  entities: { [type: string]: Language };
  active: string;
  initialized: boolean;
  initializing: boolean;
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: LanguageState = {
  entities: {},
  active: 'en',
  initialized: false,
  initializing: false,
  loaded: false,
  loading: false,
};

export const LanguageReducer = createReducer(
  initialState,
  on(pageActions.LoadLanguagesSuccess, (state: LanguageState, { languages }) => {
    const entities = utility.ToEntities(languages, 'type', state.entities);

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadLanguagesFail, (state: LanguageState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.InitActiveLanguage, (state: LanguageState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(pageActions.InitActiveLanguageComplete, (state: LanguageState, { activeLang }) => {
    return {
      ...state,
      active: activeLang,
      initializing: false,
      initialized: true,
    };
  }),

  on(pageActions.SetActiveLanguageSuccess, (state: LanguageState, { activeLang }) => {
    return {
      ...state,
      active: activeLang,
    };
  })
);

export const getLanguagesEntities = (state: LanguageState) => state.entities;
export const getLanguageActive = (state: LanguageState) => state.active;
export const getLanguagesLoading = (state: LanguageState) => state.loading;
export const getLanguagesLoaded = (state: LanguageState) => state.loaded;

export const getLanguagesInitializing = (state: LanguageState) => state.initializing;
export const getLanguagesInitialized = (state: LanguageState) => state.initialized;
