import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as ProjectsActions from '../actions/project-socials.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { SocialService } from '../../../bricks/services/social.service';

@Injectable()
export class ProjectSocialsEffects {
  constructor(private actions$: Actions, private service: SocialService) {}

  LoadProjectSocials$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.LoadProjectSocials),
      switchMap(({ id }) =>
        this.service.byProject(id).pipe(
          map(Socials => ProjectsActions.LoadProjectSocialsSuccess({ Socials })),
          catchError(error => of(ProjectsActions.LoadProjectSocialsFail({ error })))
        )
      )
    );
  });
}
