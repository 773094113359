import { createReducer, on } from '@ngrx/store';

import * as actions from '../actions/project-events.action';
import * as utility from '../../../utility';
import { Event } from '../../../content/models/event.model';

export interface ProjectEventsState {
  entities: { [id: number]: Event };
  loading?: number;
  loaded?: number;
  error?: any;
}

export const initialState: ProjectEventsState = {
  entities: {},
};

export const ProjectEventsReducer = createReducer(
  initialState,
  on(actions.LoadProjectEvents, (state: ProjectEventsState, { id }) => {
    return {
      ...state,
      loading: id,
      loaded: null,
    };
  }),
  on(actions.LoadProjectEventsSuccess, (state: ProjectEventsState, { events }) => {
    const entities = utility.ToEntities(events, 'id', {});

    return {
      ...state,
      entities,
      loading: null,
      loaded: state.loading,
    };
  }),
  on(actions.LoadProjectEventsFail, (state: ProjectEventsState, { error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      error,
    };
  })
);

export const getProjectEventsEntities = (state: ProjectEventsState) => state.entities;
export const getProjectEventsLoading = (state: ProjectEventsState) => state.loading;
export const getProjectEventsLoaded = (state: ProjectEventsState) => state.loaded;
