import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { EmployeeDetailComponent } from './components/employee-detail/employee-detail.component';
import { EmployeeOverviewComponent } from './components/employee-overview/employee-overview.component';
import { EmployeeSummariesGuard } from './guards/employee-summaries.guard';
import { EmployeeService } from './services/employee.service';

const routes: Routes = [
  // {
  //   path: '',
  //   pathMatch: 'full',
  //   component: EmployeeOverviewComponent,
  //   canActivate: [EmployeeSummariesGuard],
  //   resolve: {
  //     employee: EmployeeService,
  //   },
  // },
  {
    path: '',
    pathMatch: 'full',
    component: EmployeeDetailComponent,
    resolve: {
      employee: EmployeeService,
    },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EmployeeRoutingModule {}
