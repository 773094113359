<h1 class="logo-mvrdv fill--{{ fill$ | async }}">
  <a routerLink="/" class="logo-mvrdv__link">
    <svg width="112" height="37" viewBox="0 0 112 37">
      <title>MVRDV</title>
      <g fill-rule="nonzero">
        <path
          d="M16.5,0L13,18.5L10,0H0v37h6V14.9L10.5,37h4l5-22.6V37H26V0H16.5z M41.7,0l-3.4,19.5L34.8,0H28l6.8,37h5.8L48,0H41.7z
            M105.7,0l-3.4,19.5L98.8,0H92l6.8,37h5.9L112,0H105.7z M65.4,20c2.7-1.4,4.1-4.5,4.1-9.2c0-6.9-3.2-10.4-9.7-10.8H50v37h6.7V21
          h2.6L62.3,37H70C70,37,65.4,20,65.4,20z M62.3,11.3c0,2.1-1,3.3-3.1,3.6h-2.6V5.6h2.6c2.1,0,3.1,1.2,3.1,3.6V11.3z M78.3,0H72v37
          h8.3c6.2,0,9.4-3.3,9.7-10V12.1C90,4,86.1,0,78.3,0z M84.2,26.2c0,3.4-1.5,5.1-4.4,5.1h-1.5V5.6h1c3.2,0,4.9,1.7,4.9,5.1
          C84.2,10.8,84.2,26.2,84.2,26.2z"
        />
      </g>
    </svg>
    <span class="logo-sub" *ngIf="subLogo" [@fadeAnimation]>{{ subLogo }}</span>
  </a>
</h1>

<button class="main-nav-toggle fill--{{ fill$ | async }}" [class.is--active]="menuActive" (click)="toggle()">
  <span>Toggle nav</span>
</button>

<!--
  <button
  class="main-nav-toggle main-nav__search-button-close fill--dark is--active"
  (click)="toggle()"
  *ngIf="searchActive && !menuActive">
  <span>Toggle search</span>
</button> -->

<nav class="main-nav" [class.is--active]="menuActive">
  <div class="main-nav__inner">
    <div class="main-nav__list">
      <ul>
        <li
          class="main-nav__list__item"
          [routerLinkActive]="'is--current'"
          [routerLinkActiveOptions]="{ exact: item.title == 'Home' }"
          *ngFor="let item of items"
        >
          <a *ngIf="!item.externalUrl" (click)="close()" [routerLink]="item.url">{{ item.title }}</a>
          <a *ngIf="item.externalUrl" (click)="close()" rel="noopener noreferrer" target="_blank" [href]="item.externalUrl">
            {{ item.title }}
          </a>
        </li>

        <li class="main-nav__list__item" [routerLinkActive]="'is--current'" [routerLinkActiveOptions]="{ exact: true }">
          <a [routerLink]="['/themes', 15, 'next']" (click)="gotoTheme(null, $event)">{{ 'nav-next' | fromDictionary }}</a>
        </li>
      </ul>
    </div>

    <ul class="main-nav__theme filter">
      <li *ngFor="let theme of filteredThemes" class="main-nav__theme__item" [class.is--active]="currentTheme === theme">
        <!-- <li *ngFor="let theme of themes" (click)="setTheme($event, theme)" [class.is--active]="currentTheme === theme"> -->

        <a
          [externalLink]="theme.externalUrl?.startsWith('http') ? theme.externalUrl : null"
          [routerLink]="theme.externalUrl ? [theme.externalUrl] : ['/themes', theme.id, theme.title | urlRewrite]"
          (click)="gotoTheme(theme, $event)"
        >
          {{ theme.title }}
        </a>
        <!-- '/themes', theme.id, theme.title | urlRewrite //-->
      </li>
    </ul>

    <!-- <ul class="main-nav__social-list">

        <li class="main-nav__social-list__item" *ngFor="let channel of follow">
          <a [href]="channel.url" class="button type--icon-only">
            <app-icon [anchor]="channel.icon"></app-icon>
          </a>
        </li>
      </ul> -->

    <app-language-selector [customLinks]="languages" (linkClicked)="close()"></app-language-selector>

    <button class="main-nav__search-button button type--icon-only" [routerLink]="'search'" (click)="toggle()">
      <app-icon [anchor]="'icon-search'"></app-icon>
    </button>
  </div>
</nav>
