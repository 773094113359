import { SafeResourceUrl } from '@angular/platform-browser';
import { SafePipe } from './../pipes/safe.pipe';
import { Directive, HostBinding, ElementRef, Input, OnChanges } from '@angular/core';

@Directive({
  selector: '[appBackgroundImage]'
})
export class BackgroundImageDirective implements OnChanges {
  @Input() appBackgroundImage = '';
  @HostBinding('style.background-image') bg: SafeResourceUrl;

  constructor(
    private el: ElementRef,
    private safePipe: SafePipe
  ) { }

  ngOnChanges() {
    this.bg = this.safePipe.transform(`url(${this.appBackgroundImage});`, 'style');
  }
}
