import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { map, tap, switchMap, catchError } from 'rxjs/operators';
import * as dictionaryActions from '../actions';
import { LanguageService } from '../../services/language.service';
import { of } from 'rxjs';
import { DictionaryEntry } from '../../models';

@Injectable()
export class DictionaryEffects {
  constructor(private actions$: Actions, private langService: LanguageService) {}

  loadDictionary$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(dictionaryActions.LoadDictionary),
      switchMap(() => this.langService.loadDictionary()),
      map(entries => dictionaryActions.LoadDictionarySuccess({ entries })),
      catchError(error => of(dictionaryActions.LoadDictionaryFail({ error })))
    );
  });

  loadDictionarySuccess$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(dictionaryActions.LoadDictionarySuccess),
        tap(({ entries }) => {
          const entities = entries.reduce((dictEntries: { [placeholder: string]: DictionaryEntry }, entry: DictionaryEntry) => {
            return {
              ...dictEntries,
              [entry.placeholder.toUpperCase()]: entry,
            };
          }, {});
          this.langService.dictionary = entities;
        })
      );
    },
    { dispatch: false }
  );
}
