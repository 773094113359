import { fadeAnimation } from './../core/fade.animation';
import {
  Component,
  Input,
  HostListener,
  Inject,
  Renderer2,
  ChangeDetectionStrategy,
  OnDestroy,
  AfterViewInit,
  ChangeDetectorRef,
  OnInit,
} from '@angular/core';

import { MainNavService } from './main-nav.service';
import { MainNavItem } from './interfaces/main-nav.interface';
import { MainSocialFollow } from './interfaces/main-social-follow.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { Location, DOCUMENT } from '@angular/common';
import { ThemeService } from '../theme/services/theme.service';
import { ThemeSummary } from '../theme/models/theme-summary.model';
import { UrlRewritePipe } from '../core/pipes/url-rewrite.pipe';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-main-nav',
  templateUrl: 'main-nav.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [fadeAnimation],
})
export class MainNavComponent implements AfterViewInit, OnInit, OnDestroy {
  @Input() items: MainNavItem[];
  @Input() follow: MainSocialFollow[];
  @Input() themes: ThemeSummary[];
  currentTheme;
  // fill = 'dark';
  fill$ = this.nav.fill$;
  subLogo: string;
  menuActive = false;
  searchActive = false;
  private _unsubscribe$ = new Subject<void>();

  languages = [
    {
      lang: 'DE',
      url: 'https://mvrdv.com/themes/11/germany',
    },
    {
      lang: 'NL',
      url: 'https://mvrdv.com/themes/12/netherlands',
    },
    {
      lang: 'FR',
      url: 'https://mvrdv.com/themes/13/french',
    },
  ];

  get filteredThemes() {
    return this.themes && this.themes.filter(a => a.id !== 15);
  }

  constructor(
    @Inject(DOCUMENT) private document,
    private renderer: Renderer2,
    private nav: MainNavService,
    private router: Router,
    private route: ActivatedRoute,
    private location: Location,
    private themeService: ThemeService,
    private urlPipe: UrlRewritePipe,
    private cd: ChangeDetectorRef
  ) {
    router.events.subscribe(() => {
      this.searchActive = location.path() === '/search';
    });
  }

  ngOnInit() {
    this.nav.subLogo$.pipe(takeUntil(this._unsubscribe$)).subscribe(l => {
      this.subLogo = l;
    });
  }

  ngAfterViewInit() {
    this.nav.updateLama();

    // Needed for change detection. ivm push strategy

    this.nav.fill$.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      // this.fill = a;
      this.cd.detectChanges();
    });
  }

  ngOnDestroy() {
    this.nav.destroyLama();
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  @HostListener('document:keydown.esc')
  close() {
    this.menuActive = false;
    this.updateRoot();
  }

  toggle() {
    this.menuActive = !this.menuActive;
    this.updateRoot();
  }

  updateRoot() {
    this.menuActive
      ? this.renderer.addClass(this.document.body, 'main-nav--active')
      : this.renderer.removeClass(this.document.body, 'main-nav--active');
    this.cd.detectChanges();
  }

  gotoTheme(r, e) {
    e.preventDefault();
    this.close();
  }
}
