import { Photo } from './../../models/photo.interface';
import { Router, ActivatedRoute } from '@angular/router';
import { Component, OnInit, Input, AfterViewInit, ViewChild, OnChanges, OnDestroy } from '@angular/core';
import { HostListener } from '@angular/core';
import { ModalService } from './../../../modal/modal.service';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { Utility } from '../../../core/classes/utility';
import { Observable, Subject } from 'rxjs';
import { map, take, takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-photo-wall',
  templateUrl: './photo-wall.component.html',
})
export class PhotoWallComponent extends Utility implements AfterViewInit, OnChanges, OnDestroy {
  @ViewChild('swiper', { static: true }) swiper;
  @Input() title: string;
  @Input() photos: Photo[] = [];
  @Input() showThumbs: boolean;

  activeIndex$: Observable<number>;
  active$: Observable<Photo>;
  prev$: Observable<Photo>;
  next$: Observable<Photo>;

  imageLoaded = false;
  swiperConfig: SwiperConfigInterface = {
    slidesPerView: 1.5,
    keyboard: true,
    centeredSlides: true,
    effect: 'slide',
    loop: false,
    spaceBetween: 50,
  };
  moving = false;
  private _unsubscribe$ = new Subject<void>();

  constructor(private router: Router, private route: ActivatedRoute, private modals: ModalService) {
    super();
  }

  ngAfterViewInit() {
    this.route.queryParams.pipe(takeUntil(this._unsubscribe$)).subscribe(this.updateModal.bind(this));
    this.activeIndex$ = this.route.queryParams.pipe(map(a => this.photos.findIndex(p => p.id === +a['photo']) ?? null));
    this.active$ = this.prev$ = this.activeIndex$.pipe(map(a => this.photos[a]));
    this.next$ = this.activeIndex$.pipe(map(a => this.photos[a + 1]));
    this.prev$ = this.activeIndex$.pipe(map(a => this.photos[a - 1]));
  }

  ngOnChanges(changes) {}

  updateModal(params) {
    const id = params['photo'];

    if (!id && this.modals.isActive('gallery-modal')) {
      this.modals.close();
      return;
    }

    if (id && !!this.photos) {
      if (id) {
        this.imageLoaded = false;
        this.modals.open('gallery-modal');
      }
    }
  }

  syncParams(i) {
    if (this.photos.length) {
      this.open(this.photos[i]);
    }
  }

  open(item: Photo) {
    if (item && item.id) {
      setTimeout(() => {
        this.router.navigate([], {
          queryParams: { photo: item.id },
        });
      });
    }
  }

  close() {
    this.router.navigate([], { queryParams: {} });
  }

  load() {
    this.imageLoaded = true;
  }

  trackByFn(index, item) {
    return item.id;
  }

  @HostListener('document:keydown.arrowLeft') async left() {
    if (!this.modals.isActive('gallery-modal')) return;
    var prev = await this.prev$.pipe(take(1)).toPromise();
    this.open(prev);
  }

  @HostListener('document:keydown.arrowRight') async right() {
    if (!this.modals.isActive('gallery-modal')) return;
    var next = await this.next$.pipe(take(1)).toPromise();
    this.open(next);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
