import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as employeesActions from '../actions/employee-summaries.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { EmployeeService } from '../../services/employee.service';


@Injectable()
export class EmployeesEffects {
  constructor(private actions$: Actions, private employeesService: EmployeeService) {}

  LoadEmployeeSummaries$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(employeesActions.LoadEmployeeSummaries),
      switchMap(() =>
        this.employeesService.all().pipe(
          map(employees => employeesActions.LoadEmployeeSummariesSuccess({ summaries: employees })),
          catchError(error => of(employeesActions.LoadEmployeeSummariesFail({ error })))
        )
      )
    );
  });
}
