import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'superScript'
})
export class SuperScriptPipe implements PipeTransform {

  transform(value: string) {
      if (value.includes('m2')) {
        const unit = value.match('m2');
        return value.replace(unit[0], `<span class="cubic-meter">m<sup>${value[unit.index + 1]}</sup></span>`);
      } else {
        return value;
      }
  }

}
