import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getHash'
})
export class GetHashPipe implements PipeTransform {

  transform(value: string): string {
    const hashes = value.split(' ').filter(v => v.startsWith('#'));
    return hashes.join(' ');
  }
}
