import * as fromPages from './menu-items.reducer';
import * as fromSocials from './social-links.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'main-nav';

export interface MainNavState {
  menuItems: fromPages.MenuItemsState;
  socialLinks: fromSocials.SocialLinksState;
}

export const reducers: ActionReducerMap<MainNavState> = {
  menuItems: fromPages.MenuItemsReducer,
  socialLinks: fromSocials.SocialLinksReducer,
};

export const getMainNavState = createFeatureSelector<MainNavState>(featureKey);
export const metaReducers: MetaReducer<MainNavState>[] = !environment.production ? [] : [];
