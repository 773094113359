import { createSelector } from '@ngrx/store';

import * as fromRoot from '../../store';
import * as fromFeature from '../reducers';
import * as fromDictionary from '../reducers/dictionary.reducer';

export const getAllDictionaryState = createSelector(fromFeature.getDictionaryState, (state: fromFeature.DictionaryState) => state.entries);

export const getDictionaryEntities = createSelector(getAllDictionaryState, fromDictionary.getDictionaryEntities);
export const getDictionaryLoaded = createSelector(getAllDictionaryState, fromDictionary.getDictionaryLoaded);
export const getDictionaryLoading = createSelector(getAllDictionaryState, fromDictionary.getDictionaryLoading);
