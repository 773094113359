import { trigger, animate, transition, style, query, stagger, keyframes } from '@angular/animations';

export const fadeRouteAnimation = trigger('fadeRouteAnimation', [
  transition('* <=> *', [
    query(':enter', [style({ opacity: 0 })], { optional: true }),

    // query(
    //   ':leave',
    //   [style({ opacity: 1 }), animate('.2s', style({ opacity: 0 }))],
    //   { optional: true }
    // ),

    query(':enter', [style({ opacity: 0 }), animate('.3s', style({ opacity: 1 }))], { optional: true }),
  ]),
]);

export const fadeAnimation = trigger('fadeAnimation', [
  transition(':enter', [style({ opacity: '0' }), animate('.7s ease-out', style({ opacity: '1' }))]),
  transition(':leave', [style({ opacity: '0' }), animate('.7s ease-out', style({ opacity: '0' }))]),
]);

export const fadeInAnimation = trigger('fadeInAnimation', [
  transition(':enter', [style({ opacity: '0' }), animate('.4s ease-out', style({ opacity: '1' }))]),
]);

export const fadeOutAnimation = trigger('fadeOutAnimation', [
  transition(':leave', [style({ opacity: '1' }), animate('.4s ease-out', style({ opacity: '0' }))]),
]);

export const staggerOpacityAnimation = trigger('staggerOpacityAnimation', [
  transition('* => *', [
    query('li', style({ opacity: 0 }), { optional: true }),

    query('li.is--loaded', stagger('250ms', [animate('1s ease-in', keyframes([style({ opacity: 1 })]))]), { optional: true }),
  ]),
]);
