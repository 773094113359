<section class="brick-wall background--light">
  <header class="brick-wall__header" *ngIf="title">
    <h1 class="brick-wall__header__title">{{ title | uppercase }}</h1>
  </header>

  <nav class="brick-wall__filter" *ngIf="filters">
    <ul class="filter">
      <li [routerLinkActive]="'is--active'" *ngFor="let item of filters">
        <a [routerLink]="item.url">{{ item.title }}</a>
      </li>
    </ul>
    <a
      class="button button--type--outline button--type--outline-black button--type--with-icon type--uppercase newletter-button button--type--s"
      href="https://81637670.sibforms.com/serve/MUIEAICkHmvc2jZRtdNwsRhNCBr4vl2t47imN6GMldddnMG11WpKHLcCHixunUoXbctIWdyAUsC2lr9MYPsZ-yQFj0dQdsC1hpixlrNf5RQtChXzFZiJTN5fD93Jla_SctVsP2_Y5xlsfz-nyH9EobWZ46AkInoJ4BODc9a3Im5c_EmP9TXE0gqkz994Bl6Aa0f_oPAMjRR01EGq"
      target="_blank"
      rel="noopener"
    >
      <app-icon [anchor]="'icon-newsletter'"></app-icon>
      <span>{{ 'newsletter-button-text' | fromDictionary }}</span>
    </a>
  </nav>

  <div class="brick-wall__container" grid>
    <div grid-sizer></div>

    <ng-container *ngFor="let item of items | slice : 0 : showTotalUpdates; trackBy: trackByFn" [ngSwitch]="item.Type">
      <app-post-social grid-item *ngSwitchDefault [item]="item" (load)="update()"></app-post-social>

      <app-post-news grid-item *ngSwitchCase="'article'" (load)="update()" [item]="item"></app-post-news>

      <app-post-content grid-item *ngSwitchCase="'content'" (load)="update()" [item]="item"></app-post-content>
    </ng-container>
  </div>
</section>
