import { Observable } from 'rxjs/Observable';
import { Project } from './../../../project/models/project.interface';
import { UrlRewritePipe } from './../../../core/pipes/url-rewrite.pipe';
import { Theme } from './../../models/theme.interface';
import { ActivatedRoute } from '@angular/router';
import { Component, OnInit, OnDestroy } from '@angular/core';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { PostNavItem } from '../../../post/interfaces/post-nav.interface';
import { TimelineItem } from '../../models/timeline-item.interface';
import { MediaBlock } from '../../models/media-block.model';
import { Brick } from '../../../bricks/models/brick.model';
import { Content } from '../../../content/models/content.model';
import * as _ from 'lodash';
import { Event } from '../../../content/models/event.model';
import { ThemeContentResolve } from '../../services/theme-content.resolve';
import { map, takeUntil, tap, withLatestFrom } from 'rxjs/operators';
import { Subject, of } from 'rxjs';

import * as themesSelectors from '../../store/selectors';
import { Store } from '@ngrx/store';
import { getSelectedTheme, getThemeWallContent } from '../../store/selectors';

@Component({
  selector: 'app-theme-detail',
  templateUrl: './theme-detail.component.html',
})
export class ThemeDetailComponent implements OnInit, OnDestroy {
  themes$: Observable<PostNavItem[]>;
  theme$: Observable<Theme>;
  content$: Observable<Brick[]>;
  projects$: Observable<Project[]>;
  nextProjects$: Observable<Project[]>;

  showTotalProjects = 24;
  showTotalProjects$: Observable<number> = of(24);
  showTotalUpdates = 8;
  showUpdates = this.showTotalUpdates;
  aggregations: any;
  inview = false;
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private nav: MainNavService,
    private urlPipe: UrlRewritePipe,
    private contentService: ThemeContentResolve,
    private store: Store
  ) {}

  ngOnInit() {
    this.nav.setFill('light');
    this.theme$ = this.store.select(getSelectedTheme);
    this.content$ = this.store.select(getThemeWallContent).pipe(map(a => a.map(b => this.contentToBrick(b))));

    this.projects$ = this.route.data.pipe(map(data => data.projects.projects?.filter(p => !p.IsNEXTProject)));
    this.nextProjects$ = this.route.data.pipe(map(data => data.projects.projects?.filter(p => p.IsNEXTProject)));

    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
      this.aggregations = data.projects.aggregations;
    });

    // initialize themes nav (bottom of page)
    this.themes$ = this.store.select(themesSelectors.getOrderedThemes).pipe(
      map(themes =>
        themes.map(t => ({
          title: t.title,
          url: ['/themes', t.id, this.urlPipe.transform(t.title)],
        }))
      )
    );
  }

  updateNav() {
    this.nav.updateLama();
  }

  ngOnDestroy() {
    // reset subLogo
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
    this.nav.setSubLogo(null);
  }

  contentToBrick(item: Content): Brick {
    return {
      Id: item.id,
      Title: item.title,
      Description: item.text,
      Type: 'article',
      ImageUrl: item.image,
      Url: item.url,
      Favicon: null,
      SiteName: null,
      DateAdded: (<Event>item).eventDate || item.publishDate,
      Author: null,
      ProjectId: null,
      HostName: null,
    };
  }
}
