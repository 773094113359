<nav class="language-selector">
  <!-- <pre>{{activeLanguage$ | async}}</pre> -->
  <!-- <pre>{{customLinks | json}}</pre> -->
  <!-- <pre>{{ anyLinkActive$ | async | json }}</pre> -->
  <ul class="language-selector__list mb-0">
    <li
      *ngFor="let item of languages$ | async"
      class="language-selector__list__item"
      [class.is--active]="(activeLanguage$ | async) === item.type && !(anyLinkActive$ | async)"
    >
      <a [href]="item.domain" class="button button-icon language-selector__button" [title]="item.type">
        {{ item?.name?.toUpperCase() }}
      </a>
    </li>

    <li
      *ngFor="let link of customLinks"
      class="language-selector__list__item"
      [routerLinkActive]="'is--active'"
      [routerLinkActiveOptions]="{ exact: true }"
    >
      <a
        (click)="linkClicked.emit()"
        [href]="link.url"
        [class.is--active]="(link.url | lowercase).endsWith(activeRoute$ | async)"
        class="button button-icon language-selector__button"
      >
        {{ link?.lang }}
      </a>
    </li>
  </ul>
</nav>
