import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/project-socials.action';
import * as utility from '../../../utility';
import { Brick } from '../../../bricks/models/brick.model';

export interface ProjectSocialsState {
  entities: { [id: number]: Brick };
  loading?: number;
  loaded?: number;
  error?: any;
}

export const initialState: ProjectSocialsState = {
  entities: {},
};

export const ProjectSocialsReducer = createReducer(
  initialState,
  on(pageActions.LoadProjectSocials, (state: ProjectSocialsState, { id }) => {
    return {
      ...state,
      loading: id,
      loaded: null,
    };
  }),
  on(pageActions.LoadProjectSocialsSuccess, (state: ProjectSocialsState, { Socials }) => {
    const entities = utility.ToEntities(Socials, 'Id', {});

    return {
      ...state,
      entities,
      loading: null,
      loaded: state.loading,
    };
  }),
  on(pageActions.LoadProjectSocialsFail, (state: ProjectSocialsState, { error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      error,
    };
  })
);

export const getProjectSocialsEntities = (state: ProjectSocialsState) => state.entities;
export const getProjectSocialsLoading = (state: ProjectSocialsState) => state.loading;
export const getProjectSocialsLoaded = (state: ProjectSocialsState) => state.loaded;
