<article
  class="post-thumb"
  [class.is--loaded]="isLoaded"
  [class.type--padding]="padding"
  [class.type--small]="small"
  [class.type--dark]="dark"
  (appInViewport)="inview = $event"
>
  <a *ngIf="!href" class="post-thumb__caption" [routerLink]="url" [queryParams]="urlParams">
    <h1 class="post-thumb__caption__title" *ngIf="title">{{ title }}</h1>
    <span class="post-thumb__caption__text" *ngIf="text">{{ text }}</span>
  </a>
  <a *ngIf="href" class="post-thumb__caption" [href]="url">
    <h1 class="post-thumb__caption__title" *ngIf="title">{{ title }}</h1>
    <span class="post-thumb__caption__text" *ngIf="text">{{ text }}</span>
  </a>

  <figure class="post-thumb__image">
    <app-image
      *ngIf="inview"
      [src]="image | http2Https"
      loading="lazy"
      (loaded)="load.emit($event); isLoaded = true"
      [alt]="title"
      [width]="imgWidth"
      [height]="imgHeight"
      [mode]="imgMode"
      [sizes]="{ sm: 4 }"
      [imageSizes]="[300, 500, 640]"
    ></app-image>
  </figure>
</article>
