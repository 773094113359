<article class="portfolio">
  <header class="portfolio__intro background--dark">
    <h1 class="portfolio__intro__title">{{ 'portfolio-title' | fromDictionary }}</h1>
  </header>

  <aside class="portfolio__chapters background--light">
    <ol class="portfolio__chapters__list">
      <li class="portfolio__chapter" *ngFor="let program of programs">
        <figure class="portfolio__chapter__crop">
          <img loading="lazy" [src]="([program.Projects[0]] | stringJoin: 'ImageUrl') + '?width=400&height=400&mode=crop'" />
        </figure>
        <a class="portfolio__chapter__wrapper" (click)="scrollToId($event)" [attr.data-id]="program?.Name | urlRewrite">
          <strong class="portfolio__chapter__title">{{ program.Name }}</strong>
          <div class="portfolio__chapter__projects">{{ program.Projects | stringJoin: 'Title' }}</div>
        </a>
      </li>
    </ol>
  </aside>

  <main class="portfolio__projects">
    <section *ngFor="let program of programs">
      <!-- Chapter //-->
      <div class="portfolio__projects__chapter background--dark" [id]="program?.Name | urlRewrite">
        <div class="portfolio__projects__chapter__wrapper">
          <h1 class="portfolio__projects__chapter__title">
            <span class="rotate-title">{{ program.Name }}</span>
          </h1>
          <img
            loading="lazy"
            class="portfolio__projects__chapter__image"
            [src]="([program.Projects[0]] | stringJoin: 'ImageUrl') + '?width=1980&height=1398&mode=crop'"
          />
        </div>
      </div>
      <!-- Projects in chapter //-->
      <ul class="portfolio__projects__list background--dark">
        <li class="portfolio__projects__list__item" *ngFor="let project of program.Projects">
          <article class="portfolio__project">
            <figure class="portfolio__project__crop">
              <img loading="lazy" [src]="project?.ImageUrl + '?width=1980&height=1398&mode=crop'" />
            </figure>
            <div class="portfolio__project__content">
              <div class="portfolio__project__content__wrapper">
                <h1 class="portfolio__project__content__title">{{ project?.Title }}</h1>
                <div
                  class="portfolio__project__content__intro"
                  *ngIf="project?.Summary"
                  [innerHTML]="project?.Summary | stripHtml | safe: 'html'"
                ></div>
              </div>
              <ul class="portfolio__project__content__meta">
                <li *ngIf="project.Country">
                  <strong>{{ 'portfolio-location' | fromDictionary }}</strong>
                  {{ project.Country }}
                </li>
                <li *ngIf="project.Year && project.Year > 0">
                  <strong>{{ 'portfolio-year' | fromDictionary }}</strong>
                  {{ project.Year }}
                </li>
                <li *ngIf="project.Budget">
                  <strong>{{ 'portfolio-budget' | fromDictionary }}</strong>
                  {{ !project.Budget ? 'Undisclosed' : project.Budget }}
                </li>
                <li *ngIf="project.ClientName && project.ClientName > 0">
                  <strong>{{ 'portfolio-client' | fromDictionary }}</strong>
                  {{ !project.ClientName ? 'Undisclosed' : project.ClientName }}
                </li>
                <li *ngIf="project.Size != 0 || project.Size">
                  <strong>{{ 'portfolio-size' | fromDictionary }}</strong>
                  {{ project.Size }}
                </li>
              </ul>
            </div>
          </article>
        </li>
      </ul>
    </section>
  </main>
</article>
