import { MomentModule } from 'angular2-moment';
import { PostNavComponent } from './components/post-nav/post-nav.component';
import { RouterModule } from '@angular/router';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { PostTeaserBannerComponent } from './components/post-teaser-banner/post-teaser-banner.component';
import { PostTeaserComponent } from './components/post-teaser/post-teaser.component';
import { CoreModule } from '../core/core.module';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { PostHeaderComponent } from './components/post-header/post-header.component';
import { UrlRewritePipe } from '../core/pipes/url-rewrite.pipe';
import { PostThumbComponent } from './components/post-thumb/post-thumb.component';
import { PostSocialComponent } from './components/post-social/post-social.component';
import { PostContentComponent } from './components/post-content/post-content.component';
import { PostNewsComponent } from './components/post-news/post-news.component';
import { LinkyModule } from 'angular-linky';
import { StripInstagramCrapPipe } from './pipes/strip-instagram-crap.pipe';
import { StripDomainPipe } from './pipes/strip-domain.pipe';
import { StripTwitterCrapPipe } from './pipes/strip-twitter-crap.pipe';
import { ThemeTeaserComponent } from './components/theme-teaser/theme-teaser.component';
import { PostListComponent } from './components/post-list/post-list.component';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    LazyLoadImageModule,
    RouterModule,
    MomentModule,
    LinkyModule,
    DictionaryNgrxModule
  ],
  declarations: [
    PostTeaserComponent,
    PostTeaserBannerComponent,
    PostHeaderComponent,
    PostNavComponent,
    PostThumbComponent,
    PostContentComponent,
    PostSocialComponent,
    PostNewsComponent,
    StripInstagramCrapPipe,
    StripDomainPipe,
    StripTwitterCrapPipe,
    ThemeTeaserComponent,
    PostListComponent,
  ],
  exports: [
    PostTeaserBannerComponent,
    PostTeaserComponent,
    PostHeaderComponent,
    PostNavComponent,
    PostThumbComponent,
    PostSocialComponent,
    PostNewsComponent,
    PostContentComponent,
    ThemeTeaserComponent,
    PostListComponent,
  ],
  providers: [
    UrlRewritePipe
  ]
})
export class PostModule { }
