import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/theme-content.action';
import * as utility from '../../../utility';
import { Content } from '../../../content/models/content.model';

export interface ThemeContentState {
  entities: { [id: number]: Content };
  loading?: number;
  loaded?: number;
  error?: any;
}

export const initialState: ThemeContentState = {
  entities: {},
};

export const ThemeContentReducer = createReducer(
  initialState,
  on(pageActions.LoadThemeContent, (state: ThemeContentState, { id }) => {
    return {
      ...state,
      loading: id,
      loaded: null,
    };
  }),
  on(pageActions.LoadThemeContentSuccess, (state: ThemeContentState, { content }) => {
    const entities = utility.ToEntities(content, 'id', {});

    return {
      ...state,
      entities,
      loading: null,
      loaded: state.loading,
    };
  }),
  on(pageActions.LoadThemeContentFail, (state: ThemeContentState, { error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      error,
    };
  })
);

export const getThemeContentEntities = (state: ThemeContentState) => state.entities;
export const getThemeContentLoading = (state: ThemeContentState) => state.loading;
export const getThemeContentLoaded = (state: ThemeContentState) => state.loaded;
