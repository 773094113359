import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Award } from '../../../content/models/award.model';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
})
export class AwardsComponent implements OnInit {
  @Input() awards: Award[];
  @ViewChild('awardsContainer') awardsContainer: ElementRef;

  showAll = false;

  ngOnInit(): void {
    if (this.awards.length < 5) {
      this.showAll = true;
    }
  }

  get noImages() {
    return this.awards.filter(a => a.image).length === 0;
  }


  lessAwards() {
    this.showAll = false;

    setTimeout(() => {
      // this.newsContainer.nativeElement.scrollTop = this.newsContainer.nativeElement.scrollHeight;
      this.awardsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }
}
