export const constants = {
  numberOfProjects: 128,
  mapsApiKey: 'AIzaSyBycRvcTf9iYh198SXix1BEwAHXb4WwwOs',
  mapsDefaults: {
    zoom: 3,
    type: 'satellite',
    center: {
      latitude: 37.269557,
      longitude: 50.684597,
      isOpen: false,
    },
    scrollwheel: false,
    zoomControl: false,
    disableDoubleClickZoom: true,
    disableDefaultUI: true,
    mapTypeControl: false,
    streetViewControl: false,
    minZoom: 2,
    iconUrl: 'assets/gfx/map-marker.svg',
    clusterStyles: <any[]>[
      {
        url: 'assets/gfx/map-marker-cluster.svg',
        width: 26.4,
        height: 40.8,
        textColor: 'black',
        backgroundPosition: '0 -2px',
      },
    ],
    infoWindow: {
      closeWhenOthersOpen: true,
      placement: 'top',
      maxWidth: 300,
      maxHeight: 330,
      border: false,
      borderRadius: false,
      shadow: false,
      showCloseButton: true,
      isOpen: false,
    },
  },
};
