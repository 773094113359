import { ThemeService } from './../../../theme/services/theme.service';
import { UrlRewritePipe } from './../../../core/pipes/url-rewrite.pipe';
import { PostNavItem } from './../../../post/interfaces/post-nav.interface';
import { Utility } from './../../../core/classes/utility';
import { isPlatformBrowser } from '@angular/common';
import { ProjectService } from './../../services/project.service';
import { ActivatedRoute, Router } from '@angular/router';
import { MainNavService } from './../../../main-nav/main-nav.service';
import { FilterState } from './../../models/filter-state.model';
import { ProjectLocation } from './../../models/project-location.model';
import { Observable } from 'rxjs/Observable';
import { ProjectView } from './../../enums/project-view.enum';
import { fadeAnimation } from './../../../core/fade.animation';
import { Component, OnInit, AfterViewInit, Inject, PLATFORM_ID, OnDestroy } from '@angular/core';
import { constants } from '../../../core/constants';
import { debounceTime, map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Title, Meta } from '@angular/platform-browser';
import { FromDictionaryPipe } from '../../../dictionary/pipes/from-dictionary.pipe';
import { getOrderedThemes } from '../../../theme/store';
import { Store } from '@ngrx/store';
import { Project } from '../../models/project.interface';

@Component({
  selector: 'app-project-overview',
  templateUrl: './project-overview.component.html',
  animations: [fadeAnimation],
})
export class ProjectOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  maxProjects = 40;
  paging = 0;
  projectView = ProjectView;
  q = [];
  aggregations = {};
  locations$: Observable<ProjectLocation[]>;

  mapsOptions = constants.mapsDefaults;
  map;
  loaded = false;
  themes$: Observable<PostNavItem[]>;
  results = 0;
  initialProjects = [];
  projects = [];
  view: any = this.projectView.GRID;
  filtersOpen = false;
  filterState: FilterState = {
    q: '',
    size: {
      range: [0, 370000],
      value: [0, 370000],
    },
    years: {
      range: [1993, new Date().getFullYear()],
      value: [1993, new Date().getFullYear()],
    },
    checkboxes: [],
  };

  private _unsubscribe$ = new Subject<void>();

  get isBrowser() {
    return isPlatformBrowser(this.platformId);
  }

  constructor(
    private service: ProjectService,
    private route: ActivatedRoute,
    private nav: MainNavService,
    private router: Router,
    private urlPipe: UrlRewritePipe,
    private themeService: ThemeService,
    private metaTitle: Title,
    private meta: Meta,
    @Inject(PLATFORM_ID) private platformId,
    private dict: FromDictionaryPipe,
    private store: Store
  ) {}

  ngOnInit() {
    // this.locations$ = this.service.getLocations();
    this.locations$ = this.service.getLocations().pipe(
      map(locations => locations.filter(location => location.latitude && location.longitude))
    );

    if (this.route.snapshot.data.type === 'map' || this.route.snapshot.data.type === 'list') {
      this.setView(this.route.snapshot.data.type);
    } else {
    }
    // this.themes$ = this.themeService
    //   .all()
    //   .first()
    //   .map(themes =>
    //     themes.map(t => ({
    //       title: t.title,
    //       url: ['/themes', t.id, this.urlPipe.transform(t.title)],
    //     }))
    //   );
    this.themes$ = this.store.select(getOrderedThemes);

    this.metaTitle.setTitle(`MVRDV - ${this.dict.transform('projects-title')}`);
    this.meta.addTags([
      { property: 'og:title', content: `MVRDV - ${this.dict.transform('projects-title')}` },
      {
        property: 'og:description',
        content: Utility.concatString(this.dict.transform('project-overview-meta-description'), 160),
      },
      {
        name: 'description',
        content: Utility.concatString(this.dict.transform('project-overview-meta-description'), 160),
      },
    ]);
    // Get first all Aggregations
    this.getAllAggregations();
  }

  getParam() {
    this.route.queryParams
      .pipe(debounceTime(400))
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(params => {
        this.loaded = false;
        this.paging = params['page'] ? parseFloat(params['page']) : 0;
        this.service.paramsToFilterState(this.filterState, params);
        this.q = this.service.filterStateToElasticQuery(this.filterState);
        this.loadProjects();
      });
  }

  ngAfterViewInit() {
    this.nav.updateLama();
    this.nav.setFill('dark');
    this.resetZoom();
  }

  getAllAggregations() {
    this.service
      .find(0, 0, [])
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(projects => {
        this.aggregations = projects.aggregations;

        this.filterState = this.service.aggregationsToFilterState(this.filterState, projects.aggregations);

        // get all url params
        this.getParam();
      });
  }

  loadProjects() {
    this.service
      .find(this.maxProjects, this.maxProjects * this.paging, this.q)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(projects => {
        this.initialProjects = projects.hits.map(hit => hit.source);
        this.projects = this.initialProjects;
        this.results = projects.hitcount;
        this.loaded = true;
      });
  }

  setView(view: ProjectView) {
    this.view = view;
    this.loaded = false; // set loaded
    this.projects = []; // reset projects

    if (this.view === 'list' || this.view === 'map') {
      this.maxProjects = 10000;
      this.paging = 0;
    } else {
      this.maxProjects = 80;
      this.paging = 0;
    }

    // Check ff browser
    if (isPlatformBrowser(this.platformId)) {
      if (this.view === this.projectView.MAP && Utility.getBreakpoint() === 'breakpoint-mobile') {
        this.mapsOptions.zoom = 2;
      }
    }

    this.loadProjects(); // get projects
  }

  markerOpened(markerObj) {
    this.map.panTo({
      lat: markerObj.latitude,
      lng: markerObj.longitude,
    });
    setTimeout(this.map.panBy(0, -100), 10);
  }

  recenter(timeout = 0) {
    setTimeout(() => {
      if (this.map) {
        this.map.setCenter({
          lat: this.mapsOptions.center.latitude,
          lng: this.mapsOptions.center.longitude,
        });
      }
    }, timeout);
  }

  resetZoom() {
    if (this.map) {
      this.map.setZoom(this.mapsOptions.zoom);
      this.recenter();
    }
  }

  getMap(map) {
    this.map = map;
  }

  hasProgram35(p: Project) {
    return p.Programs?.some(a => a.Id === 35);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
