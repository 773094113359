import { Directive, HostListener, Input, AfterViewInit, ElementRef } from '@angular/core';
import { HostBinding } from '@angular/core';
import { OnChanges } from '@angular/core';
import { DecimalPipe } from '@angular/common';

@Directive({
  selector: '[appCount]',
})
export class CountDirective implements AfterViewInit, OnChanges {
  @Input() appCount: number;
  @Input() countStart = false;
  started: false;
  framerate = 66;
  time = 1500;
  steps = this.time / this.framerate;

  constructor(private element: ElementRef, private decimalPipe: DecimalPipe) {}

  ngAfterViewInit() {
    this.reset();
  }

  ngOnChanges(changes) {
    changes && changes['countStart'] && changes['countStart'].currentValue === true ? this.start() : this.reset();
  }

  reset() {
    this.element.nativeElement.innerHTML = 0;
  }

  start() {
    for (let i = 0; i <= this.steps; i++) {
      const delay = i * this.framerate;
      const factor = delay / this.time;
      const formatedNumber = this.decimalPipe.transform(parseInt((factor * this.appCount) as any, 0));

      setTimeout(() => (this.element.nativeElement.innerHTML = formatedNumber), delay);
    }
    setTimeout(() => (this.element.nativeElement.innerHTML = this.decimalPipe.transform(parseInt(this.appCount as any, 0))), this.time);
  }
}
