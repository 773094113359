import { CheckboxGroup } from './../../core/interfaces/checkbox-group.model';
import { FilterState } from './../models/filter-state.model';
import { ProjectPhase } from './../enums/project-phase.enum';
import { Project } from './../models/project.interface';
import { Injectable } from '@angular/core';
import { QueryType } from '../enums/query-type.enum';
import { ElasticQuery } from '../models/elastic-query.model';
import { Observable } from 'rxjs/Observable';
import { ElasticSearchResult } from '../models/elastic-search-result.model';
import { HttpClient } from '@angular/common/http';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import * as _ from 'lodash';
import { ProjectLocation } from '../models/project-location.model';
import { tap } from 'rxjs/operators';
import { environment } from '../../../environments/environment';
import { Event } from '../../content/models/event.model';

@Injectable({ providedIn: 'root' })
export class ProjectService implements Resolve<any> {
  private API_URL = environment.apiUrl;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (route.params['id']) {
      return this.get(+route.params['id']).pipe(
        tap(p => {
          if (!p.PublishOnWebsite) {
            this.router.navigate(['/', '404']);
          }
        })
      );
    }
  }

  constructor(private _http: HttpClient, private router: Router) {}

  get(id): Observable<Project> {
    return this._http.get<Project>(`${this.API_URL}/projects/${id}`);
  }

  getContent(id): Observable<any> {
    return this._http.get(`${this.API_URL}/projects/${id}/content/all`);
  }

  getEvents(id): Observable<Event[]> {
    return this._http.get<Event[]>(`${this.API_URL}/projects/${id}/events`);
  }

  getLocations() {
    return this._http.get(`${this.API_URL}/projects/map`) as Observable<ProjectLocation[]>;
  }

  find(size: number = 12, from: number = 0, filters: Array<ElasticQuery>): Observable<ElasticSearchResult> {
    if (!filters) {
      filters = [
        {
          Type: QueryType.MATCHALL,
          Field: null,
          Values: null,
        },
      ];
    }

    return this._http.post<ElasticSearchResult>(`${this.API_URL}/projects/find/ordered?size=${size}&from=${from}`, filters);
  }

  aggregationsToFilterState(filterState: FilterState, aggregations): FilterState {
    const size = [0, aggregations.SurfaceSize.Value];
    const years = [1993, aggregations.years.Value];
    const checkboxes = ['themes', 'programs', 'country'].map(name => ({
      name,
      alias: (<any>Object.values(aggregations[name].Aggregations)[0]).Meta.alias,
      fields: (<any>Object.values(aggregations[name].Aggregations)[0]).Items.map(item => ({
        value: item.Key,
        checked: false,
      })),
    }));

    const phaseCheckbox = {
      name: 'phase',
      fields: JSON.parse(aggregations.phase.Meta.values)
        .sort((a, b) => a - b)
        .map((item, index) => ({
          value: item,
          placeholder: `project-phase_${index}`,
          checked: false,
        }))
        .filter(item => item.value !== 'None'),
    };

    const state: FilterState = Object.assign(filterState, {
      size: {
        value: size,
        range: size,
      },
      years: {
        value: years,
        range: years,
      },
      checkboxes: [...checkboxes, phaseCheckbox],
    });

    return state;
  }

  paramsToFilterState(filterState: FilterState, params): FilterState {
    const state = filterState;

    if (params['q']) {
      state.q = params['q'];
    }

    ['size', 'years']
      .filter(key => !!params[key])
      .forEach(key => {
        const value = params[key] ? params[key].split('-').map(n => parseInt(n, 0)) : state[key].value;
        state[key].value = value;
      });

    ['themes', 'typologies', 'country', 'phase']
      .filter(key => !!params[key])
      .forEach(key => {
        const i = state.checkboxes.findIndex(c => c.name === key);
        const item = state.checkboxes[i];
        item.fields = item.fields.map(field =>
          Object.assign(field, {
            checked: params[key].split(',').includes(field.value),
          })
        );
        state.checkboxes[i] = item;
      });

    return state;
  }

  filterStateToParams(filterState: FilterState): any {
    const params = {};

    Object.entries(filterState).forEach(([key, value]) => {
      if (key === 'q' || key === 'view' || key === 'isOpen') {
        params[key] = value.toString();
      } else if ((key === 'size' || key === 'years') && !(value.range[0] === value.value[0] && value.range[1] === value.value[1])) {
        params[key] = value.value[0] + '-' + value.value[1];
      } else if (key === 'checkboxes') {
        value.forEach((checkbox: CheckboxGroup) => {
          params[checkbox.name] = checkbox.fields
            .filter(field => field.checked)
            .map(field => field.value)
            .join(',');
        });
      }
    });
    return _.omitBy(params, _.isEmpty);
  }

  filterStateToElasticQuery(filterState: FilterState): ElasticQuery[] {
    let q: ElasticQuery[] = [];

    if (filterState.q) {
      q = [
        ...q,
        {
          Type: QueryType.QUERY,
          Field: 'q',
          Values: [filterState.q],
        },
      ];
    }

    filterState.checkboxes
      .map(group => ({
        name: group.name,
        values: group.fields
          .filter(field => field.checked)
          .map(field => (group.name === 'phase' ? ProjectPhase[field.value.toUpperCase().replace(' ', '_')] || 0 : field.value)),
      }))
      .filter(group => group.values.length)
      .forEach(checkbox => {
        q = [
          ...q,
          {
            Type: checkbox.name === 'phase' ? QueryType.CHECKBOX : QueryType.NESTED,
            Field: checkbox.name,
            Values: checkbox.values,
          },
        ];
      });

    ['size', 'years']
      .filter(key => !!filterState[key])
      .filter(key => {
        const item = filterState[key];
        return !(item.value[0] === item.range[0] && item.value[1] === item.range[1]);
      })
      .forEach(key => {
        q = [
          ...q,
          {
            Type: QueryType.DROPDOWN,
            Field: key === 'years' ? 'year' : key,
            Values: filterState[key].value,
          },
        ];
      });

    return q;
  }
}
