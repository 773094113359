import {
  Component,
  OnInit,
  ChangeDetectionStrategy,
  Pipe,
  PipeTransform,
  ViewChild,
  ElementRef,
  HostListener,
  ChangeDetectorRef,
  Input,
  HostBinding,
  Output,
  EventEmitter,
} from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';
import { ResizePipe } from '@teamfoster/pipes';

@Component({
  selector: 'app-image',
  templateUrl: './image.component.html',
  styleUrls: ['./image.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ImageComponent implements OnInit {
  @Output() loaded = new EventEmitter<boolean>();

  @Input() debug: boolean; // Shows debugdata in table

  @Input() src: string; // URL without resizing pipes etc.
  @Input() alt: string; // Regular Alt text
  @Input() loading: string; // loading attribute

  // When both width and height are filled, this is used to calculate the aspect-ratio
  @Input() width: number;
  @Input() height: number;

  // if image get's clipped, this determines from what anchor
  @Input() anchor:
    | 'topleft'
    | 'topcenter'
    | 'topright'
    | 'middleleft'
    | 'middlecenter'
    | 'middleright'
    | 'bottomleft'
    | 'bottomcenter'
    | 'bottomright';

  // Type of clipping when aspect ratio is given.
  @Input() mode: 'crop' | 'stretch' | 'pad' | 'max';

  // Image file format
  @Input() format: 'jpg' | 'png' | 'bmp' | 'gif' | 'webp';

  // Scale
  @Input() scale: 'both' | 'down' | 'canvas';

  // Set what image sizes the srcset will contain (this will be used to pick from)
  @Input() imageSizes: number[] = [600, 1200, 1600, 1920];

  // How many cols does the image span on what breakpoint?
  // For example: { md: 4, xl: 3 } is the same as bootstrap .col-md-4 .col-xl-3 classes
  @Input() sizes: {
    xs?: number;
    sm?: number;
    md?: number;
    lg?: number;
    xl?: number;
    xxl?: number;
  };

  // Overwrite the sizes attribute (to set it manually)
  @Input() customSizes;

  // Used only for debugging purposes
  @ViewChild('img') img: ElementRef<HTMLImageElement>;

  // Inherited from CSS: $grid-breakpoints
  // is used to generate size attribute with collspans
  cssBreakPoints = {
    sm: 576,
    md: 768,
    lg: 992,
    xl: 1200,
    xxl: 1400,
  };

  // not used yet, could be used to make sizes more accurate
  // containerWidths = [600, 760, 960, 1140, 1320];

  gcd: number; // GCD is the highest number that evenly divides both numbers
  aspectRatio: string; // used for CSS, outputs an string as such: '16/9' or 'auto'
  currentSrc: string; // on resize this will be updated with the chosen image url

  constructor(private resize: ResizePipe, private cd: ChangeDetectorRef, private sanitizer: DomSanitizer) {}

  ngOnInit(): void {
    this.gcd = this.aspect(this.height, this.width);
    this.aspectRatio = this.width && this.height ? `${this.width / this.gcd} / ${this.height / this.gcd}` : 'auto';

    // required alt text
    if (!this.alt) {
      console.error(`Attribute 'alt' is required for image with src ${this.src}`);
    }
  }

  //? enable this if you want to test with chosen img size
  @HostBinding('attr.style')
  public get getCSSvariables(): any {
    return this.sanitizer.bypassSecurityTrustStyle('--aspect-ratio:' + this.aspectRatio);
  }

  // Test purposes only
  // shows which src gets chosen
  @HostListener('window:resize', ['$event'])
  onResize() {
    this.currentSrc = this.img.nativeElement.currentSrc;
    this.cd.markForCheck();
  }

  get sizesAttr() {
    // So youll be able to make your own size property build
    if (this.customSizes) {
      return this.customSizes;
    }
    if (!this.sizes) {
      return '';
    }

    const sizeRules: string[] = [];

    // Sets for each container width an srcset (so for full width images)
    Object.keys(this.sizes).forEach(key => {
      const size = this.sizes[key];
      const breakpoint = this.cssBreakPoints[key];

      const width = (100 / 12) * size;

      sizeRules.push(`(min-width: ${breakpoint}px) ${width}vw`); // TODO calculate width with max width of container & vw?
    });

    // .concat(', 100vw')
    return sizeRules.join(',');
  }

  get srcSetAttr() {
    const srcSetRules: string[] = [];

    this.imageSizes.forEach(imgWidth => {
      srcSetRules.push(
        `${this.resize.transform(
          encodeURI(this.src),
          imgWidth,
          this.getAspectRatioHeight(imgWidth),
          this.anchor,
          this.mode,
          this.format,
          this.scale
        )}  ${imgWidth}w` // TODO calculate width with max width of container & vw?
      );
    });

    return srcSetRules.join(',');
  }

  getAspectRatioHeight(size: number) {
    if (!this.height || !this.width) {
      return null;
    }

    // ? What if only height is given ?
    const percentageDiff = (size - this.width) / this.width;
    const newHeight = Math.round((percentageDiff + 1) * this.height);

    // TODO if aspect ratio is given, calculate aspectratio height based on breakpoint width
    return newHeight;
  }

  aspect(a, b) {
    return !b ? a : this.aspect(b, a % b);
  }
}
