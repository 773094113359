import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-tech-specs',
  templateUrl: './tech-specs.component.html',
})
export class TechSpecsComponent implements OnInit {
  @Input() project: any;
  @Input() cssClasses: string = '';

  statusLookup: string[] = ['Competition', 'Design', 'In progress', 'On site', 'Realised'];

  get themes() {
    return this.project.Themes.map(a => a.title).join(', ');
  }
  get programs() {
    return this.project.Programs.map(a => a.Name).join(', ');
  }
  get scales() {
    return this.project.Scales.map(a => a.Name).join(', ');
  }
  get typologies() {
    return this.project.Typologies.map(a => a.Name).join(', ');
  }

  constructor() {}
  ngOnInit() {
    //console.log(this.project);
  }
}
