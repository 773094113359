import { Injectable } from '@angular/core';
import { Content } from '../models/content.model';
import { News } from '../models/news.model';
import { Event } from '../models/event.model';
import { Page } from '../models/page.model';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { ActivatedRoute } from '@angular/router';
import { ProjectFile } from '../../project/models/project-file.interface';
import { environment } from '../../../environments/environment';
import { MainSocialFollow } from '../../main-nav/interfaces/main-social-follow.interface';

@Injectable()
export class ContentDetailService implements Resolve<Content | News | Event | Page> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<Content | News | Event | Page> {
    const apiIdentifier = route.data['apiIdentifier'];
    const id: number = route.params['id'] || route.data['pageId'];
    const apiUrl = `${environment.apiUrl}/content/${apiIdentifier}/${id}`;

    return this.http.get<Content | News | Event | Page>(apiUrl);
  }

  constructor(private http: HttpClient) {}

  getSubItems(id: number, apiIdentifier: string): Observable<any[]> {
    const apiUrl = `${environment.apiUrl}/content/${apiIdentifier}/${id}/pages`;
    return this.http.get<any[]>(apiUrl);
  }

  getGallery(id: number): Observable<ProjectFile[]> {
    const apiUrl = `${environment.apiUrl}/content//${id}/gallery`;
    return this.http.get<any[]>(apiUrl);
  }
}
