import { Directive, HostListener, Input, AfterViewInit } from '@angular/core';
import { HostBinding } from '@angular/core';

@Directive({
  selector: '[appCollapsableToggle]'
})
export class CollapsableToggleDirective {
  @Input() isOpen = false;

  @HostListener('click', ['$event']) click(e) {
    this.isOpen = !this.isOpen;
  }

  @HostBinding('class.is--open') get update() {
    return this.isOpen;
  }

  constructor(
  ) { }

}
