import { createSelector } from '@ngrx/store';

import * as fromFeature from '../../store/reducers';
import * as fromSocialLinks from '../reducers/social-links.reducer';
import * as fromRoot from '../../../store';

export const getSocialLinksSelectorState = createSelector(
  fromFeature.getMainNavState,
  (state: fromFeature.MainNavState) => state.socialLinks
);

export const getSocialLinksEntities = createSelector(getSocialLinksSelectorState, fromSocialLinks.getSocialLinksEntities);

export const getAllSocialLinks = createSelector(getSocialLinksEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getSocialLinksLoaded = createSelector(getSocialLinksSelectorState, fromSocialLinks.getSocialLinksLoaded);
export const getSocialLinksLoading = createSelector(getSocialLinksSelectorState, fromSocialLinks.getSocialLinksLoading);
