<form class="filter-form background--light" [class.is--open]="isOpen" *ngIf="state">
  <nav class="filter-form__view-nav">
    <ul>
      <li class="filter-form__view-nav__item type--grid" [class.is--active]="view === projectView.GRID">
        <a class="button type--strike-through" [routerLink]="['/projects/']">{{ 'view-grid' | fromDictionary }}</a>
      </li>
      <li class="filter-form__view-nav__item type--list" [class.is--active]="view === projectView.LIST">
        <a [routerLink]="['/projects/list']" class="button type--strike-through">{{ 'view-list' | fromDictionary }}</a>
      </li>
      <li class="filter-form__view-nav__item type--map" [class.is--active]="view === projectView.MAP">
        <a [routerLink]="['/projects/map']" class="button type--strike-through">{{ 'view-map' | fromDictionary }}</a>
      </li>
    </ul>
  </nav>

  <dl class="collapsable-list">
    <dt
      class="collapsable-list__head"
      (click)="toggle()"
      [class.is--open]="isOpen"
      [class.is--clicking-disabled]="view === projectView.MAP"
      [class.is--invisible]="view === projectView.MAP"
    >
      {{ 'filter' | fromDictionary }}
    </dt>
    <span class="filter-form__results-count" *ngIf="view !== 'map' && !loading">
      {{ results > 0 ? results : ('project-filter-no-result-prefix' | fromDictionary) }}
      {{ (results > 1 ? 'projects' : 'project') | fromDictionary }}
      <span *ngIf="state.q">
        {{ 'project-filter-query-prefix' | fromDictionary }}
        <strong>{{ state.q }}</strong>
      </span>
    </span>

    <dd class="collapsable-list__body">
      <fieldset class="fieldset">
        <legend class="legend type--margin">{{ 'search' | fromDictionary }}</legend>
        <div class="fields">
          <input
            type="search"
            class="input"
            [(ngModel)]="state.q"
            (keyup)="updateRoute()"
            id="search"
            #search
            name="search"
            [placeholder]="'type-to-search' | fromDictionary"
          />
          <button type="submit" class="button button-search">
            <app-icon [anchor]="'icon-search'"></app-icon>
          </button>
          <label
            class="search__result-count"
            *ngIf="results !== null"
            for="search"
            [class.hover--underline]="results > 0"
            [class.is--clickable]="results > 0"
            (click)="results > 0 ? toggle() : null"
          ></label>
        </div>
      </fieldset>

      <div class="filter__wrapper">
        <ng-container *ngFor="let group of state.checkboxes">
          <fieldset
            class="filter-select fieldset name--{{ group.name }}"
            *ngIf="group?.fields?.length"
            (clickOutside)="group.isOpen ? toggleSelectBox(group) : null"
          >
            <button class="button filter-select__button" [class.is--open]="group.isOpen" (click)="toggleSelectBox(group)">
              {{ 'filters-' + (group.alias || group.name) | fromDictionary }}
              <span *ngFor="let checked of group | selected" class="filter-select__button__selected">{{ checked }}</span>
            </button>
            <ul class="filter-select__list details-list type--5">
              <ng-container *ngFor="let field of group.fields | orderBy: 'value'">
                <li (click)="field.checked = !field.checked; updateRoute()" class="filter-select__list__item">
                  <input
                    type="checkbox"
                    class="checkbox"
                    [(ngModel)]="field.checked"
                    [name]="field.value"
                    [id]="'form-' + field.value | urlRewrite"
                  />
                  <label class="label" [for]="field.value">
                    {{ field.placeholder ? (field.placeholder | fromDictionary) : field.value }}
                  </label>
                </li>
              </ng-container>
            </ul>
          </fieldset>
        </ng-container>
      </div>
      <fieldset class="fieldset" *ngIf="state.size">
        <legend class="legend">{{ 'filters-Size' | fromDictionary }}</legend>
        <div class="fields">
          <nouislider
            name="size"
            [tooltips]="true"
            [connect]="true"
            [min]="state.size.range[0]"
            [max]="state.size.range[1]"
            [range]="{
              'min': state.size.range[0],
              '10%': [1000],
              '20%': [10000],
              '60%': [350000],
              '80%': [660001],
              '90%': [6000001],
              '95%': [20640001],
              'max': state.size.range[1]
            }"
            [step]="1"
            [format]="sizeFormat"
            (change)="updateRoute()"
            [(ngModel)]="state.size.value"
          ></nouislider>
        </div>
      </fieldset>

      <fieldset class="fieldset" *ngIf="state.years">
        <legend class="legend">{{ 'filters-Years' | fromDictionary }}</legend>
        <div class="fields">
          <nouislider
            name="years"
            [min]="state.years.range[0]"
            [max]="state.years.range[1]"
            [tooltips]="true"
            [connect]="true"
            [step]="1"
            (change)="updateRoute()"
            [(ngModel)]="state.years.value"
          ></nouislider>
        </div>
      </fieldset>
    </dd>
  </dl>
</form>
