import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { PipesModule } from '@teamfoster/pipes';

import * as fromStore from './store';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { LanguageSelectorComponent } from './components/language-selector/language-selector.component';
import { RouterModule } from '@angular/router';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';

@NgModule({
  declarations: [LanguageSelectorComponent],
  imports: [
    CommonModule,
    PipesModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    RouterModule,
    EffectsModule.forFeature(fromStore.effects),
    DictionaryNgrxModule,
  ],

  exports: [LanguageSelectorComponent],
})
export class LanguageModule {}
