import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/project-content.action';
import * as utility from '../../../utility';
import { Content } from '../../../content/models/content.model';

export interface ProjectContentState {
  entities: { [id: number]: Content };
  loading?: number;
  loaded?: number;
  error?: any;
}

export const initialState: ProjectContentState = {
  entities: {},
};

export const ProjectContentReducer = createReducer(
  initialState,
  on(pageActions.LoadProjectContent, (state: ProjectContentState, { id }) => {
    return {
      ...state,
      loading: id,
      loaded: null,
    };
  }),
  on(pageActions.LoadProjectContentSuccess, (state: ProjectContentState, { content }) => {
    const entities = utility.ToEntities(content, 'id', {});

    return {
      ...state,
      entities,
      loading: null,
      loaded: state.loading,
    };
  }),
  on(pageActions.LoadProjectContentFail, (state: ProjectContentState, { error }) => {
    return {
      ...state,
      loading: null,
      loaded: null,
      error,
    };
  })
);

export const getProjectContentEntities = (state: ProjectContentState) => state.entities;
export const getProjectContentLoading = (state: ProjectContentState) => state.loading;
export const getProjectContentLoaded = (state: ProjectContentState) => state.loaded;
