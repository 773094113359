<nav class="pagination" *ngIf="total > 1">
  <h3 class="is--hidden">Pagination</h3>
  <ul>
    <li class="pagination__item" [class.is--invisible]="currentPage <= 0">
      <a class="pagination__arrow" (click)="top()" [routerLink]="prevUrl" [queryParams]="{page: this.currentPage - 1}" queryParamsHandling="merge" rel="prev"><</a>
    </li>

    <li class="pagination__item">
      <span>{{currentPage + 1}} / {{pages() | round}}</span>
    </li>

    <li class="pagination__item" [class.is--invisible]="currentPage >= (pages() | round) - 1">
      <a class="pagination__arrow" (click)="top()" [routerLink]="nextUrl" [queryParams]="{page: this.currentPage + 1}" queryParamsHandling="merge" rel="next">></a>
    </li>
  </ul>
</nav>
