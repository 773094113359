import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import * as fromStore from './store';
import { CookieNoticeComponent } from './components/cookie-notice/cookie-notice.component';
import { StripEmbedPipe } from './pipes/strip-embed.pipe';
import { RouterModule } from '@angular/router';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';

@NgModule({
  declarations: [CookieNoticeComponent, StripEmbedPipe],
  imports: [
    CommonModule,
    RouterModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
    DictionaryNgrxModule
  ],
  providers: [StripEmbedPipe],
  exports: [CookieNoticeComponent, StripEmbedPipe],
})
export class CookieModule {}
