<section class="related-events-section background--light"  #eventsContainer  id="events">
  <h1 class="h2 section-header--left">{{ title | fromDictionary }}</h1>

  <div>
    <!-- *ngIf="allEvents.length === 1" -->
    <app-featured-article
      [item]="asBricks(allEvents)?.[0]"
      *ngIf="allEvents.length === 1 || upcomingEvents.length === 1"
      [showEventDateCard]="!(pastEvents.length > 0 && upcomingEvents.length === 0) || upcomingEvents.length === 1"
    ></app-featured-article>

    <ul class="news-list" *ngIf="eventCards.length > 1">
      <!-- <li class="news-list__item" *ngFor="let item of eventCards | slice : 0 : (showAll ? eventCards.length : 2); trackBy: trackBy"> -->
      <li class="news-list__item" *ngFor="let item of eventCards | slice : 0 : currentIndex; trackBy: trackBy">

        <app-post-news
          [item]="item"
          [hideCategory]="true"
          [imageWidth]="900"
          [showEventDateCard]="!(pastEvents.length > 0 && upcomingEvents.length === 0)"
        ></app-post-news>
      </li>
    </ul>

    <div class="text-center news-list--more" *ngIf="eventCards.length > 2 &&  eventCards.length > defaultPaging ">
      <button class="button button--type--outline button--type--outline-black"  *ngIf="currentIndex < eventCards.length" (click)="currentIndex = currentIndex+itemsToLoad">
        {{ 'news-more-button-text' | fromDictionary }}
      </button>

      <button class="button button--type--outline button--type--outline-black"  *ngIf="currentIndex >= eventCards.length " (click)="lessEvents()">
        {{ 'events-less-button-text' | fromDictionary }}
      </button>
    </div>

    <div class="past-events" [class.set--padding-top]="upcomingEvents.length" *ngIf="pastEvents.length > 0 ">
      <h2 class="type--uppercase" *ngIf="upcomingEvents.length"> {{ 'past-events-title' | fromDictionary }} </h2>

      <ul class="past-event-list">
        <li
        class="past-event-list__item"
        *ngFor="let evt of pastEvents | slice : 0 : currentIndexHisotry; trackBy: trackBy"
      >
        <!-- <li
          class="past-event-list__item"
          *ngFor="let evt of pastEvents | slice : 0 : (showAllHistory ? pastEvents.length : 2); trackBy: trackBy"
        > -->
          <a class="no--underline article-list-item--link-container" [routerLink]="['/events', evt.id, evt.title | urlRewrite]">

            <article class="article-list-item">
              <img
                *ngIf="evt.image"
                class="article-list-item__image"
                [src]="evt.image + '?width=300&height=300&quality=75'"
                [alt]="evt.title"
                width="300"
                height="300"
              />
              <main class="article-list-item__main">
                <time class="article-list-item__date" *ngIf="evt.eventDate">{{ evt.eventDate | amTimeAgo }}</time>

                <h1 class="h4 article-list-item__title">{{ evt.title }}</h1>
              </main>
            </article>
          </a>
        </li>
      </ul>

      <div class="text-center news-list--more" *ngIf="pastEvents.length > defaultPaging ">
        <button class="button button--type--outline button--type--outline-black" *ngIf="currentIndexHisotry < pastEvents.length" (click)="currentIndexHisotry = currentIndexHisotry+itemsToLoad">
          {{ 'news-more-button-text' | fromDictionary }}
        </button>
        <button class="button button--type--outline button--type--outline-black"  *ngIf="currentIndexHisotry >= pastEvents.length " (click)="lessEventsHistory()">
          {{ 'events-less-button-text' | fromDictionary }}
        </button>
      </div>
    </div>
  </div>
</section>

<!--<div>
  <h3>Upcoming events</h3>
  <ul>
    <li *ngFor="let evt of upcomingEvents">
      <a [routerLink]="['/events', evt.id, evt.title | urlRewrite]">
        <strong>{{ evt.eventDate | date : 'dd-MM-yyyy' }}</strong>
        {{ evt.title }}
      </a>
    </li>
  </ul>
</div>
<div>
  <h3>Past events</h3>
  <ul>
    <li *ngFor="let evt of pastEvents">
      <a [routerLink]="['/events', evt.id, evt.title | urlRewrite]">
        <strong>{{ evt.eventDate | date : 'dd-MM-yyyy' }}</strong>
        {{ evt.title }}
      </a>
    </li>
  </ul>
</div>-->
