import { PostNavItem } from './../../../post/interfaces/post-nav.interface';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { ThemeService } from '../../../theme/services/theme.service';
import { ThemeSummary } from '../../../theme/models/theme-summary.model';
import { UrlRewritePipe } from '../../../core/pipes/url-rewrite.pipe';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-related-themes',
  templateUrl: './related-themes.component.html',
})
export class RelatedThemesComponent implements OnInit {
  themes: ThemeSummary[];
  nav: PostNavItem[];
  @Input() currentTheme; // nog aanpassen aan actieve van pagina
  current;
  private _unsubscribe$ = new Subject<void>();

  constructor(private themeService: ThemeService, private urlPipe: UrlRewritePipe) {}

  ngOnInit() {
    this.themeService
      .getThemeSummary()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(a => {
        this.themes = a.filter(t => t.projects.length && t.id !== 15);

        this.nav = this.themes.map(t => ({
          title: t.title,
          url: ['/themes', t.id, this.urlPipe.transform(t.title)],
        }));

        if (this.currentTheme) {
          this.current = a.filter(c => c.id === this.currentTheme.id)[0]; // get current theme data
        } else {
          this.current = this.themes[0];
        }
      });
  }

  public setTheme(event, theme) {
    this.currentTheme = theme;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
