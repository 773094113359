<article class="brick-wall__container__item type--news">
  <header class="brick-wall__container__item__header" *ngIf="item?.DateAdded">
    <a class="hover--underline" rel="noopener noreferrer" [href]="item.Url" target="_blank">
      <time class="brick-wall__container__item__header__date">{{ item.DateAdded | amTimeAgo }}</time>
    </a>
  </header>

  <main class="brick-wall__container__item__content" *ngIf="item?.Url && item?.Title">
    <h1>
      <a [href]="item.Url" rel="noopener noreferrer" target="_blank" class="no--underline">{{ item.Title }}</a>
    </h1>
    <a class="hover--underline" rel="noopener noreferrer" [href]="item.Url" target="_blank">
      <strong *ngIf="item?.HostName">{{ item.HostName }}</strong>
    </a>
  </main>
</article>
