import { WindowRef } from './core/window-ref';
import { Component, OnInit, Inject, PLATFORM_ID, HostListener } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { Router, NavigationEnd, RoutesRecognized } from '@angular/router';
import { scrollToY } from './core/directives/scroll-to.directive';

import { MainNavService } from './main-nav/main-nav.service';
import { isPlatformBrowser, DOCUMENT } from '@angular/common';
import { fadeRouteAnimation } from './core/fade.animation';
import { NavigationService } from './core/services/navigation.service';
import { CookieNoticeService } from './core/services/cookie-notice.service';
import { NotFoundComponent } from './404.component';
import { Store } from '@ngrx/store';
import { getCookiesAccepted } from './cookie/store';
import { filter, map, take, withLatestFrom } from 'rxjs/operators';
import { getLanguageActive } from './language/store/selectors';
import { getDictionaryLoaded } from './dictionary/store';
import { ContentDetailService } from './content/services/content-detail.service';
import { ThemeSummary } from './theme/models/theme-summary.model';
import { getOrderedThemes } from './theme/store';
import { MainNavItem } from './main-nav/interfaces/main-nav.interface';
import { getAllSocialLinks, getOrderedMenuItems, getSocialLinksLoaded } from './main-nav/store';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  animations: [fadeRouteAnimation],
})
export class AppComponent implements OnInit {
  navItems$: Observable<MainNavItem[]>;
  // followItems$ = this.nav.getSocialLinks();
  photoModalOpen = false;
  history: NavigationEnd[] = [];
  loading = true;
  showNewsLetter = false;
  activeLanguage$: Observable<string>;
  dictionaryLoaded$: Observable<boolean>;
  socialsLoaded$: Observable<boolean>;
  schema$: Observable<any>;
  loadedSocial = false;
  themes$: Observable<ThemeSummary[]>;

  constructor(
    @Inject(DOCUMENT) private document: any,
    @Inject(PLATFORM_ID) private platformId,
    private router: Router,
    private nav: MainNavService,
    private navService: NavigationService,
    private contactService: ContentDetailService,
    private store: Store
  ) {}

  getRouterOutletState(outlet) {
    return outlet.isActivated ? outlet.activatedRoute : '';
  }

  getPreviousUrl() {
    if (this.history.length > 1) {
      return this.history[this.history.length - 1].urlAfterRedirects;
    } else {
      return undefined;
    }
  }

  async ngOnInit() {
    this.dictionaryLoaded$ = this.store.select(getDictionaryLoaded);
    this.socialsLoaded$ = this.store.select(getSocialLinksLoaded);
    this.activeLanguage$ = this.store.select(getLanguageActive);
    this.themes$ = this.store.select(getOrderedThemes);
    this.navItems$ = this.store.select(getOrderedMenuItems);

    this.schema$ = this.store.select(getAllSocialLinks).pipe(
      map(links => {
        return {
          '@context': 'http://schema.org',
          '@type': 'Organization',
          'name': 'MVRDV',
          'url': this.document.location.hostname,
          'logo': `${this.document.location.hostname}/assets/gfx/logo.png`,
          'sameAs': [...links.map(a => a.url)],
        };
      })
    );

    this.router.events.pipe(withLatestFrom(this.store.select(getCookiesAccepted))).subscribe(([event, cookies]) => {
      if (isPlatformBrowser(this.platformId) && event instanceof NavigationEnd && cookies) {
        (<any>window).gtag('config', 'G-MW3GYKQKVS', { page_path: event.urlAfterRedirects, anonymize_ip: true });
      }
    });

    if (isPlatformBrowser(this.platformId)) {
      this.document.querySelector('body').classList.add('set--in');
      this.document.querySelector('body').classList.remove('set--ssr');
      this.navService.loading.subscribe(a => (this.loading = a));

      this.store
        .select(getCookiesAccepted)
        .pipe(
          filter(a => a),
          take(1)
        )
        .subscribe(a => {
          if (a) {
            this.loadAnalytics();
            this.loadFacebookPixel();
          }
        });

      this.activeLanguage$.subscribe((lang: string) => {
        this.document.documentElement.lang = lang;
      });
    } else {
      this.document.querySelector('body').classList.add('set--ssr');
      this.loading = false;
    }

    this.router.events.subscribe(e => {
      if (e instanceof NavigationEnd && isPlatformBrowser(this.platformId)) {
        const tree = this.router.parseUrl(this.router.url);
        const el = this.document.getElementById(tree.fragment);
        const queryParamsNav =
          e.urlAfterRedirects.indexOf('?') > -1 ||
          (this.getPreviousUrl() && this.getPreviousUrl().indexOf('?') > -1 && e.urlAfterRedirects === this.getPreviousUrl().split('?')[0]);

        // check scroll position, animate, top top or nothing...
        if (tree.fragment && el) {
          setTimeout(() => scrollToY(el.offsetTop), 0);
        } else if (queryParamsNav) {
          // do nothing
        } else {
          window.scrollTo(0, 0);
        }

        // update history
        this.history = [...this.history, e];
      }
    });
  }

  loadAnalytics() {
    const baseScript = document.createElement('script');
    baseScript.async = true;
    baseScript.src = 'https://www.googletagmanager.com/gtag/js?id=G-MW3GYKQKVS';
    document.body.append(baseScript);

    // analytics
    const $script = document.createElement('script');
    $script.id = 'analytics';
    $script.async = true;
    $script.innerHTML = `window.dataLayer = window.dataLayer || [];
      function gtag() {
        dataLayer.push(arguments);
      }
      gtag('js', new Date());

      gtag('config', 'G-MW3GYKQKVS', { anonymize_ip: true });`;

    document.body.append($script);

    // Tagmanager
    const $tagScript = document.createElement('script');
    $tagScript.id = 'tagmanager';
    $tagScript.async = true;
    $tagScript.innerHTML = `
      (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':

      new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],

      j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=

      'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);

      })(window,document,'script','dataLayer','GTM-WRQTPXP');
    `;

    document.body.append($tagScript);

    // Tagmanager iframe script
    const $tagIframeContainer = document.createElement('noscript');
    const $tagIframe = document.createElement('iframe');
    $tagIframe.src = 'https://www.googletagmanager.com/ns.html?id=GTM-WRQTPXP';
    $tagIframe.style.display = 'none';
    $tagIframe.style.visibility = 'hidden';
    $tagIframeContainer.append($tagIframe);

    document.body.insertAdjacentElement('afterbegin', $tagIframeContainer);
  }

  loadFacebookPixel() {
    const $script = document.createElement('script');
    $script.id = 'Facebook';
    $script.async = true;
    $script.innerHTML = `!function (f, b, e, v, n, t, s) {
            if (f.fbq) return; n = f.fbq = function () {
                n.callMethod ?
                    n.callMethod.apply(n, arguments) : n.queue.push(arguments)
            }; if (!f._fbq) f._fbq = n;
            n.push = n; n.loaded = !0; n.version = '2.0'; n.queue = []; t = b.createElement(e); t.async = !0;
            t.src = v; s = b.getElementsByTagName(e)[0]; s.parentNode.insertBefore(t, s)
        }(window,
            document, 'script', 'https://connect.facebook.net/en_US/fbevents.js');
        fbq('track', 'ViewContent');`;

    document.head.prepend($script);
  }
}
