import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Resolve, RouterStateSnapshot, ActivatedRouteSnapshot } from '@angular/router';

import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/forkJoin';

import { ContentResult } from '../models/content-result.model';
import { environment } from '../../../environments/environment';

@Injectable()
export class ContentOverviewService implements Resolve<ContentResult> {
  itemCount = 24;

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<ContentResult> {
    const apiIdentifier = route.data['apiIdentifier'];
    const page = +route.queryParams['page'] || 0;

    return this.get(apiIdentifier, this.itemCount, page * this.itemCount);
  }

  constructor(private http: HttpClient) {}

  get(identifier: string, take: number = 24, skip: number = 0) {
    const apiUrl = `${environment.apiUrl}/content/${identifier}/get?take=${take}&skip=${skip}`;
    return this.http.get<ContentResult>(apiUrl);
  }

  getUpdates(take: number = 24, skip: number = 0) {
    const contentUrl = `${environment.apiUrl}/content/updates/get?take=${take}&skip=${skip}`;

    const updates$ = this.http.get<ContentResult>(contentUrl);
    return updates$;
  }
}
