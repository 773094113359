import { MakeYoutubePrettyPipe } from './pipes/make-youtube-pretty.pipe';
import { AgmCoreModule } from '@agm/core';
import { ReversePipe } from './pipes/reverse.pipe';
import { BackgroundImageDirective } from './directives/background-image.directive';
import { SwiperConfigInterface, SwiperModule, SWIPER_CONFIG, SwiperComponent } from 'ngx-swiper-wrapper';
import { SpanifyPipe } from './pipes/spanify.pipe';
import { SuperScriptPipe } from './pipes/superscript.pipe';
import { RemoveEmptyElementsPipe } from './pipes/remove-empty-elements.pipe';
import { LoaderComponent } from './components/loader/loader.component';
import { CollapsableToggleDirective } from './directives/collapsable-toggle.directive';
import { WindowRef } from './window-ref';
import { TruncatePipe } from './pipes/truncate.pipe';
import { NgModule } from '@angular/core';
import { CommonModule, DecimalPipe } from '@angular/common';
import { IconComponent } from './components/icon.component';
import { YoutubeComponent } from './components/youtube.component';
import { Html5VideoComponent } from './components/html5video';
import { ScrollToDirective } from './directives/scroll-to.directive';
import { SquarePipe } from './pipes/square.pipe';
import { UrlRewritePipe } from './pipes/url-rewrite.pipe';
import { WordsPipe } from './pipes/words.pipe';
import { SafePipe } from './pipes/safe.pipe';
import { StripHtmlPipe } from './pipes/strip-html.pipe';
import { RouterModule } from '@angular/router';
import { PaginationComponent } from './components/pagination/pagination.component';
import { NgMathPipesModule, NgArrayPipesModule } from 'ngx-pipes';
import { FilterPipe } from './pipes/filter.pipe';
import { CountDirective } from './directives/count.directive';
import { ParallaxService } from './directives/parallax/parallax.service';
import { ParallaxDirective } from './directives/parallax/parallax.directive';
import { InViewportDirective } from './directives/in-viewport.directive';
import { NouisliderModule, NouisliderComponent } from 'ng2-nouislider';
import { StringJoinPipe } from './pipes/string-join.pipe';
import { GetHashPipe } from './pipes/get-hashes.pipe';
import { ExternalLinkDirective } from './directives/external-link.directive';
import { ToggleClassDirective } from './directives/toggle-class.directive';
import { ClickOutsideDirective } from './directives/click-outside.directive';
import { NavigationService } from './services/navigation.service';
import { HeaderComponent } from './components/header/header.component';
import { Http2HttpsPipe } from './pipes/http-https.pipe';
import { ImageComponent } from './components/image/image.component';
import { ResizePipe } from '@teamfoster/pipes';

const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 1,
  keyboard: true,
};

const moduleExports = [
  IconComponent,
  YoutubeComponent,
  ScrollToDirective,
  SquarePipe,
  UrlRewritePipe,
  SafePipe,
  WordsPipe,
  StripHtmlPipe,
  FilterPipe,
  GetHashPipe,
  TruncatePipe,
  CollapsableToggleDirective,
  PaginationComponent,
  LoaderComponent,
  RemoveEmptyElementsPipe,
  SpanifyPipe,
  SuperScriptPipe,
  CountDirective,
  ParallaxDirective,
  InViewportDirective,
  BackgroundImageDirective,
  Html5VideoComponent,
  ReversePipe,
  StringJoinPipe,
  ExternalLinkDirective,
  MakeYoutubePrettyPipe,
  ToggleClassDirective,
  ClickOutsideDirective,
  HeaderComponent,
  Http2HttpsPipe,
  ImageComponent
];

@NgModule({
  imports: [CommonModule, RouterModule, NgMathPipesModule, SwiperModule, NouisliderModule, NgArrayPipesModule],
  declarations: [...moduleExports, StringJoinPipe, ExternalLinkDirective],
  providers: [
    WindowRef,
    DecimalPipe,
    SafePipe,
    ParallaxService,
    NavigationService,
    ResizePipe,
    {
      provide: SWIPER_CONFIG,
      useValue: DEFAULT_SWIPER_CONFIG,
    },
  ],
  exports: [...moduleExports, SwiperComponent, NouisliderComponent],
})
export class CoreModule {}
