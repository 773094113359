import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { ModalComponent } from './modal.component';
import { ModalService } from './modal.service';

@NgModule({
  imports: [
    CoreModule
  ],
  declarations: [
    ModalComponent
  ],
  exports: [
    ModalComponent
  ],
  providers: [
    ModalService
  ],
})
export class ModalModule { }
