<article *ngIf="project$ | async as project">
  <app-post-header
    class="background--dark"
    *ngIf="project.MainImage.ImageUrl || photos[0].url"
    [image]="project.MainImage.ImageUrl || photos[0].url"
    [title]="project.Title"
    (load)="updateNav()"
  ></app-post-header>

  <app-tech-specs
    [cssClasses]="(media$ | async)?.length > 0 && !(media$ | async)[0]?.mediaItem.url ? 'pb-0' : ''"
    [project]="project"
  ></app-tech-specs>

  <app-awards *ngIf="(awards$ | async)?.length" [awards]="awards$ | async"></app-awards>

  <app-featured-photo *ngIf="(media$ | async)?.length" [photo]="(media$ | async)[0]"></app-featured-photo>

  <app-related-projects *ngIf="project.relatedProjects?.length" [projects]="project.relatedProjects"></app-related-projects>

  <section class="content-detail">
    <div class="background--dark content-detail__map" *ngIf="project?.Latitude && project?.Longitude">
      <nav class="content-detail__map__controls">
        <a class="button type--strike-through type--m" [routerLink]="['/projects']" [queryParams]="{ view: projectView.MAP }">
          {{ 'all-projects' | fromDictionary }}
        </a>
      </nav>

      <!--<app-google-static-map [latitude]="project.Latitude" [longitude]="project.Longitude"></app-google-static-map>-->
    </div>

    <app-featured-photo *ngIf="(media$ | async)?.length > 1" [photo]="(media$ | async)[1]"></app-featured-photo>

    <main
      class="content-detail__body set--reset-top-p set--reset-bottom-p text-body background--light"
      *ngIf="project?.Description"
      [innerHTML]="project.Description | removeEmptyElements | stripEmbed : (cookiesAccepted$ | async) | makeYoutubePretty | safe : 'html'"
    ></main>

    <!--<ul class="theme-slide__list background--light">
      <li class="theme-slide__list__item" *ngFor="let c of content$ | async">
        <app-post-teaser [title]="c.title" [url]="c.url" [showArrow]="false"></app-post-teaser>
      </li>
    </ul>-->

    <app-project-news *ngIf="(news$ | async)?.length" [news]="news$ | async"></app-project-news>
  </section>

  <app-featured-photo *ngIf="(media$ | async)?.length > 2" [photo]="(media$ | async)[2]"></app-featured-photo>

  <section class="content-detail" *ngIf="otherMedia$ | async as otherMedia">
    <ng-container *ngFor="let item of otherMedia">
      <app-featured-photo [photo]="item"></app-featured-photo>
    </ng-container>
  </section>

  <app-photo-wall [showThumbs]="true" [title]="'project-gallery-title' | fromDictionary" [photos]="photos$ | async"></app-photo-wall>

  <div class="text-center margin-vertical--075">
    <strong>&copy; {{ 'rights-reserved' | fromDictionary }}</strong>
  </div>

  <app-project-events
    *ngIf="(hasRelatedEvents$ | async) === true"
    [upcomingEvents]="upcomingEvents$ | async"
    [pastEvents]="pastEvents$ | async"
  ></app-project-events>

  <app-project-content
    *ngIf="(otherContent$ | async)?.length"
    [projectTitle]="(project$ | async)?.Title"
    [content]="otherContent$ | async"
  ></app-project-content>

  <app-credits [project]="project"></app-credits>
  <!-- <div (appInViewport)="loadBrickWall = true">
    <app-brick-wall
      *ngIf="(socialPosts$ | async)?.length && loadBrickWall"
      [items]="socialPosts$ | async"
      [title]="'#' + project?.Title"
    ></app-brick-wall>
  </div> -->

  <app-post-nav [title]="'share-title' | fromDictionary" [items]="sharingLinks$ | async" [moreSpacing]="true" [href]="true"></app-post-nav>

  <app-related-themes [currentTheme]="project.Themes[0]"></app-related-themes>
</article>
