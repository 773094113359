import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/menu-items.action';
import * as utility from '../../../utility';
import { MainNavItem } from '../../interfaces/main-nav.interface';

export interface MenuItemsState {
  entities: { [id: number]: MainNavItem };
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: MenuItemsState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const MenuItemsReducer = createReducer(
  initialState,
  on(pageActions.LoadMenuItems, (state: MenuItemsState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadMenuItemsSuccess, (state: MenuItemsState, { items }) => {
    const entities = utility.ToEntities(items, 'id', state?.entities);

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadMenuItemsFail, (state: MenuItemsState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getMenuItemsEntities = (state: MenuItemsState) => state.entities;
export const getMenuItemsLoading = (state: MenuItemsState) => state.loading;
export const getMenuItemsLoaded = (state: MenuItemsState) => state.loaded;
