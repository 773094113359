import * as fromProjects from './projects.reducer';
import * as fromContent from './project-content.reducer';
import * as fromSocials from './project-socials.reducer';
import * as fromEvents from './project-events.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'projects';

export interface ProjectsState {
  projects: fromProjects.ProjectsState;
  content: fromContent.ProjectContentState;
  events: fromEvents.ProjectEventsState;
  socials: fromSocials.ProjectSocialsState;
}

export const reducers: ActionReducerMap<ProjectsState> = {
  projects: fromProjects.ProjectsReducer,
  content: fromContent.ProjectContentReducer,
  events: fromEvents.ProjectEventsReducer,
  socials: fromSocials.ProjectSocialsReducer,
};

export const getProjectsState = createFeatureSelector<ProjectsState>(featureKey);
export const metaReducers: MetaReducer<ProjectsState>[] = !environment.production ? [] : [];
