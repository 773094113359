export class Utility {

  public static concatString(text: string, charCount: number): string {
    if (!text || text.length <= charCount) {
      return text;
    }
    const subString = text.replace(/(<([^>]+)>)/ig, '').substr(0, charCount - 1);
    return subString + '...';
  }

  srcSetFromUrl(url: string) {
    if (!url) return '';
    return `${url}?width=640 100w, ${url}?width=1200 768, ${url}?width=1200 1440, ${url}?width=1920 2560`;
  }

  /* Read css properties of elements */
  public static getPseudoProperty(el: string, pseudo: string, prop: string ) {

    const value = window.getComputedStyle(
      document.querySelector(el), pseudo
    ).getPropertyValue(prop).replace(/^["']|["']$/g, '');

    return value;
  }

  /* Return css breakpoint */
  public static getBreakpoint() {
    return Utility.getPseudoProperty('body', ':after', 'content')
  }
}
