import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, take, switchMap, catchError, filter } from 'rxjs/operators';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import {
  getSelectedTheme,
  getThemeContentLoaded,
  getThemeContentLoading,
  getThemesLoadedIds,
  getThemesLoading,
  LoadTheme,
  LoadThemeContent,
} from '../store';
import { FromDictionaryPipe } from '../../dictionary/pipes/from-dictionary.pipe';
import { MainNavService } from '../../main-nav/main-nav.service';

@Injectable({ providedIn: 'root' })
export class ThemeDetailGuard implements CanActivate {
  constructor(private store: Store, private dict: FromDictionaryPipe, private nav: MainNavService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([this.store.select(getThemesLoadedIds), this.store.select(getThemesLoading)]).pipe(
      tap(([ids, loading]) => {
        if (!loading && !ids.includes(+route.params.id)) {
          this.store.dispatch(LoadTheme({ id: route.params.id }));
        }
      }),
      filter(([ids, loading]) => !loading && ids.includes(+route.params.id)),
      map(([ids, loading]) => !loading && ids.includes(+route.params.id))
    );
  }
}
