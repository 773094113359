<section class="related-news-section background--light" #newsContainer id="news">
  <h1 class="h2 section-header--left">{{ 'project-related-news-title' | fromDictionary }}</h1>

  <app-featured-article [item]="newsBricks[0]" *ngIf="newsBricks.length === 1"></app-featured-article>

  <div>
    <ul class="news-list" *ngIf="newsBricks.length > 1">
      <!-- <li class="news-list__item" *ngFor="let item of newsBricks | slice : 0 : (showAll ? newsBricks.length : 2); trackBy: trackBy"> -->
      <li class="news-list__item" *ngFor="let item of newsBricks | slice : 0 : currentIndex; trackBy: trackBy">

        <app-post-news [item]="item" [hideCategory]="true" [imageWidth]="900" [imageHeight]="500"></app-post-news>
      </li>
    </ul>

    <div class="text-center news-list--more" *ngIf="newsBricks.length > defaultPaging " >
      <!-- <button class="button button--type--outline button--type--outline-black" (click)="showAll = !showAll">
        {{ 'news-more-button-text' | fromDictionary }}
      </button> -->
      <button class="button button--type--outline button--type--outline-black"  *ngIf="currentIndex >= newsBricks.length " (click)="lessNews()">
        {{ 'news-less-button-text' | fromDictionary }}
      </button>
      <button class="button button--type--outline button--type--outline-black" *ngIf="currentIndex < newsBricks.length" (click)="currentIndex = currentIndex+itemsToLoad">
        {{ 'news-more-button-text' | fromDictionary }}
      </button>
    </div>
  </div>
</section>
