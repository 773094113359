import { isPlatformBrowser } from '@angular/common';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { switchMap, take } from 'rxjs/operators';
import { LanguageState } from '../language/store';
import * as selectors from '../language/store/selectors';

@Injectable()
export class LanguageInterceptor implements HttpInterceptor {
  activeLanguage$: Observable<string>;

  constructor(private store: Store<LanguageState>, @Inject(PLATFORM_ID) private platformId) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return this.store.select(selectors.getLanguageActive).pipe(
      take(1),
      switchMap(lang =>
        next.handle(
          req.clone({
            headers: req.headers.set('Language', `${lang}`.toUpperCase()),
          })
        )
      )
    );
  }
}
