<section
  class="content-detail type--employee-detail"
  *ngIf="employee">
  <app-post-header
    class="background--{{employee?.LightImage ? 'dark' : 'light'}}"
    (changes)="updateNav(employee?.LightImage ? 'dark' : 'light')"
    (load)="updateNav(employee?.LightImage ? 'dark' : 'light')"
    [alignLeft]="true"
    [title]="employee.Name"
    [subtitle]="employee?.Function"
    [blackAndWhite]="!employee?.ImageUrl"
    [noOverlay]="true"
    [image]="employee?.ImageUrl || employee?.PortraitUrl">
  </app-post-header>

  <div class="background--light">
    <div class="content-detail__image-caption" *ngIf="employee?.PictureCredits">
        <strong>{{ 'employee-image-credits-prefix' | fromDictionary }}</strong>
        {{employee?.PictureCredits}}
    </div>

    <main class="content-detail__body" *ngIf="employee?.Quote || employee?.Summary">
      <blockquote class="block-title" *ngIf="employee?.Quote"><h1>‘{{employee.Quote}}’</h1></blockquote>
      <div class="text-intro" [innerHTML]="employee?.Summary" *ngIf="employee?.Summary"></div>

      <div class="text-intro content-detail__body__link" *ngIf="employee?.LinkedInUrl">
        {{ 'employee-connect-linkedin-prefix' | fromDictionary }} {{employee.Name | titlecase}} {{ 'employee-connect-linkedin-suffix' | fromDictionary }} <a [href]="employee?.LinkedInUrl">{{ 'linkedin' | fromDictionary }}</a>
      </div>

    </main>

    <footer class="content-detail__details padding-bottom--0" *ngIf="projects?.length">
      <!-- <sub class="content-detail__details__worked-until" *ngIf="employee?.WorkedUntil">
        Employed until: <time>{{employee.WorkedUntil | date: 'dd/MM/yyyy'}}</time>
      </sub> -->

      <dl class="collapsable-list" *ngFor="let group of content">
        <dt class="collapsable-list__head" appCollapsableToggle [isOpen]="true">{{group.contentType}}</dt>
        <dd class="collapsable-list__body">
          <ul class="details-list type--3">
            <li *ngFor="let item of group.items">
              <p>{{item.title}}</p>
            </li>
          </ul>
        </dd>
      </dl>
    </footer>

      <section *ngIf="projects?.length">
        <header class="content-detail__header is--middle-section">
          <div class="content-detail__header__container">
              <h1 class="content-detail__header__title text-center">{{ 'projects-title' | fromDictionary }}</h1>
          </div>
        </header>


        <ul class="project-grid background--dark" *ngIf="projects?.length">
          <ng-container *ngFor="let project of projects">
            <li class="project-grid__item" *ngIf="project.image">
              <app-post-thumb
                [padding]="true"
                [small]="true"
                [dark]="false"
                [image]="project.image"
                [url]="['/projects', project.id, project.title | urlRewrite]"
                [title]="project.title"
                [imgWidth]="400"
                [imgHeight]="300"
                [imgMode]="'crop'"
                >
              </app-post-thumb>
            </li>
          </ng-container>
        </ul>
      </section>


    <app-brick-wall
      *ngIf="wallContent?.length"
      [items]="wallContent"
      [title]="'employee-updates-title' | fromDictionary">
    </app-brick-wall>

  </div>
</section>
