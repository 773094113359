import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { ProjectOverviewComponent } from './containers/project-overview/project-overview.component';
import { ProjectService } from './services/project.service';
import { ProjectDetailComponent } from './containers/project-detail/project-detail.component';
import { SocialService } from '../bricks/services/social.service';
import { ProjectGeneratorComponent } from './containers/portfolio-generator/project-generator.component';
import { LegacyProjectGuard } from './services/legacy-project.service';
import { ProjectMediaResolve } from './services/project-media.resolve';
import { CookieSettingsGuard } from '../cookie/guards/cookie-settings.guard';
import { ActiveLanguageGuard, LanguageGuard } from '../language/guards';
import { DictionaryGuard } from '../dictionary/guards/dictionary.guard';
import { ProjectDetailGuard } from './guards/project-detail.guard';
import { ProjectMetaGuard } from './guards/project-meta.guard';
import { ProjectContentGuard } from './guards/project-content.guard';
import { ProjectSocialsGuard } from './guards/project-socials.guard';
import { ProjectEventsGuard } from './guards/project-events.guard';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    component: ProjectOverviewComponent,
    canActivate: [],
  },
  {
    path: 'list',
    component: ProjectOverviewComponent,
    canActivate: [],
    pathMatch: 'full',
    data: {
      type: 'list',
    },
  },
  {
    path: 'map',
    component: ProjectOverviewComponent,
    canActivate: [],
    pathMatch: 'full',
    data: {
      type: 'map',
    },
  },
  {
    path: ':id/:title',
    component: ProjectDetailComponent,
    canActivate: [ProjectDetailGuard, ProjectMetaGuard, ProjectContentGuard, ProjectSocialsGuard, ProjectEventsGuard],
    runGuardsAndResolvers: 'pathParamsOrQueryParamsChange',
  },
  {
    path: 'portfolio',
    component: ProjectGeneratorComponent,
    canActivate: [],
    pathMatch: 'full',
  },
  {
    path: ':title',
    component: ProjectGeneratorComponent,
    canActivate: [LegacyProjectGuard, CookieSettingsGuard, LanguageGuard, DictionaryGuard],
  },
  // TODO moet eigenlijk zonder /projects ervoor
  {
    path: 'en/:title',
    component: ProjectGeneratorComponent,
    canActivate: [LegacyProjectGuard, CookieSettingsGuard, LanguageGuard, DictionaryGuard],
  },
  {
    path: ':language/projects/:title',
    component: ProjectGeneratorComponent,
    canActivate: [LegacyProjectGuard, CookieSettingsGuard, LanguageGuard, DictionaryGuard],
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class ProjectRoutingModule {}
