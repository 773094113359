import { Injectable, PLATFORM_ID } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, map } from 'rxjs/operators';

import * as fromStore from '../store';
import { Observable, of } from 'rxjs';
import { Inject } from '@angular/core';
import { isPlatformServer } from '@angular/common';

@Injectable({ providedIn: 'root' })
export class CookieSettingsGuard implements CanActivate {
  constructor(private store: Store, @Inject(PLATFORM_ID) private platformId) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    if (isPlatformServer(this.platformId)) {
      return of(true);
    }

    return this.store.select(fromStore.getCookieSettingsInitialized).pipe(
      tap(loaded => {
        if (!loaded) {
          this.store.dispatch(fromStore.InitCookieSettings());
        }
      }),
      filter(loaded => loaded),
      map(loaded => loaded),
      take(1)
    );
  }
}
