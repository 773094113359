import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as MenuItemsActions from '../actions/menu-items.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { MainNavService } from '../../main-nav.service';

@Injectable()
export class MenuItemsEffects {
  constructor(private actions$: Actions, private MenuItemsService: MainNavService) {}

  LoadMenuItems$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(MenuItemsActions.LoadMenuItems),
      switchMap(() =>
        this.MenuItemsService.get().pipe(
          map(items => MenuItemsActions.LoadMenuItemsSuccess({ items })),
          catchError(error => of(MenuItemsActions.LoadMenuItemsFail({ error })))
        )
      )
    );
  });
}
