import { Component, OnInit, Input } from '@angular/core';
import { scrollToY } from '../../directives/scroll-to.directive';

@Component({
  selector: 'app-pagination',
  templateUrl: './pagination.component.html',
})
export class PaginationComponent implements OnInit {
  @Input() total = 0;
  @Input() currentPage = 1;
  @Input() pageSize = 36;
  @Input() prefix = '';
  @Input() speedScroll = 500;

  pageCount: number = this.total / this.pageSize;

  constructor() {}

  ngOnInit() {}

  get nextUrl() {
    return `${this.prefix ? '/' : ''}${this.prefix}`;
  }
  get prevUrl() {
    return `${this.prefix ? '/' : ''}${this.prefix}`;
  }
  pages() {
    return Math.ceil(this.total / this.pageSize);
  }

  top() {
    // scrollToY(0, { speed: this.speedScroll });
    window.scrollTo({ top: 0, behavior: 'smooth' });
  }
}
