import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/employee-summaries.action';
import * as utility from '../../../utility';
import { EmployeeSummary } from '../../interfaces/employee-summary.interface';

export interface EmployeesState {
  entities: { [id: number]: EmployeeSummary };
  loading: boolean;
  loaded: boolean;
  error?: any;
  sortOrder?: string;
}

export const initialState: EmployeesState = {
  entities: {},
  loaded: false,
  loading: false,
  sortOrder: 'studio'
};

export const EmployeesReducer = createReducer(
  initialState,
  on(pageActions.LoadEmployeeSummaries, (state: EmployeesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadEmployeeSummariesSuccess, (state: EmployeesState, { summaries }) => {
    const entities = utility.ToEntities(summaries, 'id', state?.entities);

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadEmployeeSummariesFail, (state: EmployeesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.setEmployeeSortOrder, (state: EmployeesState, { sortOrder }) => {
    return {
      ...state,
      sortOrder,
    };
  })
);

export const getEmployeeSummaries = (state: EmployeesState) => state.entities;
export const getEmployeeSummariesLoading = (state: EmployeesState) => state.loading;
export const getEmployeeSummariesLoaded = (state: EmployeesState) => state.loaded;
export const getEmployeeSortOrder = (state: EmployeesState) => state.sortOrder;
