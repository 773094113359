import { Component, OnInit, ChangeDetectionStrategy, Input, Renderer2, Inject, Output, EventEmitter } from '@angular/core';
import { Store } from '@ngrx/store';
import { LanguageState } from '../../store';
import * as fromLanguage from '../../store';
import * as selectors from '../../store/selectors';
import { Language } from '../../models';
import { Observable } from 'rxjs';
import { DOCUMENT } from '@angular/common';
import { getRouterState } from '../../../store';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-language-selector',
  templateUrl: './language-selector.component.html',

  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class LanguageSelectorComponent implements OnInit {
  @Output() linkClicked = new EventEmitter<void>();
  @Input() customLinks: {
    lang: string;
    url: [string | number];
  }[];

  languages$: Observable<Language[]>;
  activeLanguage$: Observable<string>;

  activeRoute$: Observable<string>;
  anyLinkActive$: Observable<boolean>;

  constructor(private store: Store, private renderer: Renderer2, @Inject(DOCUMENT) private document) {}

  setLanguage(l) {
    this.renderer.removeClass(this.document.body, 'main-nav--active');
    this.store.dispatch(fromLanguage.SetActiveLanguage({ activeLang: l.type }));
  }

  ngOnInit(): void {
    this.languages$ = this.store.select(selectors.getAllLanguages);
    this.activeLanguage$ = this.store.select(selectors.getLanguageActive);

    this.activeRoute$ = this.store.select(getRouterState).pipe(
      map(router => {
        return router?.state.url;
      })
    );

    this.anyLinkActive$ = this.activeRoute$.pipe(
      map(url => {
        return url && this.customLinks.some(link => link.url.toString().toLowerCase().endsWith(url));
      })
    );
  }
}
