import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as SocialLinksActions from '../actions/social-links.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ContentDetailService } from '../../../content/services/content-detail.service';
import { MainNavService } from '../../main-nav.service';

@Injectable()
export class SocialLinksEffects {
  constructor(private actions$: Actions, private service: MainNavService) {}

  LoadSocialLinks$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(SocialLinksActions.LoadSocialLinks),
      switchMap(() =>
        this.service.getSocialLinks().pipe(
          map(items => SocialLinksActions.LoadSocialLinksSuccess({ items })),
          catchError(error => of(SocialLinksActions.LoadSocialLinksFail({ error })))
        )
      )
    );
  });
}
