import { Pipe, PipeTransform } from '@angular/core';

@Pipe({ name: 'words' })
export class WordsPipe implements PipeTransform {
  transform(text: string, count: number): any {
    if (count === 0) {
      return '';
    } else if (count < 0) {
      return text;
    } else if (text) {
      const inputWords = text.split(/\s+/);
      if (inputWords.length > count) {
        text = inputWords.slice(0, count).join(' ') + '\u2026';
      }
      return text;
    } else {
      return text;
    }
  }
}
