import { PostModule } from './../post/post.module';
import { ThemeGridComponent } from './components/theme-grid/theme-grid.component';
import { ThemeSlidesComponent } from './components/theme-slides/theme-slides.component';
import { ThemeSlideComponent } from './components/theme-slide/theme-slide.component';
import { ProjectModule } from './../project/project.module';
import { RouterModule } from '@angular/router';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ThemeDetailComponent } from './containers/theme-detail/theme-detail.component';
import { ThemeOverviewComponent } from './containers/theme-overview/theme-overview.component';
import { ThemeRoutingModule } from './theme-routing.module';
import { ThemeTimelineComponent } from './components/theme-timeline/theme-timeline.component';
import { InViewportModule } from '@thisissoon/angular-inviewport';
import { BricksModule } from '../bricks/bricks.module';
import { ContentModule } from '../content/content.module';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
@NgModule({
  imports: [
    ThemeRoutingModule,
    CommonModule,
    CoreModule,
    RouterModule,
    PostModule,
    ProjectModule,
    BricksModule,
    ContentModule,
    InViewportModule,
    DictionaryNgrxModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  declarations: [
    ThemeDetailComponent,
    ThemeOverviewComponent,
    ThemeSlidesComponent,
    ThemeSlideComponent,
    ThemeGridComponent,
    ThemeTimelineComponent,
  ],
  exports: [ThemeOverviewComponent, ThemeSlidesComponent, ThemeSlideComponent, ThemeGridComponent],
})
export class ThemeModule {}
