import { Pipe, PipeTransform } from '@angular/core';
import { CheckboxGroup } from '../core/interfaces/checkbox-group.model';

@Pipe({
  name: 'selected',
  pure: false
})
export class SelectedPipe implements PipeTransform {
  transform(group: CheckboxGroup): string[] {
    if (!group.fields) {
      return [];
    }
    const returnVal = group.fields.filter(a => a.checked).map(a => a.value);
    return returnVal;
  }
}
