import * as fromSettings from './cookie-settings.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'Cookies';

export interface CookiesState {
  settings: fromSettings.CookieSettingsState;
}

export const reducers: ActionReducerMap<CookiesState> = {
  settings: fromSettings.CookieSettingsReducer,
};

export const getCookiesState = createFeatureSelector<CookiesState>(featureKey);

export const metaReducers: MetaReducer<CookiesState>[] = !environment.production ? [] : [];
