import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Award } from '../../models/award.model';
import { ContentType } from '../../enums/content-type.enum';
import { ContentOverviewService } from '../../services/content-overview.service';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { Meta, Title } from '@angular/platform-browser';
import * as _ from 'lodash';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-awards-overview',
  templateUrl: './awards-overview.component.html',
})
export class AwardsOverviewComponent implements OnInit, OnDestroy {
  pageSize = 999;
  title = 'content';
  contentItems: { year: number; items: Award[] }[];
  contentType: ContentType = ContentType.CONTENT;
  currentPage = 0;
  apiIdentifier = 'content';
  pageLoaded = false;
  contentTypes = ContentType;
  backUrl: string;
  backTitle: string;
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private service: ContentOverviewService,
    private nav: MainNavService,
    private metaTitle: Title,
    private meta: Meta
  ) {}

  ngOnInit() {
    this.nav.setFill('dark');
    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      this.title = a.title;
      this.backUrl = '/about';
      this.backTitle = 'About';
      const items = a.contentResult.items.map((item, i) =>
        Object.assign(item, {
          year: new Date(item.awardedOn).getFullYear(),
        })
      );
      const itemsByYear = _.chain(items)
        .groupBy('year')
        // tslint:disable-next-line:no-shadowed-variable
        .map((items, year) => ({ year, items }))
        .value();

      this.contentItems = itemsByYear;
      this.contentType = a.contentType;
      this.apiIdentifier = a.apiIdentifier;
      this.pageLoaded = true;

      this.metaTitle.setTitle(`MVRDV - ${this.title.toUpperCase()}`);
      this.meta.addTags([
        { property: 'og:title', content: `${this.title.toUpperCase()}` },
        {
          property: 'og:description',
          content:
            'MVRDV is a global operating architecture and urbanism practice with an progressive ideal engaged in solving global issues.',
        },
        {
          name: 'description',
          content:
            'MVRDV is a global operating architecture and urbanism practice with an progressive ideal engaged in solving global issues.',
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@MVRDV' },
        { name: 'twitter:title', content: `${this.title.toUpperCase()}` },
        {
          name: 'twitter:description',
          content:
            'MVRDV is a global operating architecture and urbanism practice with an progressive ideal engaged in solving global issues.',
        },
      ]);
    });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
