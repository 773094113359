import { Directive, HostListener, Input } from '@angular/core';
import { WindowRef } from '../window-ref';

interface ScrollOptions {
  window?: any;
  cb?: () => any;
  speed?: number;
  easing?: 'easeOutSine' | 'easeInOutSine' | 'easeInOutQuint';
}
export function scrollToY(
  scrollTargetY = this.scrollTo,
  options: ScrollOptions = null
) {
  const defaults: ScrollOptions = {
    window: window,
    cb: () => {},
    speed: 2000,
    easing: <'easeOutSine' | 'easeInOutSine' | 'easeInOutQuint'> 'easeOutSine',
  };

  const _options = Object.assign(defaults, options);

  let currentTime = 0;
  const scrollY = _options.window.scrollY;
  const time = Math.max(.1, Math.min(Math.abs(scrollY - scrollTargetY) / _options.speed, .8));
  const PI_D2 = Math.PI / 2;
  const easingEquations = {
    easeOutSine: pos => Math.sin(pos * (Math.PI / 2)),
    easeInOutSine: pos => (-0.5 * (Math.cos(Math.PI * pos) - 1)),
    easeInOutQuint: pos => {
      if ((pos /= 0.5) < 1) {
        return 0.5 * Math.pow(pos, 5);
      }
      return 0.5 * (Math.pow((pos - 2), 5) + 2);
    }
  };

  function tick() {
    currentTime += 1 / 60;
    const p = currentTime / time;
    const t = easingEquations[_options.easing](p);

    if (p < 1) {
      _options.window.requestAnimationFrame(tick);
      _options.window.scrollTo(0, scrollY + ((scrollTargetY - scrollY) * t));
    } else {
      _options.cb();
      _options.window.scrollTo(0, scrollTargetY);
    }
  }
  tick();
}

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[scrollTo]'
})
export class ScrollToDirective {

  @Input() scrollTo = 0;
  @HostListener('click', ['$event']) scroll() {
    scrollToY(this.scrollTo, {
      window: this.window.nativeWindow
    });
  }

  constructor(private window: WindowRef) {

  }
}
