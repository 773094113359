import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripInstagramCrap'
})
export class StripInstagramCrapPipe implements PipeTransform {

  transform(value: string, args?: any): any {
    if (typeof value === 'string') {
      const stripped = value.split(') on Instagram: ');
      return (stripped[1] || value).replace('вЂњ', '').replace('вЂ¦вЂќ', '');
    } else {
      return '';
    }
  }

}
