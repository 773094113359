import { Theme } from './../../models/theme.interface';
import { Component, OnInit, Input } from '@angular/core';
import { Observable } from 'rxjs';
import { Store } from '@ngrx/store';

import * as themesSelectors from '../../store/selectors';

@Component({
  selector: 'app-theme-overview',
  templateUrl: './theme-overview.component.html',
})
export class ThemeOverviewComponent implements OnInit {
  @Input() moreSpacing: Boolean = false;
  themes$: Observable<Theme[]>;

  constructor(private store: Store) {}

  ngOnInit() {
    this.themes$ = this.store.select(themesSelectors.getOrderedThemes);
  }
}
