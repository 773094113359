import { Brick } from './../../../bricks/models/brick.model';
import { Employee } from './../../interfaces/employee.interface';
import { MainNavService } from './../../../main-nav/main-nav.service';
import { EmployeeService } from './../../services/employee.service';
import { Component, OnInit, OnChanges, AfterViewInit, OnDestroy } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ProjectSummary } from '../../../project/models/project-summary.interface';
import { Title, Meta } from '@angular/platform-browser';
import { Utility } from '../../../core/classes/utility';
import { Content } from '../../../content/models/content.model';
import { Event } from '../../../content/models/event.model';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-employee-detail',
  templateUrl: './employee-detail.component.html',
})
export class EmployeeDetailComponent implements OnInit, OnChanges, AfterViewInit, OnDestroy {
  employee: Employee;
  projects: ProjectSummary[];
  content: any[];
  wallContent: Brick[];
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private employeeService: EmployeeService,
    private route: ActivatedRoute,
    private title: Title,
    private meta: Meta,
    private nav: MainNavService,
    private router: Router
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(data => {
      this.employee = data.employee.employee;

      if (this.employee.Hidden) {
        return this.router.createUrlTree(['/404']);
      }

      this.employeeService
        .projects(this.employee.Id)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(a => {
          this.projects = a;
        });
      this.employeeService
        .content(this.employee.Id)
        .pipe(takeUntil(this._unsubscribe$))
        .subscribe(a => {
          this.content = _.chain(a.filter(c => ['news', 'events', 'stack-magazine'].indexOf(c.contentType) < 0))
            .groupBy('contentType')
            .toPairs()
            .map(item => _.zipObject(['contentType', 'items'], item))
            .value();

          this.wallContent = a
            .filter(c => ['news', 'events', 'stack-magazine'].indexOf(c.contentType) >= 0)
            .sort((c1: any, c2: any) => {
              c1 = new Date(c1.publishDate);
              c2 = new Date(c2.publishDate);
              return c1 > c2 ? -1 : c1 < c2 ? 1 : 0;
            })
            .map(c => this.contentToBrick(c));
        });

      this.title.setTitle(`MVRDV - ${this.employee.Name.toUpperCase()}`);
      this.meta.addTags([
        { property: 'og:title', content: `${this.employee.Name.toUpperCase()}` },
        { property: 'og:description', content: Utility.concatString(this.employee.Summary, 300) },
        { property: 'og:image', content: 'https:' + this.employee.ImageUrl },
        { name: 'description', content: Utility.concatString(this.employee.Summary, 160) },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@MVRDV' },
        { name: 'twitter:title', content: `${this.employee.Name.toUpperCase()}` },
        { name: 'twitter:description', content: Utility.concatString(this.employee.Summary, 300) },
        { name: 'twitter:image', content: 'https:' + this.employee.ImageUrl.replace(/ /g, '+') + '?width=600&height=600' },
      ]);
    });

    this.nav.updateLama();
  }

  ngAfterViewInit() {
    this.nav.updateLama();
  }

  ngOnChanges() {}

  updateNav(color: 'dark' | 'light') {
    this.nav.setFill(color);
    this.nav.updateLama();
  }

  contentToBrick(item: Content): Brick {
    return {
      Id: item.id,
      Title: item.title,
      Description: item.text,
      Type: 'article',
      ImageUrl: item.image,
      Url: item.url,
      Favicon: null,
      SiteName: null,
      DateAdded: (<Event>item).eventDate || item.publishDate,
      Author: null,
      ProjectId: null,
      HostName: null,
    };
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
