import { Component, AfterViewInit, Output, EventEmitter, Input, ChangeDetectionStrategy } from '@angular/core';
import { ElementRef } from '@angular/core';

@Component({
  selector: 'app-theme-slide',
  template: '<ng-content></ng-content>',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ThemeSlideComponent implements AfterViewInit {
  constructor(public element: ElementRef) {}

  @Output() init = new EventEmitter();

  ngAfterViewInit() {
    this.init.emit();
  }
}
