import { Component, OnInit, Input } from '@angular/core';
import { Award } from '../../models/award.model';

@Component({
  selector: 'app-award-block',
  templateUrl: './award-block.component.html',
})
export class AwardBlockComponent implements OnInit {
  @Input() item: Award;

  constructor() { }

  ngOnInit() {
  }

}
