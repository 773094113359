import { MainNavService } from './main-nav.service';
import { MainNavComponent } from './main-nav.component';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { CoreModule } from '../core/core.module';
import { LanguageModule } from '../language/language.module';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    LanguageModule,
    DictionaryNgrxModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  declarations: [MainNavComponent],
  providers: [],
  exports: [MainNavComponent],
})
export class MainNavModule {}
