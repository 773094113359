<section class="section-related-projects background--light">
  <h1 class="h2 section-header">{{ 'project-related-projects-title' | fromDictionary }}</h1>

  <swiper #swiper [config]="swiperConfig" *ngIf="projects.length >= 3">
    <li *ngFor="let project of projects; trackBy: trackByFn">
      <a class="project-card--link no--underline" [routerLink]="['/projects', project.Id, project.Title | urlRewrite]">
        <app-project-card [project]="project"></app-project-card>
      </a>
    </li>
  </swiper>

  <ul class="related-project-list" *ngIf="projects.length < 3">
    <li class="related-project-list__item" *ngFor="let project of projects; trackBy: trackByFn">
      <a class="project-card--link no--underline" [routerLink]="['/projects', project.Id, project.Title | urlRewrite]">
        <app-project-card [project]="project"></app-project-card>
      </a>
    </li>
  </ul>
</section>
