import { RouterModule } from '@angular/router';
import { PostModule } from './../post/post.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SocialService } from './services/social.service';
import { CoreModule } from '../core/core.module';
import { MomentModule } from 'angular2-moment';
import { BrickWallComponent } from './components/brick-wall/brick-wall.component';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    MomentModule,
    PostModule,
    RouterModule,
    DictionaryNgrxModule
  ],
  declarations: [
    BrickWallComponent
  ],
  exports: [
    BrickWallComponent
  ],
  providers: [
    SocialService,
   ]
})
export class BricksModule { }
