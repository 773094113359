<article class="featured-article">
  <figure class="featured-article__figure" *ngIf="item.ImageUrl">
    <a [href]="item.Url" class="" [class.has--icon]="item.VideoUrl">
      <app-icon [anchor]="'icon-play'" *ngIf="item.VideoUrl"></app-icon>

      <img [src]="item.ImageUrl + '?width=900&quality=75'" alt="" />

      <time class="date-chip" [datetime]="item.DateAdded | date : 'dd/MM/yyyy'" *ngIf="showEventDateCard">
        <span class="date-chip__day">{{ item.DateAdded | date : 'dd' }}</span>
        <span class="date-chip__month">{{ item.DateAdded | date : 'MMM' }}</span>
      </time>
    </a>
  </figure>

  <main class="featured-article__main" *ngIf="item?.Url && item?.Title">
    <time class="featured-article__main__date" *ngIf="item.ParentTitle === 'news'">{{ item.DateAdded | amTimeAgo }}</time>
    <time class="featured-article__main__date" *ngIf="!showEventDateCard">{{ item.DateAdded | amTimeAgo }}</time>

    <h1 class="h4">
      <a [href]="item.Url" class="no--underline">{{ item.Title }}</a>
    </h1>

    <a
      class="hover--underline featured-article__main__type"
      [routerLink]="item.ParentUrl"
      *ngIf="item.ParentUrl && !item.HostName && !hideCategory"
    >
      <strong class="type--capitalize">{{ item.ParentTitle }}</strong>
    </a>

    <a class="button button--type--outline button--type--outline-black" [routerLink]="item.Url">more information</a>
  </main>
</article>
