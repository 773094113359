import { Component, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable } from 'rxjs';
import { AcceptCookies, getCookiePrefsSet, getCookiesAccepted, getCookiesRejected, RejectCookies } from '../../store';

@Component({
  selector: 'app-cookie-notice',
  templateUrl: './cookie-notice.component.html',
})
export class CookieNoticeComponent implements OnInit {
  cookiesPrefsSet$: Observable<boolean>;
  cookiesAccepted$: Observable<boolean>;
  cookiesRejected$: Observable<boolean>;

  open = false;

  constructor(private store: Store) {}

  ngOnInit() {
    this.cookiesPrefsSet$ = this.store.select(getCookiePrefsSet);
    this.cookiesAccepted$ = this.store.select(getCookiesAccepted);
    this.cookiesRejected$ = this.store.select(getCookiesRejected);
  }

  accept() {
    this.store.dispatch(AcceptCookies());
  }

  reject() {
    this.store.dispatch(RejectCookies());
  }
}
