<section class="credits" *ngIf="project.Teams?.length > 0" [class.padding--0]="!project.Files[3]?.ImageUrl">
  <app-image
    class="credits__bg-image"
    *ngIf="project.creditsBackdrop || project.Files[3]?.ImageUrl"
    [src]="project.creditsBackdrop || project.Files[3]?.ImageUrl"
    loading="lazy"
    [alt]="'Credit achtergrond'"
  ></app-image>

  <div class="credits__content">
    <header class="credits__content__header">
      <h1 class="credits__content__header__title">{{ 'project-credits-title' | fromDictionary }}</h1>
    </header>

    <dl class="details-list type--credits" *ngFor="let t of project.Teams">
      <dt class="details-list__head">{{ t.Title }}</dt>
      <dd class="details-list__body">
        <ng-container *ngIf="t.Members?.length > 0">
          <ul class="details-list type--credits">
            <li *ngFor="let m of t.Members" class="detail-list__item">
              <a *ngIf="!m.ProfileUrl || m.WorkedUntil" target="_blank">{{ m.Name }}</a>
              <a *ngIf="m.ProfileUrl && !m.WorkedUntil" [routerLink]="m.ProfileUrl">{{ m.Name }}</a>
            </li>
          </ul>
        </ng-container>

        <ng-container *ngIf="t.Subgroups?.length > 0">
          <ul class="details-list type--credits">
            <li *ngFor="let subgroup of t.Subgroups">
              <dl class="detail-list__subgroup">
                <dt class="detail-list__subgroup__title" *ngIf="subgroup.Title">{{ subgroup.Title }}:&nbsp;</dt>
                <dd *ngFor="let m of subgroup.Members" class="detail-list__item">
                  <a *ngIf="!m.ProfileUrl || m.WorkedUntil" target="_blank">{{ m.Name }}</a>
                  <a *ngIf="m.ProfileUrl && !m.WorkedUntil" [routerLink]="m.ProfileUrl">{{ m.Name }}</a>
                </dd>
              </dl>
            </li>
          </ul>
        </ng-container>
      </dd>
    </dl>
  </div>
</section>
