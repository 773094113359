import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'spanifyPipe'
})
export class SpanifyPipe implements PipeTransform {

  transform(value: string): string {
    return Array.from(value).map(letter => `<span class="spanified">${letter}</span>`).join('');
  }

}
