import { Component, OnInit, Input, Optional } from '@angular/core';
import { HttpStatusCodeService } from './core/http-status-code.service';

@Component({
  selector: 'app-404',
  template: `
    <article class="error-404">
      <header class="type--404">
        <figure class="error-404__crop">
          <img loading="lazy" class="error-404__image" src="https://www.mvrdv.nl/media/uploads/Tianjin(6).jpg?width=1920" />
        </figure>
        <div class="error-404__content">
          <div class="error-404__content-text">
            <h1 class="h2">Oops...</h1>
            <h2 class="h3">PAGE NOT FOUND</h2>
          </div>
        </div>
        <div class="error-404__button"><a [routerLink]="'/'" class="button color--white type--background">Back to home</a></div>
      </header>
    </article>
  `,
  styles: [
    '.error-404 { text-align: center; height: 100vh; z-index:16; }',
    '.error-404__image { opacity: .3; position: absolute; top: 0; left: 0; width: 100%; height: 100%; object-fit: cover; }',
    '.type--404 { height: 100%; height: 100%; display: flex; justify-content: center; align-items: center; }',
    '.error-404__content { z-index:16; }',
    '.error-404__button { color:black; position: absolute; bottom: 80px; left:50%; text-align:center;transform: translateX(-50%); z-index:16;  }',
  ],
})
export class NotFoundComponent implements OnInit {
  constructor(@Optional() private statusCode: HttpStatusCodeService) {
    if (this.statusCode) {
      this.statusCode.setStatusCode(404);
    }
  }
  ngOnInit() {}
}
