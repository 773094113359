import { NgModule, ModuleWithProviders } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LanguageService } from './services/language.service';
import { DictionaryConfig } from './models/dictionary-config';
import { DictionaryConfigService } from './services/dictionary-config.service';
import { FromDictionaryPipe } from './pipes/from-dictionary.pipe';

import { StoreModule } from '@ngrx/store';
import { EffectsModule, USER_PROVIDED_EFFECTS } from '@ngrx/effects';

import { reducers } from './store/reducers/index';
import { effects } from './store/effects/index';
import { LanguageModule } from '../language/language.module';

export const storeFeature = StoreModule.forFeature('dictionary', reducers);
export const effectsFeature = EffectsModule.forFeature(effects);

@NgModule({
  imports: [CommonModule, storeFeature, effectsFeature],
  declarations: [FromDictionaryPipe],
  exports: [FromDictionaryPipe],
  providers: [],
})
export class DictionaryNgrxModule {
  static forRoot(config: DictionaryConfig): ModuleWithProviders<DictionaryNgrxModule> {
    return {
      ngModule: DictionaryNgrxModule,
      providers: [
        LanguageService,
        {
          provide: DictionaryConfigService,
          useValue: config,
        },
      ],
    };
  }
}
