import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as themesActions from '../actions/theme-content.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ThemeService } from '../../services/theme.service';

@Injectable()
export class ThemeContentEffects {
  constructor(private actions$: Actions, private themesService: ThemeService) {}

  LoadThemeContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(themesActions.LoadThemeContent),
      switchMap(({ id }) =>
        this.themesService.getThemeContent(id).pipe(
          map(content => themesActions.LoadThemeContentSuccess({ content })),
          catchError(error => of(themesActions.LoadThemeContentFail({ error })))
        )
      )
    );
  });
}
