<div
  class="loader"
  *ngIf="loading"
  [@fadeOutAnimation]="!loading"
  [class.is--fixed]="fixed"
  [class.is--initialized]="initialized"
  [class.type--dark]="dark"
  [style.z-index]="zIndex">

  <div class="indicator type-spinner" *ngIf="!simple">
    <span class="indicator-item is--bounce1"></span>
    <span class="indicator-item is--bounce2"></span>
    <span class="indicator-item is--bounce3"></span>
  </div>
</div>
