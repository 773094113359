import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromMenuItems from '../reducers/menu-items.reducer';
import * as fromRoot from '../../../store';

export const getMenuItemsSelectorState = createSelector(fromFeature.getMainNavState, (state: fromFeature.MainNavState) => state.menuItems);

export const getMenuItemsEntities = createSelector(getMenuItemsSelectorState, fromMenuItems.getMenuItemsEntities);

export const getAllMenuItems = createSelector(getMenuItemsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedMenuItems = createSelector(getAllMenuItems, MenuItems => {
  return MenuItems?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getMenuItemsLoaded = createSelector(getMenuItemsSelectorState, fromMenuItems.getMenuItemsLoaded);
export const getMenuItemsLoading = createSelector(getMenuItemsSelectorState, fromMenuItems.getMenuItemsLoading);
