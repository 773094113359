<section class="related-content-section background--light" id="related-content">
  <h1 class="h2 section-header text-center">
    <span>{{ 'project-related-content-title-prefix' | fromDictionary }}&nbsp;</span>

    <span class="type--outline-black">{{ projectTitle }}</span>
  </h1>

  <app-featured-article [item]="bricks[0]" *ngIf="bricks.length === 1"></app-featured-article>

  <ul class="content-list" *ngIf="bricks.length === 2">
    <li class="content-list__item" *ngFor="let item of bricks | slice : 0 : (showAll ? bricks.length : 2); trackBy: trackBy">
      <app-post-news [item]="item" [imageWidth]="900" [imageHeight]="500"></app-post-news>
    </li>
  </ul>

  <app-brick-wall *ngIf="bricks.length > 2" [items]="bricks"></app-brick-wall>
</section>
