import { NgZone, Component, Input, ElementRef, AfterViewInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-post-list',
  templateUrl: 'post-list.component.html'
  // changeDetection: ChangeDetectionStrategy.OnPush,
})

export class PostListComponent implements AfterViewInit {
  isLoaded = false;
  @Input() title: string;
  @Input() text: string;
  @Input() image: string;
  @Input() category: string;
  @Input() url: string | (string | number)[];
  @Output() load = new EventEmitter<any>(null);

  constructor(
    private zone: NgZone,
    private element: ElementRef
  ) {}

  ngAfterViewInit() {
  }

}
