import { Directive, HostListener, Input, AfterViewInit } from '@angular/core';
import { HostBinding } from '@angular/core';

@Directive({
  selector: '[appToggleClass]'
})
export class ToggleClassDirective {
  @Input() isToggle = false;

  @HostListener('click', ['$event']) click(e) {
    this.isToggle = !this.isToggle;
  }

  @HostBinding('class.is--toggle') get update() {
    return this.isToggle;
  }

  constructor(
  ) { }

}
