<section class="related-themes background--dark text-center">
  <header class="content-detail__header">
    <div class="content-detail__header__container">
      <h1 class="content-detail__header__title">{{ 'related-themes-title' | fromDictionary }}</h1>
      <nav class="content-detail__header__filters">
        <ul class="filter">
          <li *ngFor="let theme of themes"
            [routerLink]="['/themes', theme.id, theme.title | urlRewrite]"
            [class.is--active]="current.id === theme.id">
              <a>{{theme.title}}</a>
          </li>
        </ul>
      </nav>
    </div>
  </header>


  <main class="related-themes__projects">
    <ul class="related-themes__projects__list" *ngIf="current">
      <li class="related-themes__projects__list__item" *ngFor="let p of current?.projects">

        <app-post-thumb
          [title]="p.title"
          [url]="p.url"
          [image]="p.image + '?width=400&height=300'"
          [dark]="true"
          [imgWidth]="400"
          [imgHeight]="300"
          >
        </app-post-thumb>
      </li>
    </ul>
  </main>

  <div class="related-themes__post">
    <app-theme-teaser
      [proofLink]="current?.proofLinks[0]"
      *ngIf="current?.proofLinks[0]">
    </app-theme-teaser>
  </div>
</section>
