import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/social-links.action';
import * as utility from '../../../utility';
import { MainSocialFollow } from '../../interfaces/main-social-follow.interface';

export interface SocialLinksState {
  entities: { [channel: string]: MainSocialFollow };
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: SocialLinksState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const SocialLinksReducer = createReducer(
  initialState,
  on(pageActions.LoadSocialLinks, (state: SocialLinksState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadSocialLinksSuccess, (state: SocialLinksState, { items }) => {
    const entities = utility.ToEntities(items, 'channel', state?.entities);

    return {
      ...state,
      entities,
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadSocialLinksFail, (state: SocialLinksState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getSocialLinksEntities = (state: SocialLinksState) => state.entities;
export const getSocialLinksLoading = (state: SocialLinksState) => state.loading;
export const getSocialLinksLoaded = (state: SocialLinksState) => state.loaded;
