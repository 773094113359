<section class="content-detail">
  <header class="content-detail__header is--middle-section">
    <div class="content-detail__header__container">
        <h1 class="content-detail__header__title text-center">{{ 'theme-overview-title' | fromDictionary }}</h1>
    </div>
  </header>

  <app-theme-grid
    [themes]="themes$ | async">
  </app-theme-grid>
</section>
