<div id="recruitee-careers" class="recruitee" [class.set--loading]="loading">
  <ul class="recruitee__list">
    <ng-container *ngIf="!loading">
      <li class="recruitee__list__item spacer-bottom-m" *ngFor="let job of jobs">
        <article class="recruitee-card">
          <h1 class="recruitee-card__title h5">
            <a [href]="job.careers_url" target="_blank">{{ job.title }}</a>
          </h1>
          <ul class="recruitee-card__meta">
            <!-- <li class="recruitee-card__meta__item" *ngIf="job.employment_type_code">Dedication: {{ job.employment_type_code }}</li> -->
            <!-- <li class="recruitee-card__meta__item" *ngIf="job.department">Department: {{ job.department }}</li> -->
            <li class="recruitee-card__meta__item" *ngIf="job.location">{{ job.location }}</li>
          </ul>
        </article>
      </li>
    </ng-container>
    <li class="recruitee__list__item type--loading" *ngIf="loading">{{ 'jobs-loading-text' | fromDictionary }}</li>
    <!-- <li class="recruitee__list__item" *ngIf="jobs.length < 1">Currently we don't have any open positions.</li> -->
  </ul>
</div>
