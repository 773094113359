import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripEmbed',
})
export class StripEmbedPipe implements PipeTransform {
  transform(value: string, cookiesAccepted: boolean): any {
    // console.log(cookiesAccepted);
    if (cookiesAccepted) {
      return value;
    }
    // console.log(value);
    // if(this.cookieService.getCookieAccepted()) {
    //   return value;
    // } else {
    // tslint:disable-next-line:max-line-length
    return value?.replace(
      /<iframe.+?<\/iframe>/g,
      '<div class="embed-removed"><span>This content is not being displayed because cookies have not been accepted.</span></div>'
    );
    //}

    // return value;
  }
}
