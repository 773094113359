import * as fromRouter from '@ngrx/router-store';
import { ActivatedRouteSnapshot, RouterStateSnapshot, Params, Data } from '@angular/router';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../environments/environment';
import * as fromMeta from '../reducers/meta.reducer';

export interface RouterStateUrl {
  url: string;
  queryParams: Params;
  params: Params;
  data: Data;
}

export interface State {
  router: fromRouter.RouterReducerState<RouterStateUrl>;
  meta: fromMeta.MetaState;
}

export const reducers: ActionReducerMap<State> = {
  router: fromRouter.routerReducer,
  meta: fromMeta.metaDataReducer,
};

export const metaReducers: MetaReducer<State>[] = !environment.production ? [] : [];

export const getRouterState = createFeatureSelector<fromRouter.RouterReducerState<RouterStateUrl>>('router');

export class CustomSerializer implements fromRouter.RouterStateSerializer<RouterStateUrl> {
  serialize(routerState: RouterStateSnapshot): RouterStateUrl {
    const { url } = routerState;
    const { queryParams } = routerState.root;

    let state: ActivatedRouteSnapshot = routerState.root;
    let { params } = state;
    let { data } = state;

    while (state.firstChild) {
      state = state.firstChild;
      params = {
        ...params,
        ...state.params,
      };
      data = {
        ...data,
        ...state.data,
      };
    }

    return {
      url,
      queryParams,
      params,
      data,
    };
  }
}
