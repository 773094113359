import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProjectSocials from '../reducers/project-socials.reducer';

export const getProjectSocialsSelectorState = createSelector(
  fromFeature.getProjectsState,
  (state: fromFeature.ProjectsState) => state.socials
);

export const getProjectSocialsEntities = createSelector(getProjectSocialsSelectorState, fromProjectSocials.getProjectSocialsEntities);

export const getAllProjectSocials = createSelector(getProjectSocialsEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedProjectSocials = createSelector(getAllProjectSocials, ProjectSocials => {
  return ProjectSocials?.sort((a, b) => (a.Id <= b.Id ? 1 : -1));
});

export const getProjectSocialsLoaded = createSelector(getProjectSocialsSelectorState, fromProjectSocials.getProjectSocialsLoaded);
export const getProjectSocialsLoading = createSelector(getProjectSocialsSelectorState, fromProjectSocials.getProjectSocialsLoading);
