import { Component, OnInit, Input } from '@angular/core';
import { Shop } from '../../models/shop.model';

@Component({
  selector: 'app-shop-block',
  templateUrl: './shop-block.component.html',
})
export class ShopBlockComponent implements OnInit {
  @Input() item: Shop;

  constructor() { }

  ngOnInit() {
  }

}
