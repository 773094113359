<article class="content-overview awards-overview">

    <header class="awards-overview__header">
      <h1 class="awards-overview__title">{{title}}</h1>
    </header>
<!--
    <div class="awards-overview__container">
        <ol class="awards-overview__list" >
          <li
            class="awards-overview__list__item"
            [routerLink]="[i.url]"
            [class.is--first-date]="i.firstOfYear"
            *ngFor="let i of contentItems; let $index = index;"
            [attr.data-date]="i.awardedOn | date: 'yyyy'">

            <app-award-block [item]="i"></app-award-block>
          </li>
        </ol>
    </div> -->

    <!-- <dl class="collapsable-list">
        <dt class="collapsable-list__head" appCollapsableToggle [isOpen]="true">Projects</dt>
        <dd class="collapsable-list__body">
          <ul class="details-list type--3">
            <li *ngFor="let project of projects">
              <a [routerLink]="['/projects', project.id, project.title | urlRewrite]">{{project.title}}</a>
            </li>
          </ul>
        </dd>
      </dl> -->

    <div class="awards-overview__container">
      <dl class="collapsable-list">
        <ng-container *ngFor="let year of contentItems | reverse; let first = first">
          <dt class="collapsable-list__head" appCollapsableToggle [isOpen]="first">{{year.year}}</dt>
          <dd class="collapsable-list__body">
            <ul class="details-list type--1">
              <li *ngFor="let item of year.items">
                {{item.title}}
              </li>
            </ul>
          </dd>
        </ng-container>
      </dl>
    </div>
</article>
