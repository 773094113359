import * as fromPages from './employee-summaries.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'employees';

export interface EmployeesState {
  employees: fromPages.EmployeesState;
}

export const reducers: ActionReducerMap<EmployeesState> = {
  employees: fromPages.EmployeesReducer,
};

export const getEmployeeSummariesState = createFeatureSelector<EmployeesState>(featureKey);
export const metaReducers: MetaReducer<EmployeesState>[] = !environment.production ? [] : [];
