<section class="background--light content-overview project-overview">
  <header class="content-overview__header container" hidden>
    <h1 class="content-overview__title">{{ 'projects-title' | fromDictionary }}</h1>
  </header>

  <app-filters
    *ngIf="isBrowser"
    (viewChange)="setView($event)"
    [view]="view"
    [isOpen]="filtersOpen"
    [results]="results"
    [loading]="!loaded"
    [state]="filterState"
  ></app-filters>

  <div class="background--light" [hidden]="view !== projectView.LIST">
    <!-- extra div for scrollama-->

    <main class="project-list" *ngIf="view === projectView.LIST" [@fadeAnimation]>
      <app-loader [loading]="!loaded" [dark]="false"></app-loader>

      <ul class="details-list type--3">
        <li *ngFor="let project of projects" [@fadeAnimation]>
          <a [routerLink]="project.routerLink">{{ project.Title }}</a>
        </li>
      </ul>
    </main>
  </div>

  <div class="project-overview__container" [hidden]="view === projectView.LIST">
    <app-loader [loading]="!loaded" [dark]="false"></app-loader>

    <div *ngIf="view === projectView.MAP" class="background--dark content-detail" [@fadeAnimation]>

      <agm-map

        #map
        class="agm-map"
        [mapTypeId]="mapsOptions.type"
        [scrollwheel]="mapsOptions.scrollwheel"
        [zoomControl]="true"
        [disableDoubleClickZoom]="mapsOptions.disableDoubleClickZoom"
        [disableDefaultUI]="mapsOptions.disableDefaultUI"
        [mapTypeControl]="mapsOptions.mapTypeControl"
        [latitude]="mapsOptions.center.latitude"
        [longitude]="mapsOptions.center.longitude"
        [streetViewControl]="mapsOptions.streetViewControl"
        [minZoom]="mapsOptions.minZoom"
        (mapReady)="getMap($event)"
        [zoom]="mapsOptions.zoom"
      >
        <agm-marker-cluster [styles]="mapsOptions.clusterStyles">

            <agm-marker
              *ngFor="let location of locations$ | async"
              [iconUrl]="mapsOptions.iconUrl"
              [latitude]="location.latitude"
              [longitude]="location.longitude"
            >
              <agm-snazzy-info-window
                *ngIf="mapsOptions['infoWindow']"
                [closeWhenOthersOpen]="true"
                [placement]="'top'"
                [maxWidth]="300"
                [maxHeight]="500"
                [border]="false"
                [borderRadius]="false"
                [shadow]="false"
                [showCloseButton]="true"
                (beforeOpen)="markerOpened(location)"
                [isOpen]="false"
                #infoWindow
              >
                <ng-template>
                  <app-post-thumb
                    [padding]="true"
                    [title]="location.title"
                    [text]="location.country"
                    [url]="['/', 'projects', location.id, location.title | urlRewrite]"
                    [image]="location.image"
                    [imgWidth]="400"
                    [imgHeight]="300"
                    [imgMode]="'crop'"
                  ></app-post-thumb>
                </ng-template>
              </agm-snazzy-info-window>
            </agm-marker>

        </agm-marker-cluster>
      </agm-map>
    </div>

    <main class="background--light" *ngIf="view === projectView.GRID" [@fadeAnimation]>
      <!-- extra div for scrollama-->
      <!-- <div *ngIf="view === projectView.GRID" [@fadeAnimation]> -->
      <div [@fadeAnimation]>
        <ul class="project-grid">
          <ng-container *ngFor="let project of projects">
            <li class="project-grid__item" *ngIf="project?.MainImage?.ImageUrl">
              <!-- Enable to debug ordering -->
              <!-- <div
                style="position: absolute; width: 100%; height: 100%; overflow: hidden; background: rgba(255, 255, 255, 0.8); z-index: 999"
              >
                <ul>
                  <li>
                    <h6>{{ project.Title }}</h6>
                  </li>
                  <li>Featured: {{ project.Featured }}</li>
                  <li>Phase: {{ project.Phase }}</li>
                  <li>Is exhibition: {{ hasProgram35(project) }}</li>
                  <li>Order: {{ project.Order }}</li>
                  <li>TP: {{ project.TPNumber }}</li>
                </ul>
              </div> -->
              <app-post-thumb
                [padding]="true"
                [small]="true"
                [dark]="false"
                [image]="project.MainImage.ImageUrl"
                [url]="project.routerLink"
                [title]="project.Title"
                [imgWidth]="400"
                [imgHeight]="300"
                [imgMode]="'crop'"
              ></app-post-thumb>
            </li>
          </ng-container>
        </ul>
        <app-pagination
          [total]="results"
          [currentPage]="paging"
          [pageSize]="maxProjects"
          [prefix]="'projects/'"
          [speedScroll]="100"
        ></app-pagination>
      </div>
    </main>
    <div class="project-overview__no-results search__noresults text-body" *ngIf="projects.length <= 0">
      <p>
        {{ 'project-no-result' | fromDictionary }}
        <a href="/projects">{{ 'project-reset-filters' | fromDictionary }}</a>
      </p>
    </div>
  </div>
</section>

<ng-container *ngIf="loaded">
  <app-post-nav
    [title]="'themes-title' | fromDictionary"
    *ngIf="themes$ | async as themes"
    [items]="themes"
    [moreSpacing]="true"
  ></app-post-nav>
</ng-container>
