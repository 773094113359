import { fadeInAnimation } from './../../../core/fade.animation';
import { MainNavService } from './../../../main-nav/main-nav.service';
import { Title, Meta } from '@angular/platform-browser';
import { OnInit, AfterViewInit, Component, HostListener, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { constants } from '../../../core/constants';
import { ModalService } from '../../../modal/modal.service';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { MainSocialFollow } from '../../../main-nav/interfaces/main-social-follow.interface';
import { ContentDetailService } from '../../services/content-detail.service';
import { FromDictionaryPipe } from '../../../dictionary/pipes/from-dictionary.pipe';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  animations: [fadeInAnimation],
})
export class ContactComponent implements OnInit, AfterViewInit, OnDestroy {
  title = '';
  zoom = 5;

  locations = [
    {
      latitude: 51.92348,
      longitude: 4.492613,
      title: 'MVRDV Rotterdam',
      dictionaryName: 'rotterdam',
      body: '<p>\r\nt:+31 (0)10 477 2860\u2028<br>\u2028\u00A0\r\n</p>\r\n<p>\r\n<a href="mailto:office@mvrdv.com">office@mvrdv.com</a><br>\r\n\u2028\u2028Achterklooster 7\r\n\u20283011 RA Rotterdam NL\u2028\u2028<br>\r\nPost Box 63136\u2028<br>\r\n3011 RA Rotterdam NL<br>\r\n<p>',
    },
    {
      latitude: 31.2068169,
      longitude: 121.439516,
      title: 'MVRDV Shanghai',
      dictionaryName: 'shanghai',
      body: '<p>\r\nt:+86 21 61131920<br>\r\nf:+86 21 6113 6583\r\n</p>\r\n<p>\r\n<a href="mailto:china@mvrdv.com">china@mvrdv.com</a><br>\r\n6B/6F 374-376 Wukang Road<br>\r\n200031 Shanghai, CN\r\n<p>',
    },
    {
      latitude: 48.868171,
      longitude: 2.344356,
      title: 'MVRDV Paris',
      dictionaryName: 'paris',
      body: '<p><p>',
    },
    {
      latitude: 52.497636409250966,
      longitude: 13.380275276099196,
      title: 'MVRDV Germany',
      dictionaryName: 'germany',
      body: '<p><p>',
    },
    {
      latitude: 40.698380,
      longitude: -73.971141,
      title: 'MVRDV New York',
      dictionaryName: 'new-york',
      body: '<p><p>',
    },
  ];

  options = constants.mapsDefaults;
  map;
  socials: MainSocialFollow[];
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private metaTitle: Title,
    private meta: Meta,
    private nav: MainNavService,
    private contactService: MainNavService,
    public modal: ModalService,
    private dict: FromDictionaryPipe
  ) {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(d => {
      this.title = d.title;
    });

    this.metaTitle.setTitle(`MVRDV - ${this.dict.transform('contact-meta-title')}`);
    this.meta.addTags([
      { property: 'og:title', content: `MVRDV - ${this.dict.transform('contact-meta-title')}` },
      {
        property: 'og:description',
        content: this.dict.transform('contact-meta-description'),
      },
      {
        name: 'description',
        content: this.dict.transform('contact-meta-description'),
      },
      { name: 'twitter:card', content: 'summary' },
      { name: 'twitter:site', content: '@MVRDV' },
      { name: 'twitter:title', content: `MVRDV - ${this.dict.transform('contact-meta-title')}` },
      {
        name: 'twitter:description',
        content: this.dict.transform('contact-meta-description'),
      },
    ]);

    this.contactService
      .getSocialLinks()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(d => (this.socials = d));

    this.setZoom();
  }

  ngAfterViewInit() {
    this.nav.setFill('dark');
    this.nav.updateLama();
  }

  @HostListener('window:resize', ['$event'])
  setZoom() {
    if (typeof window === 'undefined') return;
    else if (window.innerWidth < 480) this.options.zoom = 1;
    else if (window.innerWidth < 992) this.options.zoom = 2;
    else this.options.zoom = 3;
    this.recenter(1000);
  }

  recenter(timeout = 0) {
    setTimeout(() => {
      if (this.map) {
        this.map.setCenter({
          lat: this.options.center.latitude,
          lng: this.options.center.longitude,
        });
      }
    }, timeout);
  }

  getMap(map) {
    this.map = map;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }

  dictionaryTextFound(text) {
    return !text.startsWith('TEXT NOT FOUND FOR');
  }
}
