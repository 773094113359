import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromThemes from '../reducers/themes.reducer';
import * as fromRoot from '../../../store';
import { Theme } from '../../models/theme.interface';

export const getThemesSelectorState = createSelector(fromFeature.getThemesState, (state: fromFeature.ThemesState) => state.themes);

export const getThemesEntities = createSelector(getThemesSelectorState, fromThemes.getThemesEntities);

export const getAllThemes = createSelector(getThemesEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedThemes = createSelector(getAllThemes, themes => {
  return themes?.filter(a => a.publish)?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getThemesLoaded = createSelector(getThemesSelectorState, fromThemes.getThemesLoaded);
export const getThemesLoading = createSelector(getThemesSelectorState, fromThemes.getThemesLoading);
export const getThemesLoadedIds = createSelector(getThemesSelectorState, fromThemes.getThemesLoadedIds);

export const getSelectedTheme = createSelector(getThemesEntities, fromRoot.getRouterState, (entities, router): Theme => {
  return router.state && entities[router.state.params?.id || router.state.data?.id];
});

export const getSelectedThemeLoaded = createSelector(getThemesLoadedIds, fromRoot.getRouterState, (ids, router): boolean => {
  return router.state && ids.includes(router.state.params?.id || router.state.data?.id);
});
