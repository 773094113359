import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'stripDomain'
})
export class StripDomainPipe implements PipeTransform {

  transform(value: string = '', args?: any): any {
    const domainRegex = /.(com|net|org|info|coop|int|com.au|co.uk|org.uk|ac.uk|nl|be|de|fr|cn)$/igm;
    return value.replace(domainRegex, '');
  }

}
