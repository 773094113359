import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProjectContent from '../reducers/project-content.reducer';
import * as fromRoot from '../../../store';
import { Project } from '../../models/Project.interface';

export const getProjectContentSelectorState = createSelector(
  fromFeature.getProjectsState,
  (state: fromFeature.ProjectsState) => state.content
);

export const getProjectContentEntities = createSelector(getProjectContentSelectorState, fromProjectContent.getProjectContentEntities);

export const getAllProjectContent = createSelector(getProjectContentEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedProjectContent = createSelector(getAllProjectContent, ProjectContent => {
  return ProjectContent?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getProjectWallContent = createSelector(getOrderedProjectContent, ProjectContent => {
  return ProjectContent.sort((a1, a2) => {
    const d1 = new Date(a1.publishDate);
    const d2 = new Date(a2.publishDate);
    return d1 > d2 ? -1 : d1 < d2 ? 1 : 0;
  });
});

export const getProjectContentLoaded = createSelector(getProjectContentSelectorState, fromProjectContent.getProjectContentLoaded);
export const getProjectContentLoading = createSelector(getProjectContentSelectorState, fromProjectContent.getProjectContentLoading);
