import * as dictionaryActions from '../actions/dictionary.action';
import { DictionaryEntry } from '../../models';
import { Action, createReducer, on } from '@ngrx/store';

export interface DictionaryState {
  entities: { [placeholder: string]: DictionaryEntry };
  loaded: boolean;
  loading: boolean;
}

export const initialState: DictionaryState = {
  entities: {},
  loaded: false,
  loading: false,
};

export const DictionaryReducer = createReducer(
  initialState,
  on(dictionaryActions.LoadDictionarySuccess, (state: DictionaryState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(dictionaryActions.LoadDictionarySuccess, (state: DictionaryState, { entries }) => {
    const entities = entries.reduce(
      (dictEntries: { [placeholder: string]: DictionaryEntry }, entry: DictionaryEntry) => {
        return {
          ...dictEntries,
          [entry.placeholder.toUpperCase()]: entry,
        };
      },
      {
        ...state.entities,
      }
    );

    return {
      ...state,
      loading: false,
      loaded: true,
      entities,
    };
  }),
  on(dictionaryActions.LoadDictionaryFail, (state: DictionaryState, { error }) => {
    return {
      ...state,
      loading: false,
    };
  })
);

export const getDictionaryEntities = (state: DictionaryState) => state.entities;
export const getDictionaryLoading = (state: DictionaryState) => state.loading;
export const getDictionaryLoaded = (state: DictionaryState) => state.loaded;
