import { Component, OnInit, Input } from '@angular/core';
import { Event } from '../../models/event.model';

@Component({
  selector: 'app-event-block',
  templateUrl: './event-block.component.html',
})
export class EventBlockComponent implements OnInit {
  @Input() item: Event;

  constructor() { }

  ngOnInit() {
  }

}
