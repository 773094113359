<section class="theme-grid background--dark" [class.is--active]="isActive" (appInViewport)="isActive = true">
  <header hidden>
    <h1>Themes</h1>
  </header>

  <ul class="theme-grid__list">
    <li *ngFor="let theme of themes; let i = index; trackBy: trackBy" class="theme-grid__list__item">
      <app-post-thumb
        [image]="theme.image"
        [title]="theme.title"
        [url]="theme.routerLink || ['/themes', theme.id, theme.title | urlRewrite]"
        padding="true"
        [imgWidth]="640"
        [imgHeight]="480"
        [imgMode]="'crop'"
      ></app-post-thumb>
    </li>

    <!-- <li class="theme-grid__list__item">
      <a class="theme-grid__list__item__logo" routerLink="/">
        <svg class="ng-tns-c1-1" height="37" viewBox="0 0 112 37" width="112" fill="black">
          <title class="ng-tns-c1-1">
            MVRDV
          </title>
          <g class="ng-tns-c1-1" fill-rule="nonzero">
            <path d="M16.5,0L13,18.5L10,0H0v37h6V14.9L10.5,37h4l5-22.6V37H26V0H16.5z M41.7,0l-3.4,19.5L34.8,0H28l6.8,37h5.8L48,0H41.7z
              M105.7,0l-3.4,19.5L98.8,0H92l6.8,37h5.9L112,0H105.7z M65.4,20c2.7-1.4,4.1-4.5,4.1-9.2c0-6.9-3.2-10.4-9.7-10.8H50v37h6.7V21.1
              h2.6L62.3,37H70C70,37,65.4,20,65.4,20z M62.3,11.3c0,2.1-1,3.3-3.1,3.6h-2.6V5.7h2.6c2.1,0,3.1,1.2,3.1,3.6V11.3z M78.3,0H72v37
              h8.3c6.2,0,9.4-3.3,9.7-10V12.1C90,4,86.1,0,78.3,0z M84.2,26.2c0,3.4-1.5,5.1-4.4,5.1h-1.5V5.7h1c3.2,0,4.9,1.7,4.9,5.1
              C84.2,10.8,84.2,26.2,84.2,26.2z"/>
          </g>
        </svg>
      </a>
    </li> -->
  </ul>
</section>
