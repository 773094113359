import { Component, OnInit, Input } from '@angular/core';
import { ProjectSummary } from '../../../project/models/project-summary.interface';
import { ActivatedRoute } from '@angular/router';
import { has } from 'lodash';

@Component({
  selector: 'app-credits',
  templateUrl: './credits.component.html'
})
export class CreditsComponent implements OnInit {
  @Input()
  project: any;
  hasMembers = false;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {}
}
