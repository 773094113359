import { Observable } from 'rxjs/Observable';
import { Resolve } from '@angular/router';
import { ContentResult } from './../models/content-result.model';
import { Injectable } from '@angular/core';
import { ContentOverviewService } from './content-overview.service';


@Injectable()
export class UpdatesResolve implements Resolve<ContentResult> {
  resolve(): Observable<ContentResult> {
    return this.service.getUpdates(this.service.itemCount, 0);
  }

  constructor(private service: ContentOverviewService) { }
}
