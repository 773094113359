import { Component, OnInit, ElementRef, OnDestroy } from '@angular/core';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Job } from '../../models/job.model';
import { RecruiteeService } from '../../services/recruitee.service';
// declare var RTWidget: any;

@Component({
  selector: 'app-recruitee',
  templateUrl: './recruitee.component.html',
})
export class RecruiteeComponent implements OnInit, OnDestroy {
  private nativeElement: Node;
  jobs: Job[];
  loading = true;

  private _unsubscribe$ = new Subject<void>();

  constructor(private element: ElementRef, private jobService: RecruiteeService) {}

  ngOnInit() {
    this.jobService
      .getJobs()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(j => {
        this.jobs = j.offers;
        setTimeout(() => {
          this.loading = false;
        }, 400);
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
