import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';
import { Job } from '../models/job.model';

@Injectable({
  providedIn: 'root',
})
export class RecruiteeService {
  constructor(
    private http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string
  ) {}

  getJobs() {
    return this.http.get<any>(environment.recruiteeApi);
  }
}
