import * as fromPages from './themes.reducer';
import * as fromContent from './theme-content.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'themes';

export interface ThemesState {
  themes: fromPages.ThemesState;
  content: fromContent.ThemeContentState;
}

export const reducers: ActionReducerMap<ThemesState> = {
  themes: fromPages.ThemesReducer,
  content: fromContent.ThemeContentReducer,
};

export const getThemesState = createFeatureSelector<ThemesState>(featureKey);
export const metaReducers: MetaReducer<ThemesState>[] = !environment.production ? [] : [];
