import { createAction, props } from '@ngrx/store';
import { Language } from '../../models';

export const LoadLanguages = createAction('[Language] Load Languages');

// export const LoadLanguagesSuccess = createAction('[Language] Load Language Success', props<{ languages: Language[] }>());
export const LoadLanguagesSuccess = createAction('[Language] Load Language Success', props<{ languages: any }>());
export const LoadLanguagesFail = createAction('[Language] Load Languages Fail', props<{ error: any }>());

export const SetActiveLanguage = createAction('[Language] Set Active Language', props<{ activeLang: string }>());
export const SetActiveLanguageSuccess = createAction('[Language] Set Active Language Success', props<{ activeLang: string }>());

export const InitActiveLanguage = createAction('[Language] Initialize selected language');
export const InitActiveLanguageComplete = createAction('[Language] Initialize selected language Complete', props<{ activeLang: string }>());
