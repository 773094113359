import { ProjectContentEffects } from './project-content.effect';
import { ProjectEventsEffects } from './project-events.effect';
import { ProjectSocialsEffects } from './project-socials.effect';
import { ProjectsEffects } from './projects.effect';

export const effects = [ProjectsEffects, ProjectContentEffects, ProjectSocialsEffects, ProjectEventsEffects];
export * from './projects.effect';
export * from './project-content.effect';
export * from './project-socials.effect';
export * from './project-events.effect';
