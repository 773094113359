import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getRouterState, SetTags, SetTitle } from '../../store';
import { getSelectedProject } from '../store';
import { Utility } from '../../core/classes/utility';

@Injectable({ providedIn: 'root' })
export class ProjectMetaGuard implements CanActivate {
  constructor(private store: Store) {}

  canActivate(): Observable<boolean> {
    return this.checkStore().pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(): Observable<boolean> {
    return this.store.select(getSelectedProject).pipe(
      withLatestFrom(this.store.select(getRouterState)),
      tap(([project, router]) => {
        if (project?.Id !== +router.state.params.id) {
          return;
        }
        this.store.dispatch(SetTitle({ title: `MVRDV - ${project.Title}` }));
        this.store.dispatch(
          SetTags({
            properties: {
              'description': Utility.concatString(project.Summary, 160),
              'og:title': `MVRDV - ${project.Title}`,
              'og:image': 'https:' + project.MainImage.ImageUrl.replace(/ /g, '%20'),
              'og:description': Utility.concatString(project.Summary, 300),
              'twitter:card': 'summary_large_image',
              'twitter:site': '@MVRDV',
              'twitter:title': `MVRDV - ${project.Title}`,
              'twitter:description': Utility.concatString(project.Summary, 300),
              'twitter:image': 'https://' + project.MainImage.ImageUrl.replace(/^.*:\/\//i, '').replace(/ /g, '%20') + '?width=1200',
            },
          })
        );
      }),
      filter(([project, router]) => project?.Id === +router.state.params.id),
      map(([project, router]) => project?.Id === +router.state.params.id)
    );
  }
}
