import { Injectable } from '@angular/core';
import { Observable } from 'rxjs/Observable';
import { HttpClient } from '@angular/common/http';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class ProjectGeneratorService {
  private API_URL = environment.apiUrl;
  private numberOfProjects = 128;

  constructor(private _http: HttpClient) {}

  get(id): Observable<any> {
    return this._http.get(`${this.API_URL}/projects/${id}`);
  }

  find(): Observable<any> {
    return this._http.get<any>(`${this.API_URL}/projects/programs`);
  }
}
