import { Injectable, Optional } from '@angular/core';
import { CanActivate, Router, UrlTree } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, switchMap, catchError, withLatestFrom, filter } from 'rxjs/operators';

import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getProjectsLoaded, getProjectsLoading, getSelectedProject, LoadProject } from '../store';
import { getRouterState } from '../../store';
import { HttpStatusCodeService } from '../../core/http-status-code.service';

@Injectable({ providedIn: 'root' })
export class ProjectDetailGuard implements CanActivate {
  constructor(private store: Store, private router: Router, @Optional() private statusCode: HttpStatusCodeService) {}

  canActivate(): Observable<boolean | UrlTree> {
    return this.checkStore();
  }

  checkStore(): Observable<boolean | UrlTree> {
    return this.store.select(getSelectedProject).pipe(
      withLatestFrom(this.store.select(getProjectsLoaded), this.store.select(getProjectsLoading), this.store.select(getRouterState)),
      tap(([project, loaded, loading, router]) => {
        if (!project?.Id && !loading) {
          this.store.dispatch(LoadProject({ id: router.state.params.id }));
        }
      }),
      filter(([project, loaded, loading]) => project?.Id && loaded),
      map(([project, loaded, loading]) => {
        if (!project.PublishOnWebsite) {
          return this.router.createUrlTree(['/404']);
        }
        return project?.Id && loaded && project.PublishOnWebsite;
      })
    );
  }
}
