import { PostNavItem } from './../../interfaces/post-nav.interface';
import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-post-nav',
  templateUrl: './post-nav.component.html'
})
export class PostNavComponent implements OnInit {
  @Input() title;
  @Input() items: PostNavItem[];
  @Input() background: 'light' | 'dark' = 'light';
  @Input() href = false;
  @Input() big = false;
  @Input() moreSpacing = false;

  constructor() { }

  ngOnInit() {

  }

}
