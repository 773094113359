import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-post-thumb',
  templateUrl: './post-thumb.component.html',
})
export class PostThumbComponent implements OnInit {
  isLoaded = false;
  @Input() title: string;
  @Input() text: string;
  @Input() image: string;
  @Input() url: string | (string | number)[];
  @Input() urlParams: any;
  @Input() href = false;
  @Input() padding = false;
  @Input() small = false;
  @Input() dark = false;
  @Output() load = new EventEmitter<any>(null);

  @Input() imgWidth: number;
  @Input() imgHeight: number;
  @Input() imgMode: 'crop' | 'stretch' | 'pad' | 'max';

  inview = false;
  constructor() {}

  ngOnInit() {}
}
