<!-- for testing... -->
<!-- <pre>{{item | json}}</pre> -->

<article class="brick-wall__container__item" (appInViewport)="inview = $event">
  <header class="brick-wall__container__item__header">
    <span class="brick-wall__container__item__header__title">
      <strong class="brick-wall__container__item__header__user">
        <a class="hover--underline" [href]="'//' + item.HostName + '/' + item.Author" rel="noopener noreferrer" target="_blank">
          {{ item.Author }}
        </a>
      </strong>
    </span>
    <a class="brick-wall__container__item__header__title hover--underline" [href]="item.Url" rel="noopener noreferrer" target="_blank">
      <time class="brick-wall__container__item__header__date">
        {{ item.DateAdded | amTimeAgo }} {{ 'post-date-added-on' | fromDictionary }}
        <a class="no--underline" [href]="'//' + item.HostName" rel="noopener noreferrer" target="_blank">
          {{ item.HostName | stripDomain }}
        </a>
      </time>
    </a>
  </header>

  <main class="brick-wall__container__item__content">
    <figure>
      <figcaption class="brick-wall__container__item__content__description">
        <p
          *ngIf="item.HostName === 'instagram.com'; else twitter"
          [innerHTML]="item.Description | stripInstagramCrap | getHash | linky: { hashtag: 'instagram', mention: 'instagram' }"
        ></p>
        <ng-template #twitter>
          <p
            *ngIf="item.HostName === 'twitter.com'; else facebook"
            [innerHTML]="item.Description | stripTwitterCrap | linky: { hashtag: 'twitter', mention: 'twitter' }"
          ></p>
        </ng-template>
        <ng-template #facebook>
          <p *ngIf="item.HostName === 'facebook.com'; else other" [innerHTML]="item.Description | linky: { hashtag: 'facebook' }"></p>
        </ng-template>
        <ng-template #other>
          <p [innerHTML]="item.Description | linky"></p>
        </ng-template>
      </figcaption>

      <a
        [href]="item.Url"
        rel="noopener noreferrer"
        target="_blank"
        class="brick-wall__container__item__content__img"
        [class.has--icon]="item.VideoUrl"
      >
        <app-icon [anchor]="'icon-play'" *ngIf="item.VideoUrl"></app-icon>

        <img
          loading="lazy"
          [src]="item.ImageUrl + (item.HostName === 'twitter.com' || item.HostName === 'instagram.com' ? '?w=360' : '')"
          alt=""
          (load)="load.emit($event)"
          *ngIf="item.ImageUrl && inview"
        />
      </a>

      <!-- no CORS so disabled for now :( -->
      <!--
      <div class="brick-wall__container__item__content__video" *ngIf="item.VideoUrl">
        <app-html5-video
          [posterSrc]="item.ImageUrl"
          [videoSrc]="item.VideoUrl"
          [play]="videoPlaying"
          (load)="load.emit($event)">
        </app-html5-video>

      </div>
      -->
    </figure>
  </main>
</article>
