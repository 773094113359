import * as fromPages from './language.reducer';
import { ActionReducerMap, createFeatureSelector, MetaReducer } from '@ngrx/store';
import { environment } from '../../../../environments/environment';

export const featureKey = 'languages';

export interface LanguageState {
  languages: fromPages.LanguageState;
}

export const reducers: ActionReducerMap<LanguageState> = {
  languages: fromPages.LanguageReducer,
};

export const getLanguageState = createFeatureSelector<LanguageState>(featureKey);
export const metaReducers: MetaReducer<LanguageState>[] = !environment.production ? [] : [];
