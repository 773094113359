import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, take, switchMap, catchError, filter } from 'rxjs/operators';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getSelectedTheme, getThemeContentLoaded, getThemeContentLoading, LoadThemeContent } from '../store';
import { FromDictionaryPipe } from '../../dictionary/pipes/from-dictionary.pipe';
import { MainNavService } from '../../main-nav/main-nav.service';

@Injectable({ providedIn: 'root' })
export class ThemeContentGuard implements CanActivate {
  constructor(private store: Store, private dict: FromDictionaryPipe, private nav: MainNavService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return combineLatest([
      this.store.select(getThemeContentLoaded),
      this.store.select(getThemeContentLoading),
      this.store.select(getSelectedTheme),
    ]).pipe(
      tap(([loaded, loading, theme]) => {
        if (theme && loaded !== +route.params.id && loading !== +route.params.id) {
          this.store.dispatch(LoadThemeContent({ id: +route.params.id }));
        }
      }),
      filter(([loaded, loading, theme]) => loaded === +route.params.id),
      map(([loaded, loading, theme]) => loaded === +route.params.id),
      take(1)
    );
  }
}
