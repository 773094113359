import { Pipe, PipeTransform } from '@angular/core';
import { LanguageService } from '../services/language.service';
import { DictionaryState } from '../store/reducers/dictionary.reducer';

@Pipe({
  name: 'fromDictionary',
})
export class FromDictionaryPipe implements PipeTransform {
  constructor(private langService: LanguageService) {}

  transform(value: string): string {
    if (!value) {
      return value;
    }

    if (!this.langService.dictionary || !this.langService.dictionary[value.toUpperCase()]) {
      return 'TEXT NOT FOUND FOR: ' + value;
    }

    return (
      this.langService.dictionary[value.toUpperCase()][this.langService.getLanguage()] ||
      `TEXT '${value}' NOT FILLED FOR: ${this.langService.getLanguage()}`
    );
  }
}
