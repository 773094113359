import { BricksModule } from './../bricks/bricks.module';
import { EmployeeService } from './services/employee.service';
import { EmployeeDetailComponent } from './components/employee-detail/employee-detail.component';
import { EmployeeOverviewComponent } from './components/employee-overview/employee-overview.component';
import { LazyLoadImageModule } from 'ng-lazyload-image';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { RouterModule } from '@angular/router';
import { PostModule } from '../post/post.module';
import { TeamComponent } from './components/team/team.component';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';
import { EmployeeRoutingModule } from './employee-routing.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import * as fromStore from './store';
@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    CoreModule,
    PostModule,
    BricksModule,
    DictionaryNgrxModule,
    EmployeeRoutingModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  declarations: [
    EmployeeOverviewComponent,
    EmployeeDetailComponent,
    TeamComponent,
  ],
  exports: [
    TeamComponent
  ],
  providers: [
    EmployeeService
  ]
})
export class EmployeeModule { }
