<article class="project-card">
  <img
    class="project-card__image"
    [src]="(project.MainImage?.ImageUrl || project.images?.[0]?.url ) + '?width=970&height=850&quality=75&mode=crop&scale=both'"
    [alt]="project.Title"
  />

  <span class="project-card__label" *ngIf="project.IsNEXTProject">{{ 'next-project-label' | fromDictionary }}</span>
  <h1 class="project-card__title">{{ project.Title }}</h1>
</article>
