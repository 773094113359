<section class="content-detail background--{{ background }}">
  <div class="block-mobile-nav-space"></div>

  <header class="content-detail__header">
    <div class="content-detail__header__container">
      <nav class="content-detail__header__nav" *ngIf="subPages && subPages.length">
        <ul class="filter align--left">
          <li [routerLinkActive]="'is--active'" [routerLinkActiveOptions]="{ exact: true }">
            <a [routerLink]="['/about']">{{ 'about-page-title' | fromDictionary }}</a>
          </li>
          <li *ngFor="let item of subPages" [routerLinkActive]="'is--active'">
            <a *ngIf="!item.externalUrl" [routerLink]="['/about/' + item.target]">{{ item.menuTitle || item.title }}</a>
            <a *ngIf="item.externalUrl && item.externalUrl.startsWith('/')" [routerLink]="item.externalUrl">
              {{ item.menuTitle || item.title }}
            </a>
            <a *ngIf="item.externalUrl && !item.externalUrl.startsWith('/')" [href]="item.externalUrl">
              {{ item.menuTitle || item.title }}
            </a>
          </li>
        </ul>
      </nav>

      <time
        class="content-detail__header__time"
        *ngIf="content.publishDate && contentType != 2 && contentType != 8"
        [dateTime]="content.publishDate | date : 'dd/MM/yyyy'"
      >
        {{ content.publishDate | date : 'dd/MM/yyyy' }}
      </time>
      <time
        class="content-detail__header__time"
        *ngIf="contentType === 2 && asEvent(content).eventDate"
        [dateTime]="content.publishDate | date : 'dd/MM/yyyy'"
      >
        {{ asEvent(content).eventDate | date : 'dd/MM/yyyy' }}
      </time>
      <time
        class="content-detail__header__time"
        *ngIf="contentType === 8 && asLecture(content).lectureDate"
        [dateTime]="content.lectureDate | date : 'dd/MM/yyyy'"
      >
        {{ asEvent(content).lectureDate | date : 'dd/MM/yyyy' }}
      </time>
      <h1 class="content-detail__header__title">{{ content.title }}</h1>
    </div>

    <app-post-header *ngIf="content.image" [image]="content.image" (load)="loadImage()"></app-post-header>
  </header>

  <main class="content-detail__body padding-top--0" [hidden]="content.id === 6">
    <router-outlet name="top" class="content-detail__body__router-outlet"></router-outlet>

    <div
      class="text-body"
      [innerHtml]="content.text | removeEmptyElements | stripEmbed : (cookiesAccepted$ | async) | safe : 'html'"
      *ngIf="content.text"
    ></div>

    <app-photo-wall *ngIf="gallery" [title]="null" [photos]="gallery" [showThumbs]="true"></app-photo-wall>

    <router-outlet name="default" class="content-detail__body__router-outlet"></router-outlet>
  </main>

  <router-outlet name="bottom" class="content-detail__router-outlet"></router-outlet>
</section>
