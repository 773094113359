import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Content } from '../../../content/models/content.model';
import { Brick } from '../../../bricks/models/brick.model';

@Component({
  selector: 'app-project-news',
  templateUrl: './project-news.component.html',
})
export class ProjectNewsComponent {
  @Input() news: Content[] = [];
  @ViewChild('newsContainer') newsContainer: ElementRef;

  defaultPaging = 2;
  itemsToLoad: number = 4;
  currentIndex: number = this.defaultPaging;

  constructor() {}

  ngOnInit(): void {}

  get newsBricks(): Brick[] {
    return this.news.map(news => {
      return {
        Id: news.id,
        Title: news.title,
        Description: news.text,
        Type: 'news',
        ImageUrl: news.image,
        Url: news.url,
        DateAdded: news.publishDate,
      };
    });
  }

  lessNews() {
    this.currentIndex = this.defaultPaging;

    setTimeout(() => {
      // this.newsContainer.nativeElement.scrollTop = this.newsContainer.nativeElement.scrollHeight;
      this.newsContainer.nativeElement.scrollIntoView({ behavior: 'smooth' });
    }, 0);
  }

  trackBy(index, item: any) {
    return item.id;
  }
}
