<section class="employee-overview content-detail has--bg-spacing has-no--bg">
  <header class="employee-overview__header">
      <h1 class="employee-overview__header__title">
        {{ 'employee-overview-title' | fromDictionary }}
          <!--, and the influence of architecture on the everyday lives of its inhabitants and users.-->
      </h1>
  </header>


  <ul class="employee-overview__list" *ngIf="employeeSummaries$ | async as employees" [@staggerOpacityAnimation]="employees">
    <li
      *ngFor="let employee of employees; trackBy: trackBy"
      class="employee-overview__list__item">
      <app-post-thumb
        [title]="employee?.name"
        [text]="employee?.function"
        [image]="employee?.portrait"
        [imgWidth]="300"
        [imgHeight]="300"
        [imgMode]="'crop'"
        small="true"
        [url]="['/about', 'team', employee.id, employee.name | urlRewrite]">
      </app-post-thumb>
    </li>
  </ul>

</section>


