import { Component, OnInit, Input, Output, EventEmitter, SimpleChanges } from '@angular/core';
import { OnChanges, AfterViewInit } from '@angular/core';

@Component({
  selector: 'app-post-header',
  templateUrl: './post-header.component.html',
})
export class PostHeaderComponent implements OnInit, OnChanges, AfterViewInit {
  @Input() image: any;
  @Input() title: string;
  @Input() subtitle: string;
  @Input() video: any;
  @Input() typeSmall = true;
  @Input() alignLeft = false;
  @Input() noOverlay = false;
  @Input() blackAndWhite = false;
  @Output() load = new EventEmitter<any>(null);
  @Output() changes = new EventEmitter<any>(null);
  @Output() init = new EventEmitter<any>(null);
  loaded = false;

  ngOnInit() {}

  ngAfterViewInit() {
    this.init.emit(true);
  }

  ngOnChanges(changes: SimpleChanges) {
    this.changes.emit(true);

    if (changes.image) {
      this.loaded = false;
    }
  }

  // check when image or video is loaded
  ready(e) {
    this.loaded = true;
    this.load.emit(e);
  }
}
