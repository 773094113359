<section class="content-detail__photo-wall background--light" *ngIf="showThumbs && photos?.length">
  <header class="content-detail__photo-wall__header" *ngIf="title">
    <h1 class="content-detail__photo-wall__header__title">{{ title }}</h1>
  </header>

  <ul class="content-detail__photo-wall__list">
    <li class="content-detail__photo-wall__list__item" *ngFor="let item of photos; trackBy: item?.id" [class.is--loaded]="true">
      <app-post-thumb
        [title]="item.Caption ? item.Caption : item.title"
        [url]="[]"
        [urlParams]="{ photo: item?.id }"
        [image]="item.url"
        [imgWidth]="400"
        [imgHeight]="300"
        [imgMode]="'crop'"
      ></app-post-thumb>
    </li>
  </ul>
</section>

<app-modal [id]="'gallery-modal'" (close)="close()">
  <swiper
    #swiper
    [config]="swiperConfig"
    [index]="activeIndex$ | async"
    (swiperTransitionStart)="moving = true"
    (swiperTransitionEnd)="moving = false"
    class="modal__swiper"
  >
    <figure *ngFor="let photo of photos; trackBy: trackByFn" class="modal__swiper__item">
      <img
        class="modal__swiper__item__image"
        [src]="(photo.url | http2Https) + '?width=1920'"
        (load)="load()"
        (click)="moving ? null : open(photo)"
        [class.is--loaded]="imageLoaded"
        [alt]=""
        [class.has--caption]="photo.Caption"
      />
      <figcaption class="caption modal__swiper__item__image-caption" *ngIf="photo.Caption">{{ photo.Caption }}</figcaption>
    </figure>
  </swiper>

  <footer class="modal__controls" *ngIf="photos?.length">
    <a
      [routerLink]="[]"
      [queryParams]="(prev$ | async)?.id ? { photo: (prev$ | async)?.id } : {}"
      [class.is--invisible]="(activeIndex$ | async) < 1"
    >
      <
    </a>
    <span>{{ (activeIndex$ | async) + 1 }} / {{ photos?.length }}</span>
    <a
      [routerLink]="[]"
      [queryParams]="(next$ | async)?.id ? { photo: (next$ | async)?.id } : {}"
      [class.is--invisible]="(activeIndex$ | async) > photos.length - 2"
    >
      >
    </a>
  </footer>
</app-modal>
