import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromRoot from '../../../store';
import * as fromLanguage from '../reducers/language.reducer';

export const getLanguageSelectorState = createSelector(fromFeature.getLanguageState, (state: fromFeature.LanguageState) => state.languages);

export const getLanguagesEntities = createSelector(getLanguageSelectorState, fromLanguage.getLanguagesEntities);
export const getLanguageActive = createSelector(getLanguageSelectorState, fromLanguage.getLanguageActive);

export const getAllLanguages = createSelector(getLanguagesEntities, fromRoot.getRouterState, (entities, router) => {
  const path = router?.state?.url || '';

  return Object.keys(entities)
    .map(id => entities[id])
    .filter(a => a.publish)
    .map(a => ({ ...a, domain: a['domain'] + path }));
});

export const getLanguesLoaded = createSelector(getLanguageSelectorState, fromLanguage.getLanguagesLoaded);
export const getLanguesLoading = createSelector(getLanguageSelectorState, fromLanguage.getLanguagesLoading);

export const getLanguagesInitialized = createSelector(getLanguageSelectorState, fromLanguage.getLanguagesInitialized);
export const getLanguagesInitializing = createSelector(getLanguageSelectorState, fromLanguage.getLanguagesInitializing);
