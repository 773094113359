import { EmployeeService } from './../../services/employee.service';
import { staggerOpacityAnimation } from './../../../core/fade.animation';
import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { EmployeeSummaryDepartment } from '../../interfaces/employee-summary-department';
import { Observable, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { EmployeeSummary } from '../../interfaces/employee-summary.interface';

import * as employeeSummarySelectors from '../../store/selectors';
import { setEmployeeSortOrder } from '../../store';

@Component({
  selector: 'app-team',
  templateUrl: './team.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  animations: [staggerOpacityAnimation],
})
export class TeamComponent implements OnInit {
  sortOrder$: Observable<string>;
  employees$: Observable<{
    Key: string;
    Order: number;
    Employees: EmployeeSummary[];
  }[]>;

  sortOptions = [
    {
      short: 'studio',
      title: 'Studio',
    },
    {
      short: 'office',
      title: 'Location',
    },
    {
      short: 'az',
      title: 'A-Z',
    },
  ];

  sortActive = 'studio';

  constructor(private store: Store) {}

  ngOnInit() {
    this.employees$ = this.store.select(employeeSummarySelectors.getOrderedEmployeeSummaries);
    this.sortOrder$ = this.store.select(employeeSummarySelectors.getEmployeeSortOrder);
  }

  setSortOrder(sortOrder: string) {
    this.store.dispatch(setEmployeeSortOrder({sortOrder}))
  }
}
