
    <dl class="collapsable-list" *ngIf="contentItems">
      <ng-container *ngFor="let year of contentItems | reverse; let first = first">
        <dt class="collapsable-list__head" appCollapsableToggle [isOpen]="first">{{year.year}}</dt>
        <dd class="collapsable-list__body">
          <ul class="details-list type--1">
            <li *ngFor="let item of year.items">
              {{item.title}}
            </li>
          </ul>
        </dd>
      </ng-container>
    </dl>

