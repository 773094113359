export enum ContentType {
  CONTENT = 0,
  NEWS = 1,
  EVENTS = 2,
  MENU = 3,
  PAGE = 4,
  PUBLICATIONS = 5,
  AWARD = 6,
  MAGAZINE = 7,
  LECTURES = 8,
}
