<article class="update-summary">
  <img
    loading="lazy"
    class="update-summary__image"
    [src]="item?.image + '?width=590&height=396&mode=crop'"
    [attr.alt]="item.title"
    *ngIf="item?.image"
  />
  <time class="update-summary__date" [attr.datetime]="item.publishDate">{{ item.publishDate | date: 'dd/MM/yyyy' }}</time>
  <h3 class="update-summary__title">{{ item.title }}</h3>
  <p class="update-summary__text">{{ item.text | stripHtml | words: 25 }}</p>
</article>
