import { Brick } from './../../../bricks/models/brick.model';
import { Component, OnInit, Input, EventEmitter, AfterViewInit, Output } from '@angular/core';

@Component({
  selector: 'app-post-content',
  templateUrl: './post-content.component.html'
})
export class PostContentComponent implements OnInit, AfterViewInit {
  @Input() item: Brick;
  @Output() load = new EventEmitter<any>(null);

  constructor() { }

  ngOnInit() {
  }

  ngAfterViewInit() {
    this.load.emit(true);
  }

}
