<article class="post-teaser" (appInViewport)="countStart = true">
  <div class="post-teaser__heading">
    <h1 class="post-teaser__heading__title">
      <a [routerLink]="proofLink.url">
          <span *ngIf="proofLink.textParts.length < 2; else dynamicStatement">{{proofLink.text}}</span>

          <ng-template #dynamicStatement>
            <ng-container *ngFor="let p of proofLink.textParts; let i = index">
              {{p}}
              <span
                *ngIf="proofLink.computedValues[i]"
                [appCount]="proofLink.computedValues[i]"
                [countStart]="countStart">
              </span>
            </ng-container>
          </ng-template>
      </a>
    </h1>
  </div>

  <a class="post-teaser__button" [routerLink]="proofLink.url" *ngIf="proofLink.url">
    <app-icon [anchor]="'icon-arrow'"></app-icon>
  </a>
</article>
