import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'removeEmptyElements'
})
export class RemoveEmptyElementsPipe implements PipeTransform {
  transform(text): string {
    return text
      .split('<p style="text-align: justify;">&nbsp;</p>').join('')
      .split('<p>&nbsp;</p>').join('')
      .split('<h2>&nbsp;</h2>').join('');
  }
}
