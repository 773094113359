import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'square'
})
export class SquarePipe implements PipeTransform {

  transform(value: string): string {
    if (value[value.length - 1] === '.') {
      const str = value.slice(0, -1);
      return str + `<span class="square">.</span>`;

    } else {
      return value;
    }
  }

}
