import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as ProjectsActions from '../actions/project-content.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';

@Injectable()
export class ProjectContentEffects {
  constructor(private actions$: Actions, private ProjectsService: ProjectService) {}

  LoadProjectContent$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(ProjectsActions.LoadProjectContent),
      switchMap(({ id }) =>
        this.ProjectsService.getContent(id).pipe(
          map(content => ProjectsActions.LoadProjectContentSuccess({ content })),
          catchError(error => of(ProjectsActions.LoadProjectContentFail({ error })))
        )
      )
    );
  });
}
