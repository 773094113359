import { TimelineProject } from './../../../project/models/timeline-projects.interface';
import { ThemeService } from './../../services/theme.service';
import { ActivatedRoute } from '@angular/router';
import { Component, AfterContentInit, ViewChild, HostListener, Input, AfterViewInit, OnDestroy } from '@angular/core';
import { ThemeSummary } from '../../models/theme-summary.model';
import { SwiperConfig } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface, SwiperDirective } from 'ngx-swiper-wrapper';
import { ProjectSummary } from '../../../project/models/project-summary.interface';
import { TimelineItem } from '../../models/timeline-item.interface';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-theme-timeline',
  templateUrl: './theme-timeline.component.html',
})
export class ThemeTimelineComponent implements AfterViewInit, OnDestroy {
  @ViewChild('slidesSwiper', { static: true }) slidesSwiper;
  @Input() items: TimelineItem[];
  config = {
    direction: 'horizontal',
    slidesPerView: 1.25,
    keyboard: true,
    centeredSlides: true,
    effect: 'slide',
    loop: false,
    spaceBetween: 105,
    speed: 2 * 1000,
    grabCursor: true,
    breakpoints: {
      720: { slidesPerView: 1, spaceBetween: 30 },
      992: { spaceBetween: 50 },
    },
  };

  inview = false;
  index = 0;
  currentDate = new Date().getFullYear(); // used for start timeline by nearest current date
  private _unsubscribe$ = new Subject<void>();
  // html5PlayerVars = {
  //   autoplay: true,
  //   controls: true,
  //   muted: false,
  //   loop: false,
  // };

  // html5video = 'https://www.w3schools.com/html/mov_bbb.mp4';

  constructor(private route: ActivatedRoute, private service: ThemeService) {}

  ngAfterViewInit() {
    this.slidesSwiper.directiveRef.update();

    /*
     * Get closest date from current date
     * Time out for crazy angular
     */
    setTimeout(() => {
      this.index = this.closestDate(this.items, this.currentDate);
    });
  }

  /*
   * Closed date function
   * Get nearest date from timeline data array
   */
  closestDate(list, x) {
    let min;
    let chosen = 0;

    for (const key in list) {
      if (list.hasOwnProperty(key)) {
        min = Math.abs(list[chosen].year - x);

        if (Math.abs(list[key].year - x) <= min) {
          chosen = parseInt(key, 0);
        }
      }
    }
    return chosen;
  }

  /*
   * TimeOut for delay by first start timeline in viewport.
   */
  startFirstTime(t) {
    setTimeout(() => {
      this.start();
    }, t);
  }

  start() {
    this.slidesSwiper.directiveRef.prevSlide(65 * 1000);
  }

  stop() {
    this.slidesSwiper.directiveRef.stopAutoplay();
  }

  @HostListener('document:keydown.arrowLeft')
  prev() {
    this.slidesSwiper.directiveRef.prevSlide();
  }

  @HostListener('document:keydown.arrowRight')
  next() {
    this.slidesSwiper.directiveRef.nextSlide();
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
