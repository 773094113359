import { BricksModule } from './bricks/bricks.module';
import { ModalModule } from './modal/modal.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreModule } from './core/core.module';
import { MainNavModule } from './main-nav/main-nav.module';
import { NotFoundComponent } from './404.component';

// Store
import { StoreModule } from '@ngrx/store';
import { StoreRouterConnectingModule } from '@ngrx/router-store';
import { EffectsModule } from '@ngrx/effects';
import { reducers, CustomSerializer, appEffects, metaReducers } from './store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from '../environments/environment';
import { CookieModule } from './cookie/cookie.module';
import { LanguageInterceptor } from './http-interceptors/app-interceptor';
import { DictionaryConfig } from './dictionary/models';
import { DictionaryNgrxModule } from './dictionary/dictionary-ngrx.module';
import { FromDictionaryPipe } from './dictionary/pipes/from-dictionary.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ThemeService } from './theme/services/theme.service';
import { SeoModule } from './seo/seo.module';
import { ThemeModule } from './theme/theme.module';

const dictConfig: DictionaryConfig = {
  mapping: [
    {
      domainPart: '.com/',
      language: 'en',
    },
  ],
};

@NgModule({
  declarations: [AppComponent, NotFoundComponent],
  imports: [
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,
    BrowserModule.withServerTransition({ appId: 'mvrdv' }),
    CoreModule,
    MainNavModule,
    ModalModule,
    BricksModule,
    ThemeModule,
    StoreModule.forRoot(reducers, {
      metaReducers,
      runtimeChecks: {
        strictStateImmutability: true,
        strictActionImmutability: true,
      },
    }),
    EffectsModule.forRoot(appEffects),
    StoreRouterConnectingModule.forRoot({ serializer: CustomSerializer }),
    StoreDevtoolsModule.instrument({
      maxAge: 25,
      logOnly: environment.production,
    }),
    CookieModule,
    SeoModule,
    DictionaryNgrxModule.forRoot(dictConfig),
  ],
  providers: [FromDictionaryPipe, { provide: HTTP_INTERCEPTORS, useClass: LanguageInterceptor, multi: true }],
  bootstrap: [AppComponent],
})
export class AppModule {}
