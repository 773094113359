import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromThemeContent from '../reducers/theme-content.reducer';
import * as fromRoot from '../../../store';
import { Theme } from '../../models/theme.interface';

export const getThemeContentSelectorState = createSelector(fromFeature.getThemesState, (state: fromFeature.ThemesState) => state.content);

export const getThemeContentEntities = createSelector(getThemeContentSelectorState, fromThemeContent.getThemeContentEntities);

export const getAllThemeContent = createSelector(getThemeContentEntities, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getOrderedThemeContent = createSelector(getAllThemeContent, ThemeContent => {
  return ThemeContent?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getThemeWallContent = createSelector(getOrderedThemeContent, ThemeContent => {
  return ThemeContent.filter(c => ['news', 'events', 'stack-magazine'].indexOf(c.contentType) >= 0).sort((a1, a2) => {
    const d1 = new Date(a1.publishDate);
    const d2 = new Date(a2.publishDate);
    return d1 > d2 ? -1 : d1 < d2 ? 1 : 0;
  });
});

export const getThemeContentLoaded = createSelector(getThemeContentSelectorState, fromThemeContent.getThemeContentLoaded);
export const getThemeContentLoading = createSelector(getThemeContentSelectorState, fromThemeContent.getThemeContentLoading);
