<div
  class="content-detail__featured-photo"
  [class.set--left]="photo.alignment === 1"
  [class.set--right]="photo.alignment === 2"
  [class.set--wide]="photo.alignment === 0"
>
  <figure class="content-detail__featured-photo__figure" (appInViewport)="inview = $event">
    <app-image
      class="img--full type--opacity content-detail__featured-photo__object"
      [class.is--loaded]="isLoaded"
      *ngIf="photo.mediaItem.type === 'image' && inview && photo.mediaItem.url"
      [src]="photo.mediaItem.url"
      loading="lazy"
      (loaded)="isLoaded = true; load.emit($event)"
      [alt]="'featured image'"
      [sizes]="{lg: 8}"
    ></app-image>

    <app-youtube
      class="content-detail__featured-photo__object"
      [url]="photo.mediaItem.url"
      *ngIf="photo.mediaItem.type === 'video' && photo.mediaItem.url.includes('youtube.com') && inview"
    ></app-youtube>

    <app-html5-video
      class="content-detail__featured-photo__object"
      *ngIf="photo.mediaItem.type === 'video' && !photo.mediaItem.url.includes('youtube.com') && inview"
      [playerVars]="html5PlayerVars"
      [videoSrc]="photo.mediaItem.url"
    ></app-html5-video>

    <figcaption *ngIf="photo.caption" class="caption content-detail__featured-photo__caption">{{ photo.caption }}</figcaption>
  </figure>
  <div class="content-detail__featured-photo__text text-body" [class.pt-0]="!photo?.mediaItem?.url" [innerHTML]="photo.text | safe: 'html'"></div>
</div>
