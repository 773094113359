import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/project-events.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ProjectService } from '../../services/project.service';

@Injectable()
export class ProjectEventsEffects {
  constructor(private actions$: Actions, private ProjectsService: ProjectService) {}

  LoadProjectEvents$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.LoadProjectEvents),
      switchMap(({ id }) =>
        this.ProjectsService.getEvents(id).pipe(
          map(events => actions.LoadProjectEventsSuccess({ events })),
          catchError(error => of(actions.LoadProjectEventsFail({ error })))
        )
      )
    );
  });
}
