import { HttpClient } from '@angular/common/http';
import { QueryType } from './../../project/enums/query-type.enum';
import { Project } from './../../project/models/project.interface';
import { ProjectService } from './../../project/services/project.service';
import { Observable } from 'rxjs/Observable';
import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve } from '@angular/router';
import { constants } from '../../core/constants';
import { environment } from '../../../environments/environment';
import { first, map, switchMap } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { getSelectedTheme } from '../store';

@Injectable({ providedIn: 'root' })
export class ThemeProjectResolve
  implements
    Resolve<{
      aggregations: any;
      projects: Project[];
    }>
{
  constructor(private projectService: ProjectService, private http: HttpClient, private store: Store) {}

  resolve(route: ActivatedRouteSnapshot): Observable<{
    aggregations: any;
    projects: Project[];
  }> {
    const apiBase = `${environment.apiUrl}/themes`;
    const id = +route.paramMap.get('id');

    return this.store.select(getSelectedTheme).pipe(
      switchMap(theme =>
        this.projectService.find(constants.numberOfProjects, 0, [
          {
            Type: QueryType.NESTED,
            Field: 'themes',
            Values: [theme.title],
          },
        ])
      ),
      map(result => ({
        aggregations: result.aggregations,
        projects: result.hits.map(h => h.source),
      })),
      first()
    );
  }
}
