export function ToEntities(a: any[], key: string, initialEntities?: {}) {
  if (!a) {
    return {}
  }
  return a.reduce(
    (e: { [k: number]: any }, obj: any) => {
      return {
        ...e,
        [obj[key]]: obj,
      };
    },
    {
      ...initialEntities,
    }
  );
}
