import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as themesActions from '../actions/themes.action';
import { switchMap, map, catchError } from 'rxjs/operators';
import { ThemeService } from '../../services/theme.service';

@Injectable()
export class ThemesEffects {
  constructor(private actions$: Actions, private themesService: ThemeService) {}

  LoadThemes$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(themesActions.LoadThemes),
      switchMap(() =>
        this.themesService.all().pipe(
          map(themes => themesActions.LoadThemesSuccess({ themes })),
          catchError(error => of(themesActions.LoadThemesFail({ error })))
        )
      )
    );
  });

  LoadTheme$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(themesActions.LoadTheme),
      switchMap(({ id }) =>
        this.themesService.get(id).pipe(
          map(theme => themesActions.LoadThemeSuccess({ theme })),
          catchError(error => of(themesActions.LoadThemeFail({ error })))
        )
      )
    );
  });
}
