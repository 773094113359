<header
  *ngIf="image"
  class="post-header-component"
  [class.type--align-left]="alignLeft"
  [class.type--black-and-white]="blackAndWhite"
  [class.is--loaded]="loaded"
  [class.type--no-overlay]="noOverlay || !title"
>
  <app-loader [loading]="!loaded" [dark]="true"></app-loader>

  <figure class="post-header-component__crop">
    <app-image
      *ngIf="!video && image"
      [src]="image | http2Https"
      loading="lazy"
      (loaded)="ready($event)"
      [alt]="title || subtitle"
    ></app-image>

    <ng-template #showVideo>
      <app-html5-video
        (load)="ready($event)"
        [posterSrc]="image"
        [videoSrc]="video"
        *ngIf="video"
        [playerVars]="{
          autoplay: true,
          loop: true,
          muted: true,
          controls: false
        }"
      ></app-html5-video>
    </ng-template>
  </figure>

  <div class="post-header-component__content" *ngIf="title || subtitle">
    <h1 class="post-header-component__content__title" *ngIf="title">{{ title }}</h1>
    <h1 class="post-header-component__content__subtitle" *ngIf="subtitle">{{ subtitle }}</h1>
  </div>
</header>
