import { createReducer, on } from '@ngrx/store';

import * as pageActions from '../actions/themes.action';
import * as utility from '../../../utility';
import { Theme } from '../../models/theme.interface';

export interface ThemesState {
  entities: { [id: number]: Theme };
  loadedIds: number[];
  loading: boolean;
  loaded: boolean;
  error?: any;
}

export const initialState: ThemesState = {
  entities: {},
  loadedIds: [],
  loaded: false,
  loading: false,
};

export const ThemesReducer = createReducer(
  initialState,
  on(pageActions.LoadThemes, (state: ThemesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadThemesSuccess, (state: ThemesState, { themes }) => {
    const entities = utility.ToEntities(themes, 'id', state?.entities);

    return {
      ...state,
      entities,
      loadedIds: [...state.loadedIds, ...themes.map(theme => theme.id)],
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadThemesFail, (state: ThemesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  }),
  on(pageActions.LoadTheme, (state: ThemesState) => {
    return {
      ...state,
      loading: true,
    };
  }),
  on(pageActions.LoadThemeSuccess, (state: ThemesState, { theme }) => {
    const entities = {
      ...state.entities,
      [theme.id]: theme,
    };
    return {
      ...state,
      entities,
      loadedIds: [...state.loadedIds, theme.id],
      loading: false,
      loaded: true,
    };
  }),
  on(pageActions.LoadThemeFail, (state: ThemesState, { error }) => {
    return {
      ...state,
      loading: false,
      loaded: false,
      error,
    };
  })
);

export const getThemesEntities = (state: ThemesState) => state.entities;
export const getThemesLoading = (state: ThemesState) => state.loading;
export const getThemesLoaded = (state: ThemesState) => state.loaded;
export const getThemesLoadedIds = (state: ThemesState) => state.loadedIds;
