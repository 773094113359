<article class="post-list">
  <figure class="post-list__crop">
    <img loading="lazy" [src]="image" [alt]="title" (load)="load.emit($event); isLoaded = true" />
  </figure>
  <div class="post-list__content">
    <strong class="post-list__content__tag" *ngIf="category">{{ category }}</strong>
    <h1 class="post-list__content__title">{{ title }}</h1>
    <div class="post-list__content__summary" *ngIf="text">{{ text | stripHtml | truncate: 150 }}</div>
  </div>
  <a href="" [routerLink]="url" class="post-list__link"></a>
</article>
