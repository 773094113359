<section class="team">
  <nav class="team__sort">
    <h1 class="h6 team__sort__title is--hidden">{{ 'team-order-by' | fromDictionary }}</h1>
    <div class="button-group">
      <button (click)="setSortOrder(button.short)" class="button-group__item button type--strike-through" [class.is--active]="button.short === (sortOrder$| async)" *ngFor="let button of sortOptions">
        {{ 'employee-filter-'+button.short | fromDictionary }}
      </button>
    </div>
  </nav>

  <ng-container>
    <dl *ngFor="let depart of (employees$ | async)">
      <dt class="team__key" *ngIf="depart.Employees.length > 0">{{ depart.Key }}</dt>
      <ul class="team__list" *ngIf="depart.Employees.length > 0" [@staggerOpacityAnimation]="employees" >
        <li
          *ngFor="let employee of depart.Employees;"
          class="team__list__item">
          <app-post-thumb
            [title]="employee?.name"
            [text]="employee?.function"
            [image]="employee?.portrait"
            [imgWidth]="300"
            [imgHeight]="300"
            [imgMode]="'crop'"
            small="true"
            [url]="['/about', 'team', employee.id, employee.name | urlRewrite]">
          </app-post-thumb>
        </li>
      </ul>
    </dl>
  </ng-container>
</section>

