import { Injectable, Optional, Inject } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Language } from '../types/language.type';
import { DictionaryConfigService } from './dictionary-config.service';
import { DictionaryConfig } from '../models/dictionary-config';
import { DictionaryEntry } from '../models';

import { Store } from '@ngrx/store';

import { DictionaryState, getDictionaryEntities, LoadDictionary } from '../store';
import { take } from 'rxjs/operators';
import { environment } from '../../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class LanguageService {
  private API_URL = environment.apiUrl;
  dictionary: { [placeholder: string]: DictionaryEntry };
  private currentLanguage: Language = 'en';

  constructor(
    private Http: HttpClient,
    @Optional()
    @Inject('BASE_URL')
    private BASE_URL: string,
    @Inject(DictionaryConfigService) private config: DictionaryConfig,
    private store: Store<DictionaryState>
  ) {
    if (!this.BASE_URL || !this.config) {
      this.currentLanguage = 'en';
    }

    const domain = this.config.mapping.find(a => this.BASE_URL.indexOf(a.domainPart) >= 0);

    if (domain) {
      this.currentLanguage = domain.language;
    } else {
      this.currentLanguage = 'en';
    }
  }

  loadDictionary() {
    return this.Http.get<DictionaryEntry[]>(`${this.API_URL}/dictionary`);
  }

  getLanguage() {
    return this.currentLanguage;
  }

  setLanguage(lang: string) {
    this.currentLanguage = <Language>lang?.toLowerCase();
  }

  init() {}
}
