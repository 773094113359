<article class="theme-detail" *ngIf="theme$ | async as theme" [lang]="theme?.language">
  <app-post-header
    class="background--dark"
    [title]="theme.claim"
    [image]="theme.image"
    [video]="theme?.video"
    (load)="updateNav()"
  ></app-post-header>

  <main>
    <section class="background--dark content-detail">
      <div class="content-detail__body text-center">
        <header *ngIf="theme.claim" class="block-title is--hidden">
          <h1 class="type--lowercase type--144">{{ theme.timelineTitle || theme.claim }}</h1>
        </header>

        <p *ngIf="theme.proof" class="text-intro pre-line">{{ theme.timelineText || theme.proof }}</p>
      </div>
    </section>

    <section class="content-detail background--dark" *ngIf="theme.mediaBlocks?.length">
      <ng-container *ngFor="let item of theme.mediaBlocks">
        <app-featured-photo [photo]="item"></app-featured-photo>
      </ng-container>
    </section>
    <section class="" *ngIf="(nextProjects$ | async)?.length" class="background--dark">
      <header class="content-detail__header is--middle-section background--dark">
        <div class="content-detail__header__container">
          <h1 class="content-detail__header__title text-center">{{ 'next-projects-title' | fromDictionary }}</h1>
        </div>
      </header>

      <ul class="project-grid" *ngIf="(nextProjects$ | async)?.length && theme.id === 15" class="background--dark">
        <ng-container *ngFor="let project of nextProjects$ | async | slice : 0 : (showTotalProjects$ | async)">
          <li class="project-grid__item" *ngIf="project.MainImage?.ImageUrl">
            <app-post-thumb
              [padding]="true"
              [small]="true"
              [dark]="false"
              [image]="project.MainImage?.ImageUrl"
              [url]="['/projects', project.Id, project.Title | urlRewrite]"
              [title]="project.Title"
              [imgWidth]="400"
              [imgHeight]="300"
              [imgMode]="'crop'"
            ></app-post-thumb>
          </li>
        </ng-container>
      </ul>
      <footer class="project-grid__footer padding-vertical--3"></footer>
    </section>

    <section class="" *ngIf="theme.timeline?.length || (projects$ | async)?.length">
      <header class="content-detail__header is--middle-section">
        <div class="content-detail__header__container">
          <h1 class="content-detail__header__title text-center">{{ 'experience-theme-prefix' | fromDictionary }} {{ theme.title }}</h1>
        </div>
      </header>

      <ul class="project-grid" *ngIf="projects$ | async as projects">
        <ng-container *ngFor="let project of projects$ | async | slice : 0 : (showTotalProjects$ | async)">
          <li class="project-grid__item" *ngIf="project.MainImage?.ImageUrl">
            <app-post-thumb
              [padding]="true"
              [small]="true"
              [dark]="false"
              [image]="project.MainImage?.ImageUrl"
              [url]="['/projects', project.Id, project.Title | urlRewrite]"
              [title]="project.Title"
              [imgWidth]="400"
              [imgHeight]="300"
              [imgMode]="'crop'"
            ></app-post-thumb>
          </li>
        </ng-container>
        <footer class="project-grid__footer padding-vertical--3" *ngIf="(showTotalProjects$ | async) <= projects.length">
          <a
            class="button button--type--outline button--type--outline-black"
            [routerLink]="['/projects']"
            [queryParams]="{ themes: theme.title }"
          >
            {{ 'theme-more-projects-button-text' | fromDictionary }}
            ({{ projects.length }})
          </a>
        </footer>
      </ul>
    </section>

    <div class="background--dark content-detail" *ngIf="theme.timeline?.length">
      <header class="content-detail__header is--middle-section">
        <div class="content-detail__header__container">
          <h1 class="content-detail__header__title text-center">
            {{ 'theme-timeline-title-prefix' | fromDictionary }} {{ theme.timelineTitle }}
          </h1>
        </div>
      </header>

      <app-theme-timeline [items]="theme.timeline"></app-theme-timeline>
    </div>

    <section (appInViewport)="inview = $event" *ngIf="content$ | async as wallContent">
      <app-brick-wall
        *ngIf="wallContent?.length && inview"
        [showTotalUpdates]="showUpdates"
        [items]="wallContent"
        [title]="'theme-updates' | fromDictionary"
      ></app-brick-wall>

      <footer class="project-grid__footer padding-vertical--1" *ngIf="showUpdates <= wallContent.length">
        <button class="button button--type--outline button--type--outline-black" (click)="showUpdates = showUpdates + showTotalUpdates">
          {{ 'theme-more-update-button-text' | fromDictionary }}
          ({{ wallContent.length }})
        </button>
      </footer>
    </section>
  </main>
</article>

<app-theme-overview></app-theme-overview>
