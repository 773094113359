import { createReducer, on } from '@ngrx/store';
import * as pageActions from '../actions/cookie-settings.action';

export interface CookieSettingsState {
  accepted: string;
  initializing: boolean;
  initialized: boolean;
  error?: any;
}

export const initialState: CookieSettingsState = {
  accepted: null, // null = undetermined, '1' = true, '0' = false
  initialized: false,
  initializing: false,
};

export const CookieSettingsReducer = createReducer(
  initialState,
  on(pageActions.AcceptCookies, (state: CookieSettingsState) => {
    return {
      ...state,
      accepted: '1',
    };
  }),
  on(pageActions.RejectCookies, (state: CookieSettingsState) => {
    return {
      ...state,
      accepted: '0',
    };
  }),
  on(pageActions.InitCookieSettings, (state: CookieSettingsState) => {
    return {
      ...state,
      initializing: true,
    };
  }),
  on(pageActions.InitCookieSettingsSuccess, (state: CookieSettingsState, { accept }) => {
    return {
      ...state,
      accepted: accept,
      initializing: false,
      initialized: true,
    };
  }),
  on(pageActions.ClearCookieSettings, (state: CookieSettingsState) => {
    return {
      ...initialState,
    };
  })
);

export const getCookiesAccepted = (state: CookieSettingsState) => state.accepted;
export const getCookieSettingsInitializing = (state: CookieSettingsState) => state.initializing;
export const getCookieSettingsInitialized = (state: CookieSettingsState) => state.initialized;
export const getCookieSettingsError = (state: CookieSettingsState) => state.error;
