import { Component, OnInit } from '@angular/core';
import { Award } from '../../models/award.model';
import { ContentOverviewService } from '../../services/content-overview.service';
import * as _ from 'lodash';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-awards',
  templateUrl: './awards.component.html',
})
export class AwardsComponent implements OnInit {
  contentItems: { year: number; items: Award[] }[];
  private _unsubscribe$ = new Subject<void>();
  constructor(private service: ContentOverviewService) {}

  ngOnInit() {
    this.service
      .get('awards', 1000)
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(a => {
        const items = (<Award[]>a.items).map(i =>
          Object.assign(i, {
            year: new Date(i.awardedOn).getFullYear(),
          })
        );

        this.contentItems = _.chain(items)
          .groupBy('year')
          // tslint:disable-next-line:no-shadowed-variable
          .map((items, year) => ({ year, items }))
          .value();
      });
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
