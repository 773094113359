import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromProjectEvents from '../reducers/project-events.reducer';
import * as fromRoot from '../../../store';
import { Event } from '../../../content/models/event.model';

export const getProjectEventsSelectorState = createSelector(
  fromFeature.getProjectsState,
  (state: fromFeature.ProjectsState) => state.events
);

export const getProjectEventsEntities = createSelector(getProjectEventsSelectorState, fromProjectEvents.getProjectEventsEntities);

export const getAllProjectEvents = createSelector(getProjectEventsEntities, entities => {
  return Object.keys(entities).map(id => entities[id] as Event);
});

export const getPastProjectEvents = createSelector(getAllProjectEvents, events => {
  return (
    events
      .filter(a => new Date(a.eventDate) < new Date())
      // sort by EventDate descending
      .sort((a1, a2) => (new Date(a1.eventDate) >= new Date(a2.eventDate) ? -1 : 1))
  );
});

export const getUpcomingProjectEvents = createSelector(getAllProjectEvents, events => {
  return (
    events
      .filter(a => new Date(a.eventDate) >= new Date())
      // sort by EventDate descending
      .sort((a1, a2) => (new Date(a1.eventDate) >= new Date(a2.eventDate) ? -1 : 1))
  );
});

export const getOrderedProjectEvents = createSelector(getAllProjectEvents, ProjectEvents => {
  return ProjectEvents?.sort((a, b) => (a.order >= b.order ? 1 : -1));
});

export const getProjectWallEvents = createSelector(getOrderedProjectEvents, ProjectEvents => {
  return ProjectEvents.sort((a1, a2) => {
    const d1 = new Date(a1.eventDate);
    const d2 = new Date(a2.eventDate);
    return d1 > d2 ? -1 : d1 < d2 ? 1 : 0;
  });
});

export const getProjectEventsLoaded = createSelector(getProjectEventsSelectorState, fromProjectEvents.getProjectEventsLoaded);
export const getProjectEventsLoading = createSelector(getProjectEventsSelectorState, fromProjectEvents.getProjectEventsLoading);
