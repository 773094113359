import { ProjectView } from './../../enums/project-view.enum';
import { ActivatedRoute } from '@angular/router';
import { Component, AfterViewInit, ElementRef } from '@angular/core';

import { UrlRewritePipe } from '../../../core/pipes/url-rewrite.pipe';
import { ProjectGeneratorService } from '../../services/project-generator.service';
import { scrollToY } from '../../../core/directives/scroll-to.directive';
import { takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-project-generator',
  templateUrl: './project-generator.component.html',
})
export class ProjectGeneratorComponent implements AfterViewInit {
  projectView = ProjectView;
  view = this.projectView.GRID;
  programs: any;
  aggregrations: any;
  private _unsubscribe$ = new Subject<void>();

  constructor(private projectService: ProjectGeneratorService, private element: ElementRef) {}

  ngAfterViewInit() {
    this.projectService
      .find()
      .pipe(takeUntil(this._unsubscribe$))
      .subscribe(results => {
        this.programs = results;
      });
  }

  scrollToId(e) {
    setTimeout(() => {
      const id = e.target.closest('.portfolio__chapter__wrapper').getAttribute('data-id');
      const element = this.element.nativeElement.querySelector('#' + id);
      const y = element.getBoundingClientRect().top;
      scrollToY(y);
    }, 100);
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
