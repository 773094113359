<form ngForm class="signup__newsletter">
  <fieldset [class.is--active]="form.step === 0" class="signup__newsletter__step step--0">
    <legend hidden>Newsletter</legend>
    <input autocomplete="off" class="input type--full type--m" type="email" [email]="true" placeholder="Email address" name="email" #email="ngModel" [(ngModel)]="form.email" required>
    <button class="button type--m" [disabled]="email.invalid" (click)="form.step = 1">Next</button>
  </fieldset>

  <fieldset [class.is--active]="form.step === 1" class="signup__newsletter__step step--1">
    <legend hidden>Language</legend>
    <ul>
      <li><button (click)="reset()"><</button></li>
      <li *ngFor="let language of languages">
        <button [class]="form.language === language.code ? 'text-underline' : 'hover--underline'" (click)="form.language = language.code">
          <span class="lang--on-big">{{language.title}}</span>
          <abbr class="lang--on-small" [title]="language.title">{{language.abbr}}</abbr>
        </button>
      </li>
      <li><button (click)="submit()" class="button type--m">Subscribe</button></li>
    </ul>
  </fieldset>
</form>

<app-modal [id]="'signup-newsletter-iframe'">
  <iframe name="mailer_frame" src="" class="fake-mail-frame"></iframe>
  <form #fakeForm action="//mailer.effect-ict.nl/subscribe.php" method="post" id="newsletter-signup-form" target="mailer_frame" accept-charset="UTF-8">
    <input type="hidden" name="FormValue_Fields[EmailAddress]" [value]="form.email" id="formvalue_emailaddress">
    <input style="display: none;" type="submit" name="FormButton_Subscribe" value="Subscribe" id="FormButton_Subscribe">
    <input type="hidden" name="FormValue_ListID" [value]="form.language">
    <input type="hidden" name="FormValue_Command" value="Subscriber.Add" id="FormValue_Command">
  </form>
</app-modal>
