import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';

import { Store } from '@ngrx/store';
import { tap, filter, take, switchMap, catchError, withLatestFrom } from 'rxjs/operators';

import { combineLatest, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { getRouterState, SetTags, SetTitle } from '../../store';
import { getOrderedThemes, getSelectedTheme, getThemesLoaded, getThemesLoadedIds, getThemesLoading } from '../store';
import { FromDictionaryPipe } from '../../dictionary/pipes/from-dictionary.pipe';
import { Utility } from '../../core/classes/utility';
import { MainNavService } from '../../main-nav/main-nav.service';

@Injectable({ providedIn: 'root' })
export class ThemeMetaGuard implements CanActivate {
  constructor(private store: Store, private dict: FromDictionaryPipe, private nav: MainNavService) {}

  canActivate(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.checkStore(route).pipe(
      switchMap(() => of(true)),
      catchError(() => of(false))
    );
  }

  checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
    return this.store.select(getSelectedTheme).pipe(
      withLatestFrom(this.store.select(getRouterState)),
      tap(([theme, router]) => {
        if (theme?.id !== +router.state.params.id) {
          return;
        }
        this.nav.setSubLogo(theme.short_title || theme.title);
        this.store.dispatch(SetTitle({ title: `MVRDV - ${theme.title}` }));
        this.store.dispatch(
          SetTags({
            properties: {
              'description': Utility.concatString(theme.claim, 300),
              'og:title': `MVRDV - ${theme.title}`,
              'og:image': 'https:' + theme.image.replace(/ /g, '%20'),
              'og:description': Utility.concatString(theme.claim, 300),
              'twitter:card': 'summary_large_image',
              'twitter:site': '@MVRDV',
              'twitter:title': `MVRDV - ${theme.title}`,
              'twitter:description': Utility.concatString(theme.claim, 300),
              'twitter:image': 'https:' + theme.image.replace(/ /g, '%20') + '?width=1200',
            },
          })
        );
      }),
      filter(([theme, router]) => theme?.id === +router.state.params.id),
      map(([theme, router]) => theme?.id === +router.state.params.id)
    );
  }

  // checkStore(route: ActivatedRouteSnapshot): Observable<boolean> {
  //   return combineLatest([
  //     this.store.select(getThemesLoadedIds),
  //     this.store.select(getThemesLoading),
  //     this.store.select(getSelectedTheme),
  //   ]).pipe(
  //     tap(([ids, loading, theme]) => {
  //       console.log(theme, ids);
  //       if (!loading && ids.includes(+route.params.id) && theme) {
  //         this.nav.setSubLogo(theme.short_title || theme.title);
  //         this.store.dispatch(SetTitle({ title: `MVRDV - ${theme.title}` }));
  //         this.store.dispatch(
  //           SetTags({
  //             properties: {
  //               'description': Utility.concatString(theme.claim, 300),
  //               'og:title': `MVRDV - ${theme.title}`,
  //               'og:image': 'https:' + theme.image.replace(/ /g, '%20'),
  //               'og:description': Utility.concatString(theme.claim, 300),
  //               'twitter:card': 'summary_large_image',
  //               'twitter:site': '@MVRDV',
  //               'twitter:title': `MVRDV - ${theme.title}`,
  //               'twitter:description': Utility.concatString(theme.claim, 300),
  //               'twitter:image': 'https:' + theme.image.replace(/ /g, '%20') + '?width=1200',
  //             },
  //           })
  //         );
  //       }
  //     }),
  //     filter(([ids, loading, theme]) => !loading && ids.includes(+route.params.id)),
  //     map(([ids, loading, themes]) => !loading && ids.includes(+route.params.id))
  //   );
  // }
}
