import { ProjectService } from './../../services/project.service';
import { Router, ActivatedRoute } from '@angular/router';
import { DecimalPipe } from '@angular/common';
import { ProjectView } from './../../enums/project-view.enum';
import { FilterState } from './../../models/filter-state.model';
import { Component, Output, Input, AfterViewInit, OnChanges, SimpleChange, EventEmitter, ViewChild } from '@angular/core';
import { CheckboxGroup } from '../../../core/interfaces/checkbox-group.model';
import * as _ from 'lodash';

@Component({
  selector: 'app-filters',
  templateUrl: './filters.component.html'
})
export class FiltersComponent {
  // tslint:disable-next-line:no-input-rename
  @ViewChild('search') searchElement;
  @Input() state: FilterState;
  @Input() view = ProjectView.GRID;
  @Input() isOpen;
  @Input() loading = false;
  @Input() results: number = null;
  @Output() viewChange = new EventEmitter<ProjectView>();

  projectView = ProjectView;
  sizeFormat = {
    to: value => this.decimalPipe.transform(Math.round(value)) + 'm²',
    from: value => parseInt(value.toString().replace(/,/g, '').replace('m²', ''), null)
  };

  setView(view: ProjectView) {


    // if (view === 'map') {

    //   // reset filterstates
    //   this.state.q = '';
    //   this.state.checkboxes = [];
    //   this.state.size = {
    //     range: [0, 370000],
    //     value: [0, 370000]
    //   };
    //   this.state.years = {
    //     range: [1993, new Date().getFullYear()],
    //     value: [1993, new Date().getFullYear()]
    //   };

    //   this.updateRoute();
    // }

    // this.viewChange.emit(view);

    // if (view === this.projectView.MAP && this.isOpen) {
    //   this.toggle();
    // }
  }

  updateRoute() {
    this.router.navigate([], {
    relativeTo: this.route,
      queryParams: this.service.filterStateToParams(this.state),
    });
  }

  toggle() {
    this.isOpen = !this.isOpen;

    setTimeout(() => {
      this.isOpen
        ? this.searchElement.nativeElement.focus()
        : this.searchElement.nativeElement.blur();
    }, 600);
  }

  toggleSelectBox(group) {
    group.isOpen = !group.isOpen;
  }

  constructor(
    private decimalPipe: DecimalPipe,
    private router: Router,
    private service: ProjectService,
    private route: ActivatedRoute
  ) { }
}
