import { RouterLinkWithHref } from '@angular/router';
import { Input, Optional, Directive } from '@angular/core';

@Directive({
  // tslint:disable-next-line:directive-selector
  selector: '[externalLink]',
})
export class ExternalLinkDirective {
  @Input() externalLink: string;

  constructor(
    // Inject RouterLinkWithHref
    @Optional() private link: RouterLinkWithHref
  ) {
    if (!link) {
      return;
    }

    // Save original onClick method
    const onClick = link.onClick;

    // Replace method with your own (I know this is not good idea)
    link.onClick = (...args: any[]) => {
      if (this.externalLink) {
        // Process external url
        window.location.href = this.externalLink;
        return false;
      } else {
        // Process internal url by calling routerLink original method
        return onClick.apply(link, args);
      }
    };
  }
}
