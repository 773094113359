import { Component, OnInit, AfterViewInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Content } from '../../models/content.model';
import { ContentType } from '../../enums/content-type.enum';
import { fadeInAnimation } from '../../../core/fade.animation';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { Meta, Title } from '@angular/platform-browser';
import { Utility } from '../../../core/classes/utility';
import { ContentDetailService } from '../../services/content-detail.service';
import { Photo } from '../../../project/models/photo.interface';
import { Event } from '../../models/event.model';
import { Lecture } from '../../models/lecture.model';
import { Observable, Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { getCookiesAccepted } from '../../../cookie/store';
import { takeUntil } from 'rxjs/operators';

@Component({
  selector: 'app-content-detail',
  templateUrl: './content-detail.component.html',
  animations: [fadeInAnimation],
})
export class ContentDetailComponent implements OnInit, AfterViewInit {
  content: Content;
  subPages: any[];
  gallery: Photo[] = [];
  contentType: ContentType = ContentType.CONTENT;
  contentTypes = ContentType;
  backUrl: string;
  backTitle: string;
  imageLoaded = false;
  hasMenu: boolean;
  background = 'light';
  noBody = false;

  cookiesAccepted$: Observable<boolean>;
  contentText = '';

  private _unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private metaTitle: Title,
    private meta: Meta,
    private nav: MainNavService,
    private contentService: ContentDetailService,
    private store: Store
  ) {}

  ngOnInit() {
    this.cookiesAccepted$ = this.store.select(getCookiesAccepted);

    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      this.contentType = a.contentType;
      this.content = a.content;
      this.backUrl = '/' + a.apiIdentifier;
      this.backTitle = a.apiIdentifier;
      this.hasMenu = a.menu;
      this.background = a.background || 'light';

      if (this.content.id === 898 || this.content.id === 966 || this.content.id === 966) {
        this.backUrl = '/about';
        this.backTitle = 'About';
      }

      this.metaTitle.setTitle(`MVRDV - ${this.content.title}`);
      this.meta.addTags([
        { property: 'og:title', content: `${this.content.title}` },
        {
          property: 'og:description',
          content: Utility.concatString(this.content.text || '', 160),
        },
        { property: 'og:image', content: `https:${this.content.image}` },
        {
          name: 'description',
          content: Utility.concatString(this.content.text || '', 160),
        },
        { name: 'twitter:card', content: 'summary_large_image' },
        { name: 'twitter:site', content: '@MVRDV' },
        { name: 'twitter:title', content: `${this.content.title}` },
        { name: 'twitter:description', content: Utility.concatString(this.content.text || '', 300) },
        { name: 'twitter:image', content: 'https:' + this.content.image?.replace(/ /g, '%20') + '?width=1200' },
      ]);

      if (this.hasMenu) {
        this.contentService.getSubItems(895, 'menu').subscribe(b => (this.subPages = b));
      }
      this.contentService.getGallery(this.content.id).subscribe(
        b =>
          (this.gallery = b.map(c => ({
            id: c.Id,
            url: c.ImageUrl,
            title: 'open',
            isLoaded: false,
          })))
      );
    });
  }

  ngAfterViewInit() {
    this.nav.updateLama();
  }

  // checkContent() {
  //   if (this.cookieAccepted && this.content.text) {
  //     this.contentText = this.content.text;
  //   } else {
  //     this.contentText = this.stripCookie.transform(this.content.text);
  //   }
  // }

  loadImage() {
    this.imageLoaded = true;
    this.nav.setFill('light');
    this.nav.updateLama();
  }

  asEvent(val): Event {
    return val;
  }

  asLecture(val): Lecture {
    return val;
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
