import { Injectable } from '@angular/core';
import { createEffect, ofType, Actions } from '@ngrx/effects';
import { of } from 'rxjs';
import * as actions from '../actions/cookie-settings.action';
import { switchMap, map, catchError, tap } from 'rxjs/operators';

@Injectable()
export class CookieSettingsEffects {
  private STORAGE_KEY = 'COOKIES_ACCEPT';

  constructor(private actions$: Actions) {}

  acceptCookies$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.AcceptCookies),
        tap(() => {
          localStorage.setItem(this.STORAGE_KEY, '1');
        })
      );
    },
    { dispatch: false }
  );

  rejectCookies$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.RejectCookies),
        tap(() => {
          localStorage.setItem(this.STORAGE_KEY, '0');
        })
      );
    },
    { dispatch: false }
  );

  ClearCookieSettings$ = createEffect(
    () => {
      return this.actions$.pipe(
        ofType(actions.ClearCookieSettings),
        tap(() => {
          localStorage.removeItem(this.STORAGE_KEY);
        })
      );
    },
    { dispatch: false }
  );

  InitCookieSettings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(actions.InitCookieSettings),
      map(() => {
        const storedCookieSettings = localStorage.getItem(this.STORAGE_KEY);
        if (storedCookieSettings) {
          return actions.InitCookieSettingsSuccess({ accept: storedCookieSettings });
        }
        return actions.InitCookieSettingsSuccess({ accept: null });
      }),
      catchError(error => of(actions.InitCookieSettingsFail({ error })))
    );
  });
}
