import { Brick } from './../models/brick.model';
import { Injectable } from '@angular/core';
import { Resolve, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs/Observable';
import { environment } from '../../../environments/environment';

@Injectable({ providedIn: 'root' })
export class SocialService implements Resolve<Brick[] | Observable<Brick[]>> {
  private API_URL = environment.apiUrl;
  // private API_URL = 'http://windows:63128';

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Brick[] | Observable<Brick[]> {
    const projectId = +route.params['id'];
    return this.byProject(projectId);
  }

  constructor(private http: HttpClient) {}

  byProject(id: number): Observable<Brick[]> {
    return this.http.get<Brick[]>(`${this.API_URL}/project/${id}/social`);
  }

  byEmployee(id: number): Observable<Brick[]> {
    return this.http.get<Brick[]>(`${this.API_URL}/employees/${id}/social`);
  }

  fetch(url: string): Observable<Brick> {
    return this.http.get<Brick>(`${this.API_URL}/social/preview?url=${url}`);
  }

  add(url: string, projectId: number): Observable<Brick> {
    return this.http.post<Brick>(`${this.API_URL}/project/${projectId}/social?url=${url}`, null);
  }

  delete(projectId: number, id: number): Observable<Brick[]> {
    return this.http.delete<Brick[]>(`${this.API_URL}/project/${projectId}/social/${id}`);
  }
}
