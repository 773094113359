import { BricksModule } from './../bricks/bricks.module';
import { RouterModule } from '@angular/router';

import { AgmCoreModule } from '@agm/core';
import { AgmSnazzyInfoWindowModule } from '@agm/snazzy-info-window';

import { FormsModule } from '@angular/forms';
import { FeaturedPhotoComponent } from './components/featured-photo/featured-photo.component';
import { CoreModule } from './../core/core.module';
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TechSpecsComponent } from './components/tech-specs/tech-specs.component';
import { ProjectDetailComponent } from './containers/project-detail/project-detail.component';
import { ProjectOverviewComponent } from './containers/project-overview/project-overview.component';
import { ProjectRoutingModule } from './project-routing.module';
import { ProjectService } from './services/project.service';
import { RelatedThemesComponent } from './components/related-themes/related-themes.component';
import { PhotoWallComponent } from './components/photo-wall/photo-wall.component';
import { ModalModule } from '../modal/modal.module';
import { CreditsComponent } from './components/credits/credits.component';
import { PostModule } from '../post/post.module';
import { ThemeModule } from '../theme/theme.module';
import { UrlRewritePipe } from '../core/pipes/url-rewrite.pipe';
import { FiltersComponent } from './components/filters/filters.component';
import { ProjectGeneratorComponent } from './containers/portfolio-generator/project-generator.component';
import { ProjectGeneratorService } from './services/project-generator.service';
import { constants } from '../core/constants';
import { NgArrayPipesModule } from 'ngx-pipes';
import { SelectedPipe } from './selected.pipe';
import { GoogleStaticMapComponent } from './components/google-static-map/google-static-map.component';
import { AgmMarkerClustererModule } from '@agm/markerclusterer';
import { CookieModule } from '../cookie/cookie.module';
import { DictionaryNgrxModule } from '../dictionary/dictionary-ngrx.module';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';

import * as fromStore from './store';
import { RelatedProjectsComponent } from './components/related-projects/related-projects.component';
import { FeaturedArticleComponent } from './components/featured-article/featured-article.component';
import { AwardsComponent } from './components/awards/awards.component';
import { ProjectEventsComponent } from './components/project-events/project-events.component';
import { ProjectNewsComponent } from './components/project-news/project-news.component';
import { ProjectContentComponent } from './components/project-content/project-content.component';
import { MomentModule } from 'angular2-moment';
import { ProjectCardComponent } from './components/project-card/project-card.component';

@NgModule({
  imports: [
    CommonModule,
    CoreModule,
    ProjectRoutingModule,
    ModalModule,
    PostModule,
    MomentModule,
    RouterModule,
    FormsModule,
    BricksModule,
    AgmCoreModule.forRoot({ apiKey: constants.mapsApiKey }),
    AgmSnazzyInfoWindowModule,
    AgmMarkerClustererModule,
    NgArrayPipesModule,
    CookieModule,
    DictionaryNgrxModule,
    StoreModule.forFeature(fromStore.featureKey, fromStore.reducers, {
      metaReducers: fromStore.metaReducers,
    }),
    EffectsModule.forFeature(fromStore.effects),
  ],
  declarations: [
    ProjectOverviewComponent,
    ProjectDetailComponent,
    ProjectGeneratorComponent,
    TechSpecsComponent,
    PhotoWallComponent,
    RelatedThemesComponent,
    CreditsComponent,
    FeaturedPhotoComponent,
    FiltersComponent,
    SelectedPipe,
    GoogleStaticMapComponent,
    RelatedProjectsComponent,
    FeaturedArticleComponent,
    AwardsComponent,
    ProjectEventsComponent,
    ProjectNewsComponent,
    ProjectContentComponent,
    ProjectCardComponent,
  ],
  exports: [FiltersComponent, PhotoWallComponent, FeaturedPhotoComponent],
  providers: [],
})
export class ProjectModule {}
