import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'makeYoutubePretty'
})
export class MakeYoutubePrettyPipe implements PipeTransform {

  transform(value: any, args?: any): any {
    if (typeof window === 'undefined') {
      return value;
    }

    const element = document.createElement('div');

    element.innerHTML = value;

    const matchSelectors = `
      iframe[src*="youtube.com"],
      iframe[src*="youtu.be"],
      iframe[src*="vimeo.com"]
    `;

    const youtubes = Array.from(element.querySelectorAll(matchSelectors));

    youtubes.forEach(iframe => {
      const wrapper = document.createElement('div');
      wrapper.classList.add('embed-16-9');
      iframe.parentNode.insertBefore(wrapper, iframe);
      wrapper.appendChild(iframe);
    });

    if (youtubes.length > 0) {
      return element.innerHTML;
    } else {
      return value;
    }

  }

}
