import { Component, OnInit, Input, Output, ViewChild, EventEmitter, ChangeDetectionStrategy, SimpleChanges } from '@angular/core';
import { OnChanges } from '@angular/core';

export interface Html5Video {
  loop: boolean;
  muted: boolean;
  autoplay: boolean;
  poster: string;
  src: string;
  play: boolean;
}

@Component({
  selector: 'app-html5-video',
  template: `
    <video
      (appInViewport)="playInview($event)"
      [once]="false"
      playsinline
      class="html5-video"
      [loop]="playerVars.loop"
      [muted]="playerVars.muted"
      [autoplay]="playerVars.autoplay"
      [poster]="posterSrc"
      [controls]="playerVars.controls"
      [src]="videoSrc"
      (canplay)="load.emit($event)"
      [class.is--loaded]="isLoaded"
      #videoBg
    ></video>
  `,
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class Html5VideoComponent implements OnInit, OnChanges {
  @ViewChild('videoBg', { static: true }) ref: any;
  @Input() videoSrc: string;
  @Input() playerVars: any = {};
  @Input() posterSrc: string = '';
  @Input() play: boolean;
  @Output() load = new EventEmitter<any>(null);
  playerDefaults = {
    autoplay: false,
    controls: true,
    muted: false,
    loop: false,
  };
  isLoaded = false;
  player: any;
  stateClassName = 'is--unloaded';

  constructor() {}

  ngOnInit() {
    this.playerVars = Object.assign(this.playerDefaults, this.playerVars);
  }

  ngOnChanges(changes: SimpleChanges) {
    const videoEl = this.ref.nativeElement;

    // Set isLoaded true when video is ready to play
    this.isLoaded = videoEl.readyState === 4;

    // if (!this.playerVars.autoplay) {
    //   this.play ? this.ref.nativeElement.play() : this.ref.nativeElement.pause();
    // }
  }

  playInview(e) {
    if (e && this.playerVars.autoplay) {
      this.ref.nativeElement.play();
      // console.log('play');
    } else {
      this.ref.nativeElement.pause();
      // console.log('pause');
    }
  }
}
