import { PostNavItem } from './../../../post/interfaces/post-nav.interface';
import { Brick } from './../../../bricks/models/brick.model';
import { Component, OnInit, AfterViewInit, OnDestroy } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Content } from '../../models/content.model';
import { Event } from '../../models/event.model';
import { ContentType } from '../../enums/content-type.enum';
import { ContentOverviewService } from '../../services/content-overview.service';
import { MainNavService } from '../../../main-nav/main-nav.service';
import { Meta, Title } from '@angular/platform-browser';
import { ContentResult } from '../../models/content-result.model';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FromDictionaryPipe } from '../../../dictionary/pipes/from-dictionary.pipe';

@Component({
  selector: 'app-content-overview',
  templateUrl: './content-overview.component.html',
})
export class ContentOverviewComponent implements OnInit, AfterViewInit, OnDestroy {
  pageSize = 24;
  title = 'content';
  contentItems: Content[] = [];
  contentItemsSocial: Brick[];
  contentType: ContentType = ContentType.CONTENT;
  itemCount = 0;
  currentPage = 0;
  apiIdentifier = 'content';
  pageLoaded = false;
  filters: PostNavItem[];
  contentTypes = ContentType;
  private _unsubscribe$ = new Subject<void>();

  constructor(
    private route: ActivatedRoute,
    private service: ContentOverviewService,
    private nav: MainNavService,
    private metaTitle: Title,
    private meta: Meta,
    private dict: FromDictionaryPipe
  ) {}

  ngAfterViewInit() {}

  ngOnInit() {
    this.route.data.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      this.title = this.dict.transform(`${a.title}-overview-title`);
      this.contentItems = a.contentResult.items;
      this.itemCount = a.contentResult.itemCount;
      this.contentType = a.contentType;
      this.apiIdentifier = a.apiIdentifier;
      this.pageLoaded = true;
      this.contentItemsSocial = this.contentItems.map(c => this.contentToBrick(c));

      // ugly but effective (easy fix for now)
      if (['updates', 'news', 'events', 'stack-magazine', 'magazine', 'mvrdv-tv'].includes(a.apiIdentifier)) {
        this.filters = [
          {
            title: this.dict.transform('overview-all-filter-title'),
            url: '/updates',
          },
          {
            title: this.dict.transform('overview-news-filter-title'),
            url: '/news',
          },
          {
            title: this.dict.transform('overview-events-filter-title'),
            url: '/events',
          },
          {
            title: this.dict.transform('overview-stack-magazine-filter-title'),
            url: '/stack-magazine',
          },
          {
            title: this.dict.transform('overview-mvrdv-tv-filter-title'),
            url: '/mvrdv-tv',
          },
        ];
      }

      this.metaTitle.setTitle(`MVRDV - ${this.title.toUpperCase()}`);
      this.meta.addTags([
        {
          property: 'og:title',
          content: `MVRDV - ${this.title.toUpperCase()}`,
        },
        {
          property: 'og:description',
          content: this.dict.transform('news-meta-description')
        },
        {
          name: 'description',
          content: this.dict.transform('news-meta-description')
        },
        { name: 'twitter:card', content: 'summary' },
        { name: 'twitter:site', content: '@MVRDV' },
        { name: 'twitter:title', content: `MVRDV - ${this.title.toUpperCase()}` },
        {
          name: 'twitter:description',
          content: this.dict.transform('news-meta-description')
        },
      ]);
      this.nav.setFill('dark');
    });

    this.route.queryParams.pipe(takeUntil(this._unsubscribe$)).subscribe(a => {
      if (this.pageLoaded && a['page']) {
        this.currentPage = +a['page'];

        this.service
          .get(this.apiIdentifier, this.pageSize, this.currentPage * this.pageSize)
          .pipe(takeUntil(this._unsubscribe$))
          .subscribe((b: ContentResult) => {
            this.contentItemsSocial = (<Content[]>b.items).map(c => this.contentToBrick(c));
            this.itemCount = b.itemCount;
          });
      }
    });
  }

  get prefix(): string {
    switch(this.apiIdentifier) {
      case 'publications':
        return 'about/' + this.apiIdentifier;
      case 'magazine':
        return 'stack-magazine';
      default:
        return this.apiIdentifier;
    }
  }

  contentToBrick(item: Content): Brick {
    return {
      Id: item.id,
      Title: item.title,
      Description: item.text,
      Type: 'article',
      ImageUrl: item.image,
      Url: item.url,
      Favicon: null,
      SiteName: null,
      DateAdded: (<Event>item).eventDate || item.publishDate,
      Author: null,
      ProjectId: null,
      HostName: null,
      ParentTitle: ['news', 'events'].includes(item.contentType) ? item.contentType : null,
      ParentUrl: ['news', 'events'].includes(item.contentType) ? ['/', item.contentType] : null,
    };
  }

  ngOnDestroy(): void {
    this._unsubscribe$.next();
    this._unsubscribe$.complete();
  }
}
