import { ActionReducerMap, createFeatureSelector } from '@ngrx/store';

import * as fromDictionary from './dictionary.reducer';

export interface DictionaryState {
  entries: fromDictionary.DictionaryState;
}

export const reducers: ActionReducerMap<DictionaryState> = {
  entries: fromDictionary.DictionaryReducer,
};

export const getDictionaryState = createFeatureSelector<DictionaryState>('dictionary');
