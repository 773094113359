import { createSelector } from '@ngrx/store';

import * as fromFeature from '../reducers';
import * as fromEmployeeSummaries from '../reducers/employee-summaries.reducer';
import * as fromRoot from '../../../store';
import { EmployeeSummary } from '../../interfaces/employee-summary.interface';

export const getEmployeeSummariesSelectorState = createSelector(
  fromFeature.getEmployeeSummariesState,
  (state: fromFeature.EmployeesState) => state.employees
);

export const getEmployeeSummaries = createSelector(getEmployeeSummariesSelectorState, fromEmployeeSummaries.getEmployeeSummaries);

export const getAllEmployeeSummaries = createSelector(getEmployeeSummaries, entities => {
  return Object.keys(entities).map(id => entities[id]);
});

export const getSortedEmployeeSummaries = createSelector(getAllEmployeeSummaries, employees => {
  return [...employees.sort((a,b) => a.order >= b.order ? 1 : -1)];
});

export const getEmployeeSummariesLoaded = createSelector(
  getEmployeeSummariesSelectorState,
  fromEmployeeSummaries.getEmployeeSummariesLoaded
);
export const getEmployeeSummariesLoading = createSelector(
  getEmployeeSummariesSelectorState,
  fromEmployeeSummaries.getEmployeeSummariesLoading
);

export const getEmployeeSortOrder = createSelector(getEmployeeSummariesSelectorState, fromEmployeeSummaries.getEmployeeSortOrder);

export const getSelectedEmployee = createSelector(getEmployeeSummaries, fromRoot.getRouterState, (entities, router): EmployeeSummary => {
  return router.state && entities[router.state.params?.id || router.state.data?.id];
});

export const getOrderedEmployeeSummaries = createSelector(getSortedEmployeeSummaries, getEmployeeSortOrder, (employees, order) => {
  let results;
  switch (order) {
    case 'office':
      results = groupAndSortByOffice(employees);
      break;
    case 'az':
      results = groupAndSortByName(employees);
      break;
    default:
    case 'studio':
      results = groupAndSortByDepartment(employees);
      break
  }

  return Object.keys(results)
  .map(a => {
    const group = results[a];
    group.Employees = group.Employees.filter(a => a.portrait);

    if (order === 'az') {
      group.Employees.sort((a, b) => a.name.localeCompare(b.name))
    }

    return group;
  })
  .sort((a, b) => (a.Order >= b.Order ? 1 : -1));
});

function groupAndSortByName(employees: EmployeeSummary[]) {
  return employees
    .reduce((r, a) => {
      const key = a?.name.charAt(0);

      r[key] = {Key: key, Order: key,  Employees: [...(r[key]?.Employees || []), a]};
      return r;
    }, {});
}

function groupAndSortByOffice(employees: EmployeeSummary[]) {
  var groups = employees?.reduce((r, a) => {
    let officeName = a.office?.Name.trim() || 'No office';
    let officeOrder = a.office?.Order >= 0 ? a.office.Order : 9999;

    r[officeName] = { Key: officeName, Order: officeOrder, Employees: [...(r[officeName]?.Employees || []), a] };
    return r;
  }, {});

  return groups;
}

function groupAndSortByDepartment(employees: EmployeeSummary[]) {
  var groups = employees?.reduce((r, a) => {
    let departmentName = a.department?.Name.trim() || 'No studio';
    let departmentOrder = a.department?.Order >= 0 ? a.department.Order : 99999;

    r[departmentName] = { Key: departmentName, Order: departmentOrder, Employees: [...(r[departmentName]?.Employees || []), a] };
    return r;
  }, {});

  return groups;
}
